import React, { Dispatch, SetStateAction, useEffect } from "react";
import { IFormMaker, IRole, IUserSessionReadModel, SetState } from "./models";
import Input from "../global/atoms/Input";
import styles from "../views/admin/components/usersAdd/styles.module.scss";
import ButtonOutlined from "../global/atoms/ButtonOutlined";
import { useHistory, useLocation } from "react-router-dom";
import Textarea from "../global/atoms/Textarea";
import Checkbox from "../global/atoms/Checkbox";
import SelectComponent from "../global/atoms/Select";
import store, { logout } from "../redux/store";
import { SET_APP, SET_FORM_ERRORS } from "../redux/actions";
import _ from "lodash";
import { isPublicScope } from "views/security/Security";
import { TypeScope } from "./types";
import {
  ToastRequesterCancelled,
  ToastRequesterPush,
  ToastTypes,
} from "global/ToastNotification";
import axios from "axios";
import { v4 as uuid, validate } from "uuid";
import { Button } from "rsuite";

export const handleErrors = (
  err: any,
  customAction?: (err: any) => any,
  toastId?: string
) => {
  if (axios.isCancel(err)) {
    if (toastId) ToastRequesterCancelled(toastId);
    return Promise.reject(err); // todo: that makes no sense but if returning void, promise become successfull with undefined result ...
  }

  const errorCode = err.response ? err.response.status : null;
  const errorMessage = err?.response?.data?.message ?? undefined;

  ToastRequesterPush(toastId ?? uuid(), ToastTypes.error, errorMessage);
  //console.error(errorCode, errorMessage);
  if (customAction) {
    customAction(err);
  } else {
    const errorRedirect = [
      404, 310, 405, 414, 500, 501, 502, 503, 504, 505, 506, 507, 508, 509, 510,
      511,
    ];

    if (
      !isPublicScope &&
      errorRedirect.find((e) => e === errorCode) !== undefined &&
      location.pathname.substring(0, 4) !== "/err" &&
      !err?.response?.data?.tokenExpired
    ) {
      if (!localStorage.getItem("err_skip")) {
        window.location.href = `${window.location.origin}/err/${errorCode}${window.location.pathname}`;
      }
    } else if (err?.response?.data?.tokenExpired) {
      logout(true);
    } else {
      store.dispatch({
        type: SET_APP,
        payload: {
          logged: true,
          tokenExpired: false,
          responseErrorStatus: parseInt(errorCode),
        },
      });
    }
  }
  return Promise.reject(err);
};

export const handleSetErrors = (err: any, setErrors: any, toastId?: string) => {
  return handleErrors(
    err,
    () => {
      if (setErrors !== undefined) {
        setErrors(err.response.data.errors);
      }
    },
    toastId
  );
};

export const handleToastRedux = (
  promise: Promise<any>,
  dispatch: Dispatch<any>,
  errorMessage?: string
): Promise<any> => {
  return handleToast(promise, undefined, errorMessage, undefined, dispatch);
};

// todo: remove
export const handleToast = async (
  promise: Promise<any>,
  setErrors?: Dispatch<SetStateAction<any>>,
  errorMessage?: string,
  successMessage?: string,
  dispatch?: Dispatch<any>,
  loadingOnlyToast?: boolean,
  toastId?: string
): Promise<any> => {
  if (!toastId) {
    toastId = uuid();
  }
  ToastRequesterPush(toastId as string, ToastTypes.loading);

  return promise.then(
    (data) => {
      if (!loadingOnlyToast) {
        ToastRequesterPush(toastId as string, ToastTypes.success);
      }
      if (dispatch) dispatch({ type: SET_FORM_ERRORS, payload: {} }); // clear errors
      return data;
    },
    (err) => {
      if (axios.isCancel(err)) {
        return Promise.reject();
      }
      if (dispatch)
        dispatch({
          type: SET_FORM_ERRORS,
          payload: err?.response?.data?.errors ?? {},
        });
      if (setErrors) setErrors(err.response.data.errors);
      return handleErrors(err, undefined, toastId);
    }
  );
};

// todo: remove
export const handleToastErrorCallback = async (
  promise: Promise<any>,
  callback: (errors?: []) => void
): Promise<any> => {
  const toastId = uuid();

  ToastRequesterPush(toastId, ToastTypes.loading);

  return promise.then(
    (data) => {
      ToastRequesterPush(toastId, ToastTypes.success);
      return data;
    },
    (err) => {
      if (axios.isCancel(err)) {
        return Promise.reject();
      }
      const internalErrorMessage = err?.response?.data?.message ?? undefined;
      ToastRequesterPush(toastId, ToastTypes.error, internalErrorMessage, 6000);
      return handleErrors(
        err,
        () => {
          callback(err.response.data.errors);
        },
        toastId
      );
    }
  );
};

export const NotCancelErrorPromise = (err: any): Promise<any> => {
  if (!axios.isCancel(err)) return Promise.reject(err);
  console.error(err);
  return Promise.resolve();
};

export const getAuthHeader = (
  tokenString: string | null = null
): { Authorization: string } => {
  if (tokenString) {
    return { Authorization: `Bearer ${tokenString}` };
  } else {
    const user = localStorage.getItem("user");
    if (user !== null) {
      return { Authorization: "Bearer " + JSON.parse(user).token };
    }
  }
  return { Authorization: "" };
};

export const getTokenFromLocalStorage = (): string | null => {
  return JSON.parse(localStorage.getItem("user") ?? "{}")?.token;
};

export const clickOutsideMenu = (
  ref: React.RefObject<any>,
  setState: (state: boolean) => void
): void => {
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        event.target.id !== "openProfileButton"
      ) {
        setState(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
};

export const parseDateForBackend = (date: string): number => {
  return parseInt(Date.parse(date).toString().slice(0, -3));
};

export const toPHPTimestamp = (microtime: number): number => {
  return parseInt((microtime / 1000).toFixed(0));
};

export const parseTimeForBackend = (time: number): number => {
  const date = new Date();
  date.setTime(time);
  date.setMilliseconds(0);
  return date.getTime() / 1000;
};

export const parseDateForFrontend = (timestamp: number) => {
  const date = new Date(timestamp * 1000);

  let buildDate = date.getFullYear() + "-";
  if (date.getMonth() < 9) {
    buildDate += "0";
  }
  buildDate += `${date.getMonth() + 1}-`;
  if (date.getDate() < 10) {
    buildDate += "0";
  }
  buildDate += `${date.getDate()}`;

  return buildDate;
};

export const formatDate = (date: Date): string => {
  const d = new Date(date);
  const year = d.getFullYear();
  let month = "" + (d.getMonth() + 1);
  let day = "" + d.getDate();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};

export const getRoles = (): Array<{ id: string; name: string }> => {
  const user = localStorage.getItem("user");
  if (user !== null) {
    return JSON.parse(user).user.roles;
  } else {
    return [];
  }
};

// export const getBaseUri = (loc: string, projectAutoScope = true): string => {
//   let uri = `${getBaseApiUrl()}/organizations/${getOrganizationId()}`;
//   if(projectAutoScope && getProjectIdFromUrl()) {
//     uri += `/projects/${getProjectIdFromUrl()}`
//   }
//   uri += `/${loc}`
//   return uri;
// }

export const getBaseApiUrl = (): string => {
  // if(process.env.NODE_ENV === 'production') {
  switch (location.hostname) {
    case "new.dev.sc3.companion.pl":
      return "http://new.dev.api.sc3.companion.pl/api";

    case "mss.salescompanion.pl":
      return "https://mss.api.salescompanion.pl/api";

    case "test-mss.salescompanion.pl":
      return "https://test-mss.api.salescompanion.pl/api";

    case "modus.salescompanion.pl":
      return "https://modus.api.salescompanion.pl/api";

    case "vg.salescompanion.pl":
      return "https://vg.api.salescompanion.pl/api";

    case "sales-team.salescompanion.pl":
      return "https://sales-team.api.salescompanion.pl/api";

    case "target-pro.salescompanion.pl":
      return "https://target-pro.api.salescompanion.pl/api";

    case "sbm.salescompanion.pl":
      return "https://sbm.api.salescompanion.pl/api";

    case "siglo.salescompanion.pl":
      return "https://siglo.api.salescompanion.pl/api";

    case "pepsico.salescompanion.pl":
      return "https://pepsico.api.salescompanion.pl/api";

    case "savoir.salescompanion.pl":
      return "https://savoir.api.salescompanion.pl/api";

    case "adecco.salescompanion.pl":
      return "https://adecco.api.salescompanion.pl/api";

    case "energizer.salescompanion.pl":
      return "https://energizer.api.salescompanion.pl/api";

    case "promoteka.salescompanion.pl":
      return "https://promoteka.api.salescompanion.pl/api";

    case "jet.salescompanion.pl":
      return "https://jet.api.salescompanion.pl/api";

    case "hempplant.salescompanion.pl":
      return "https://hempplant.api.salescompanion.pl/api";

    case "pscenter.salescompanion.pl":
      return "https://pscenter.api.salescompanion.pl/api";

    case "option1.salescompanion.pl":
      return "https://option1.api.salescompanion.pl/api";

    case "system.salescompanion.pl":
      return "https://system.api.salescompanion.pl/api";
  }
  // }

  return process.env.REACT_APP_API_URL ?? "";
};

export const getOrganizationId = (): string => {
  const user = localStorage.getItem("user");
  if (user !== null) {
    return JSON.parse(user).organizationId;
  } else {
    return "0e026142-801a-4a47-abb3-5e54a5e29bb3"; // todo: ?
  }
};

export const getUserUsername = (): string => {
  const user = localStorage.getItem("user");
  if (user !== null) {
    return JSON.parse(user).user.username;
  } else {
    return "";
  }
};

export const getUserId = (): string => {
  const user = localStorage.getItem("user");
  if (user !== null) {
    return JSON.parse(user).user.id;
  } else {
    return "";
  }
};

export const getUserFromSession = (): null | IUserSessionReadModel => {
  const userString = localStorage.getItem("user");
  let userObject: any = null;
  if (userString !== null) {
    userObject = JSON.parse(userString);
  }

  return userObject;
};

export const formMapper = (
  item: IFormMaker,
  state: any,
  setState: any,
  errors?: any,
  setView?: any
): any => {
  const handleChangeInput = (event: any) => {
      setState((state: any) => ({
        ...state,
        [event.target.name]: event.target.value,
      }));
    },
    handleChangeTextarea = (event: any) => {
      setState((state: any) => ({
        ...state,
        [event.target.name]: event.target.value,
      }));
    },
    handleChangeSelect = (
      object: { value: string; label: string },
      name: string
    ) => {
      setState((state: any) => ({ ...state, [name]: object.value }));
    },
    handleToggleRole = (event: any) => {
      const mapped = state.roles.map(
        (item: { roleId: string; default: boolean }) => ({
          roleId: item.roleId,
          default: item.default,
        })
      );
      //TODO: udoskonalić, jeśli usunie się rolę która ma default, to niech default przjedzie na jakąś inną
      const mapped2 = state.roles.map(
        (item: { roleId: string; default: boolean }) => item.roleId
      );
      if (mapped2.includes(event.currentTarget.dataset.id)) {
        const filtered = mapped.filter(
          (item: { roleId: string; default: boolean }) =>
            item.roleId !== event.currentTarget.dataset.id
        );
        setState({
          ...state,
          roles: filtered.map((item: any) => ({
            roleId: item.roleId,
            default: item.default,
          })),
        });
      } else if (state.roles.length) {
        setState({
          ...state,
          roles: [
            ...state.roles,
            { roleId: event.currentTarget.dataset.id, default: false },
          ],
        });
      } else {
        setState({
          ...state,
          roles: [
            ...state.roles,
            { roleId: event.currentTarget.dataset.id, default: true },
          ],
        });
      }
    },
    handleSetView = (view: number) => {
      setView(view);
    },
    handleToggleRoleRadio = (event: any) => {
      setState({
        ...state,
        roles: state.roles.map((item: any) => {
          if (item.roleId === event.currentTarget.dataset.id) {
            return { roleId: item.roleId, default: true };
          } else {
            return { roleId: item.roleId, default: false };
          }
        }),
      });
    },
    handleToggleRoleAll = () => {
      if (state.roles.length) {
        setState({ ...state, roles: [] });
      } else {
        setState({
          ...state,
          roles: item.attr.options.map((item: IRole, idx: number) => {
            if (idx) {
              return { roleId: item.roleId, default: false };
            } else {
              return { roleId: item.roleId, default: true };
            }
          }),
        });
      }
    };

  switch (item.tagName) {
    case "select":
      return (
        <>
          <SelectComponent
            placeholder={item.attr.placeholder}
            name={item.attr.name}
            onChange={handleChangeSelect}
            value={state[item.attr.name]}
            options={item.attr.options.map(
              (item: { id: string; name: string }) => ({
                value: item.id,
                label: item.name,
              })
            )}
            style={{ marginTop: "24px" }}
            disabled={item.attr.disabled}
          />
          {errors && (
            <span className={styles.errors}>{errors[item.attr.name]}</span>
          )}
        </>
      );
    case "input":
      return (
        <>
          <Input
            name={item.attr.name}
            value={state[item.attr.name]}
            type={item.attr.type}
            placeholder={item.attr.placeholder}
            onChange={handleChangeInput}
            style={{ marginTop: "24px" }}
          />
          {errors && (
            <span className={styles.errors}>{errors[item.attr.name]}</span>
          )}
        </>
      );
    case "textarea":
      return (
        <>
          <Textarea
            name={item.attr.name}
            value={state[item.attr.name]}
            placeholder={item.attr.placeholder}
            onChange={handleChangeTextarea}
            style={{ marginTop: "24px" }}
          />
          {errors && (
            <span className={styles.errors}>{errors[item.attr.name]}</span>
          )}
        </>
      );
    case "custom-roles":
      return (
        <section style={{ marginTop: "12px", marginBottom: "-12px" }}>
          <div className={styles.helpersWrapperColumns}>
            <div className={styles.helpersWrapperCheckbox}>
              <Checkbox
                isChecked={state.roles.length}
                isMixed={true}
                onClick={handleToggleRoleAll}
              />
              {item.attr.columns.roleName}
            </div>
            <span className={styles.helpersColumnDefault}>
              {item.attr.columns.default}
            </span>
          </div>
          {item.attr.options.map((item: IRole) => (
            <div className={styles.helpersWrapperOptions} key={item.roleId}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}>
                <Checkbox
                  id={item.roleId}
                  isMixed={false}
                  isChecked={state.roles
                    .map((item: any) => item.roleId)
                    .includes(item.roleId)}
                  onClick={handleToggleRole}
                />
                {item.roleName}
              </div>
              {state.roles.map((stateItem: any) => {
                if (stateItem.roleId === item.roleId) {
                  return (
                    <span
                      onClick={handleToggleRoleRadio}
                      data-id={item.roleId}
                      data-name={name}
                      className={
                        stateItem.default
                          ? styles.radioButtonActive
                          : styles.radioButton
                      }
                    />
                  );
                }
              })}
            </div>
          ))}
          {errors && (
            <span className={styles.errors}>{errors[item.attr.name]}</span>
          )}
        </section>
      );
    case "custom-add-address":
      return (
        <>
          <Input
            value={state.addressId.name}
            placeholder={item.attr.placeholder}
            type={item.attr.text}
            name={item.attr.name}
            style={{ marginTop: "24px" }}
            disabled={true}
          />
          <Button
            appearance={"primary"}
            type={"button"}
            onClick={() => handleSetView(item.attr.view)}
            style={{ width: "auto", marginTop: "12px" }}>
            Przypisz adres
          </Button>
          {errors && <span className={styles.errors}>{errors.addressId}</span>}
        </>
      );
  }
};

export const formActionsMapper = (
  item: IFormMaker,
  handleClear?: () => void
) => {
  const history = useHistory();

  const handleGoBack = () => {
    history.goBack();
  };

  switch (item.tagName) {
    case "button-submit":
      return (
        <Button
          appearance={"primary"}
          style={{ marginTop: "16px", width: "auto" }}>
          {item.attr.name}
        </Button>
      );
    case "button-delete":
      return (
        <Button
          appearance={"primary"}
          style={{ marginTop: "16px", width: "auto" }}>
          {item.attr.name}
        </Button>
      );
    case "button-clear":
      return (
        <ButtonOutlined
          style={{ marginTop: "16px", width: "auto" }}
          type={"button"}
          onClick={handleClear}>
          {item.attr.name}
        </ButtonOutlined>
      );
    case "button-back":
      return (
        <ButtonOutlined
          style={{ marginTop: "16px", width: "auto" }}
          type={"button"}
          onClick={handleGoBack}>
          {item.attr.name}
        </ButtonOutlined>
      );
  }
};

export const defaultStateMapper = (fields: Array<IFormMaker>) => {
  const temp: any = {};
  // eslint-disable-next-line prefer-const
  for (let field of fields) {
    if (field.tagName === "custom-roles") {
      temp[field.attr.name] = field.attr.value.map((item: any) => ({
        roleId: item.roleId,
        default: item.default,
      }));
    } else if (field.tagName === "input" && field.attr.type === "date") {
      temp[field.attr.name] = !field.attr.value
        ? ""
        : parseDateForFrontend(field.attr.value);
    } else if (field.tagName === "input" && field.attr.type === "number") {
      temp[field.attr.name] = field.attr.value.toString();
    } else if (field.tagName === "custom-add-address") {
      temp.addressId = {
        id: field.attr.value,
        name: field.attr.address.city
          ? `${field.attr.address.city}, ${field.attr.address.street} ${
              field.attr.address.houseNumber
            }${
              field.attr.address.apartmentNumber
                ? "/" + field.attr.address.apartmentNumber
                : ""
            }`
          : "",
      };
    } else {
      temp[field.attr.name] = field.attr.value === null ? "" : field.attr.value;
    }
  }
  return temp;
};

export const useQuery = (): URLSearchParams => {
  const location = useLocation();

  return new URLSearchParams(location.search);
};

export const getUrlAuthToken = (): string | null => {
  const q = window.location.search;
  const params = new URLSearchParams(q);
  return params.get("token");
};

export function deepClone<T = any>(object: T): T {
  return _.cloneDeep(object);
}

export const clearDayDate = (date: Date): Date => {
  const tmp = new Date(date.getTime());
  tmp.setHours(0, 0, 0, 0);
  return tmp;
};

export const isTouchDevice = () => {
  return (
    "ontouchstart" in window ||
    navigator.maxTouchPoints > 0 ||
    // @ts-ignore
    navigator.msMaxTouchPoints > 0
  );
};

export const getAddressFullName = (addressReadModel: any): string => {
  if (addressReadModel === null || addressReadModel === undefined) {
    return "";
  }

  let address = "";
  if (addressReadModel.street !== null) {
    address += `${addressReadModel.street.name} ${addressReadModel.houseNumber}`;
  }

  if (addressReadModel.apartmentNumber) {
    address += `/${addressReadModel.apartmentNumber}`;
  }

  if (addressReadModel.postCode !== null) {
    address += `, ${addressReadModel.postCode.name}`;
    if (addressReadModel.city !== null) {
      address += ` ${addressReadModel.city.name}`;
    }
  }

  return address;
};
export const getParameterFromUrl = (name: string): string | null => {
  const urlSegments = window.location.pathname.split("/");
  const parameterIndex = urlSegments.indexOf(name);
  if (parameterIndex !== -1) {
    const desiredElement = urlSegments[parameterIndex + 1];
    if (validate(desiredElement)) return desiredElement;
  }
  return null;
};

//TODO: return getParameterFromUrl('projects')
export const getProjectIdFromUrl = (): string | null => {
  return window.location.pathname.split("/")[1] === "projects"
    ? window.location.pathname.split("/")[2] || null
    : null;
};
export const getPartIdFromUrl = (part: string): string | null => {
  const p = window.location.pathname.split("/");
  const i = p.indexOf(part);
  if (validate(p[i + 1])) return p[i + 1];
  else return null;
};
export const getLocationFromUrl = (): string | null => {
  return getParameterFromUrl("locations");
};

export const getProjectIdFromPath = (pathname: string) => {
  return pathname.split("/")[1] === "projects"
    ? pathname.split("/")[2] || null
    : null;
};

export const getTaskIdFromUrl = () => {
  const path = window.location.pathname;
  const pathParts = path.split("/");
  if (pathParts[2] === undefined) {
    return null;
  }
  if (pathParts[3] === "tasks") {
    return pathParts[4];
  }

  return null;
};

export const handleSortColumn = (
  dataKey: string,
  sortType: "asc" | "desc" | undefined,
  setRequest: SetState
) => {
  setRequest((s: any) => ({
    // todo: type
    ...s,
    requestOrder: {
      field: dataKey === "" ? undefined : dataKey,
      order: sortType,
    },
  }));
};

// todo: otypowac form
export const doubleSidePickerOnChange = (
  assignedIds: Array<string>,
  unAssignedIds: Array<string>,
  assigmentStateKey: string,
  unAssigmentStateKey: string,
  state: any,
  setState: any,
  callback?: () => void
) => {
  const allData = [
    ...state[assigmentStateKey].data,
    ...state[unAssigmentStateKey].data,
  ];

  const itemsAssigned: Array<any> = [];
  assignedIds.forEach((id) => {
    const elm = allData.find((qi) => qi.id === id);
    if (elm === undefined) throw "doubleSidePickerOnChange - item not found!";
    itemsAssigned.push(elm);
  });

  const itemsUnAssigned: Array<any> = [];
  unAssignedIds.forEach((id) => {
    const elm = allData.find((qi) => qi.id === id);
    if (elm === undefined) throw "doubleSidePickerOnChange - item not found!";
    itemsUnAssigned.push(elm);
  });

  setState({
    ...state,
    [assigmentStateKey]: {
      ...state[assigmentStateKey],
      count: assignedIds.length,
      data: itemsAssigned,
    },
    [unAssigmentStateKey]: {
      ...state[unAssigmentStateKey],
      count: unAssignedIds.length,
      data: itemsUnAssigned,
    },
  });

  if (callback) callback();
};

export const rotateBase64Img = (
  base64Image: string,
  isClockwise = true
): string => {
  const offScreenCanvas = document.createElement("canvas");
  const offScreenCanvasCtx = offScreenCanvas.getContext("2d");
  if (offScreenCanvasCtx === null) return "";

  const img = new Image();
  img.src = base64Image;

  // set its dimension to rotated size

  // noinspection JSSuspiciousNameCombination
  offScreenCanvas.height = img.width;
  // noinspection JSSuspiciousNameCombination
  offScreenCanvas.width = img.height;

  // rotate and draw source image into the off-screen canvas:
  if (isClockwise) {
    offScreenCanvasCtx.rotate((90 * Math.PI) / 180);
    offScreenCanvasCtx.translate(0, -offScreenCanvas.width);
  } else {
    offScreenCanvasCtx.rotate((-90 * Math.PI) / 180);
    offScreenCanvasCtx.translate(-offScreenCanvas.height, 0);
  }
  offScreenCanvasCtx.drawImage(img, 0, 0);

  // encode image to data-uri with base64
  return offScreenCanvas.toDataURL("image/jpeg", 100);
};

export function sortByIds<Type>(
  data: Array<Type>,
  sortedIds: Array<string>,
  idKeyName = "id"
): Array<Type> {
  const tmp: Array<Type> = [];
  sortedIds.forEach((id) => {
    // @ts-ignore
    const elm = data.find((v) => v[idKeyName] === id);
    if (!elm) throw "Undefined elm";
    tmp.push(elm);
  });
  return tmp;
}
export const isPublicView = (): boolean => {
  return (
    window.location.pathname.substring(0, "/public/".length) === "/public/"
  );
};

export const isSuperAdmin = (): boolean => {
  const user = getUserFromSession();
  return user?.currentRoleId === "9aa23101-555a-4342-8d6c-6f857fa93f1f";
};

export const getScope = (): TypeScope => {
  let scope: TypeScope = "project";
  if (window.location.pathname.substring(0, "/public".length) === "/public")
    scope = "public";
  if (window.location.pathname.substring(0, "/project".length) === "/project")
    scope = "project";
  if (window.location.pathname.substring(0, "/admin".length) === "/admin")
    scope = "admin";
  if (window.location.pathname.substring(0, "/datasets".length) === "/datasets")
    scope = "datasets";
  if (
    window.location.pathname.substring(0, "/superadmin".length) ===
    "/superadmin"
  )
    scope = "superadmin";
  if (
    window.location.pathname.substring(0, "/visit-review".length) ===
    "/visit-review"
  )
    scope = "visitReview";
  if (
    window.location.pathname.substring(0, "/global-reports".length) ===
    "/global-reports"
  )
    scope = "globalReports";
  if (window.location.pathname.substring(0, "/schedule".length) === "/schedule")
    scope = "schedule";
  if (
    window.location.pathname.substring(0, "/contracts".length) === "/contract"
  )
    scope = "contracts";
  return scope;
};
