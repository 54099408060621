import { IBodyResponse, IVisitStatus } from "../models";
import { AxiosManager, HttpMethod } from "../AxiosHelper";
import { getProjectIdFromUrl } from "utils/helpers";
import AxiosHelper from "../AxiosHelper";
import { getBaseApiUrl, getOrganizationId } from "../helpers";
import { AxiosPromise } from "axios";
import { IDeleteTimeTable } from "../models";
import { TypeWeekdays } from "../types";
import { IScheduleRequestData } from "views/schedules/Hooks/useRequestData";
import { IScheduleData } from "views/schedules/Hooks/useScheduleData";
import { IScheduleModalData } from "views/schedules/Elements/ScheduleModal";
import { IScheduleDynamicForm } from "views/schedules/Hooks/useForm";
import { IScheduleExportData } from "../../views/schedules/Elements/ModalExportSchedule";
import {
  IDay,
  IRequestModify,
  IRequestScheduleBoardData,
} from "views/schedules/Elements/ScheduleViewBoard";
import { ViewType } from "views/schedules/Schedule";
import dayjs, { Dayjs } from "dayjs";
import {
  IScheduleCreateModalData,
  IScheduleRouteAddData,
  IScheduleRouteEditData,
} from "views/schedules/Elements/Board/CreateModal";
import { IScheduleEditModalData } from "../../views/schedules/Elements/Board/EditModal";

const baseUrl = `${getBaseApiUrl()}/organizations/${getOrganizationId()}/timetable`;

export interface ISchedulesAddSingle {
  dates: Array<Date>;
  projectId: string;
  taskId: string;
  locationId: string;
  userId: string;
  isApproved: boolean;
  hours: number | null;
}

export interface ISchedulesAddRecurring {
  type: number; // recurring type
  startDate: null | Date;
  endDate: null | Date;
  days: Array<TypeWeekdays>;
  projectId: string;
  taskId: string;
  locationId: string;
  userId: string;
  isApproved: boolean;
  hours: number | null;
}

export interface IScheduleUserReplacement {
  id: string;
  projectId: string;
  projectName: string;
  locationId: string;
  locationName: string;
  roleId: string;
  roleName: string;
  userFromId: string;
  userFromName: string;
  userToId: string;
  userToName: string;
  startDate: string;
  endDate: string;
}

interface IBoardData {
  data: { days: IDay[] };
  canAdd: boolean;
}

export type ScheduleListStatus = Record<
  string,
  Array<{
    count: number;
    status: IVisitStatus;
  }>
>;

const SchedulesConnection = {
  listStatuses: (requestData: IScheduleRequestData) =>
    AxiosManager.send<ScheduleListStatus>(
      HttpMethod.post,
      `${baseUrl}/pivot/statuses-count`,
      requestData,
      "scheduleListStatuses"
    ),

  pivotList: (requestData: IScheduleRequestData) =>
    AxiosManager.send<IScheduleData>(
      HttpMethod.post,
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/timetable/pivot/list`,
      requestData,
      "schedule_pivot_list"
    ),

  modalData: (rowId: string, timeTableId: string | null) =>
    AxiosManager.send<IScheduleModalData>(
      HttpMethod.get,
      `${baseUrl}/${rowId}/` +
        (timeTableId ? `schedule/${timeTableId}` : `modal`),
      undefined,
      "schedule_modal_data"
    ),

  moveModalData: (timeTableId: string, date: Dayjs) =>
    AxiosManager.send<IScheduleModalData>(
      HttpMethod.get,
      `${baseUrl}/${timeTableId}/${date.unix()}/move`,
      undefined,
      "schedule_move_modal_data"
    ),

  scheduleModalData: (userId: string) =>
    AxiosManager.send<IScheduleCreateModalData>(
      HttpMethod.get,
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/project/${
        getProjectIdFromUrl() ?? "-"
      }/timetable/routes/form/user/${userId}`
    ),

  scheduleModalEditData: (pivotDayId: string) =>
    AxiosManager.send<IScheduleEditModalData>(
      HttpMethod.get,
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/project/${
        getProjectIdFromUrl() ?? "-"
      }/timetable/routes/form/day/${pivotDayId}`
    ),

  scheduleRouteAdd: (data: IScheduleRouteAddData) =>
    AxiosManager.send(
      HttpMethod.post,
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/project/${
        getProjectIdFromUrl() ?? "-"
      }/timetable/routes`,
      data
    ),

  scheduleRouteEdit: (pivotDayId: string, data: IScheduleRouteEditData) =>
    AxiosManager.send(
      HttpMethod.post,
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/project/${
        getProjectIdFromUrl() ?? "-"
      }/timetable/routes/edit/${pivotDayId}`,
      data
    ),

  dynamicForm: (requestData: IScheduleRequestData) =>
    AxiosManager.send<IScheduleDynamicForm>(
      HttpMethod.post,
      `${baseUrl}/pivot/form-dynamic`,
      requestData,
      "scheduleDynamicForm"
    ),

  requestUserPreset: (): AxiosPromise<IScheduleRequestData> => {
    const projectId = getProjectIdFromUrl() ?? "";
    return AxiosHelper.get(
      `${baseUrl}/request-user-preset${projectId ? `/${projectId}` : ""}`
    );
  },

  remove: (data: IDeleteTimeTable) =>
    AxiosManager.send(HttpMethod.post, `${baseUrl}/remove`, data),
  add: (data: IScheduleModalData) =>
    AxiosManager.send(HttpMethod.post, `${baseUrl}/add`, data),
  move: (data: any) =>
    AxiosManager.send(HttpMethod.post, `${baseUrl}/move`, data),
  batchCreate: (data) =>
    AxiosManager.send(HttpMethod.post, `${baseUrl}/add-schedules`, data),
  // todo:
  formOptionsClear: (data: { projectId: null | string }): AxiosPromise<any> => {
    return AxiosHelper.post(`${baseUrl}/form/options/clear`, data);
  },
  clear: (data: {
    projectId: string;
    taskId: string | null;
  }): AxiosPromise<any> => {
    return AxiosHelper.post(`${baseUrl}/clear`, data);
  },
  userReplacementList: (
    requestData: IScheduleRequestData
  ): AxiosPromise<IScheduleUserReplacement[]> => {
    return AxiosHelper.post(`${baseUrl}/list/replacements`, requestData);
  },
  userReplacementRemove: (id: string) => {
    return AxiosHelper.post(`${baseUrl}/replacement/remove`, {
      ids: [{ id: id }],
    });
  },
  userReplacementUpdate: (id: string, data: any) => {
    return AxiosHelper.put(`${baseUrl}/replacement/${id}`, data);
  },
  userReplacementBatchRemove: (ids: { id: string }[]) => {
    return AxiosHelper.post(`${baseUrl}/replacement/remove`, { ids: ids });
  },
  getFile: (data: IScheduleExportData) => {
    return AxiosHelper.getBlobPost(`${baseUrl}/report/get-file`, data);
  },

  boardData: (requestData: IRequestScheduleBoardData) => {
    const _reqeuestData: IScheduleRequestData = {
      ...requestData,
      locations: [],
      networkId: "",
      projects: [getProjectIdFromUrl() ?? ""],
      taskId: "",
      customerId: "",
      statuses: [],
      source: [],
      viewType: ViewType.routes,
      presetId: "",
      presets: [],
      availableViews: [],
      requestPaginate: {
        page: 1,
        limit: 999,
      },
      requestOrder: {
        field: undefined,
        order: undefined,
      },
    };
    return AxiosManager.send<IBodyResponse<IBoardData>>(
      HttpMethod.post,
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/timetable/pivot/list`,
      _reqeuestData,
      "scheduleBoardData"
    );
  },
  boardUsers: async () => {
    const requestData: IScheduleRequestData = {
      projects: [getProjectIdFromUrl() ?? ""],
      locations: [],
      networkId: "",
      taskId: "",
      userId: "",
      customerId: "",
      dateFrom: dayjs().startOf("year").toDate(),
      dateTo: dayjs().toDate(),
      statuses: [],
      source: [],
      viewType: ViewType.routes,
      presetId: "",
      presets: [],
      availableViews: [],
      requestPaginate: {
        page: 1,
        limit: 999,
      },
      requestOrder: {
        field: undefined,
        order: undefined,
      },
    };
    const result = AxiosManager.send<{ users: [] }>(
      HttpMethod.post,
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/project/${
        getProjectIdFromUrl() ?? ""
      }/timetable/routes/form`,
      requestData
    );
    return await result.then((res) => res.data.users);
  },
  boardChanges: (requestData: IRequestModify) =>
    AxiosManager.send(
      HttpMethod.patch,
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/project/${
        getProjectIdFromUrl() ?? ""
      }/timetable/routes`,
      requestData
    ),
};

export default SchedulesConnection;
