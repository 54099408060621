import dayjs from "dayjs";
import React, {
  createContext,
  CSSProperties,
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from "react";
import TileContextMenu, { EnumScope } from "./TileContextMenu";
import {
  useControls,
  useTransformContext,
  useTransformEffect,
} from "react-zoom-pan-pinch";
import { IDay, TileWidth } from "../ScheduleViewBoard";
import BoardTableBody from "./BoardTableBody";

export const BoardDataContext = createContext<{
  days: IDay[];
  groupedDays: IDay[][];
  onDataModified: (data: IDay[]) => void;
}>({
  days: [],
  groupedDays: [],
  onDataModified: () => {
    return;
  },
});

export interface IDataMockup {
  id: string;
  date: Date;
  location: string;
  frequency: number;
  sort: number | null;
}

export interface IDndItem {
  id: string | null;
  location: {
    id: string;
    name: string;
    address: string;
    code: string;
  } | null;
  rowIndex: number;
  date: dayjs.Dayjs;
  frequency: null | number;
  isMovable: boolean;
  isEditable: boolean;
  hasContinuity: boolean;
}

interface IBoardTable {
  dateFrom: Date;
  dateTo: Date;
  scale: number;
  setScale: (scale: number) => void;
  disabledElements?: Array<string>;
  onDataModified: (data: IDay[]) => void;
  allScheduleData: IDay[];
  triggerReload: () => void;
  userId: string;
  canAdd: boolean;
  setCurrentColumn: React.Dispatch<React.SetStateAction<number>>;
  currentColumn: number;
}

export const detailedScaleVal = 2;

const BoardTable: FunctionComponent<IBoardTable> = (props) => {
  const boardContext = useTransformContext();
  const boardControls = useControls();
  const [thStyle, setThStyle] = useState({} as React.CSSProperties);

  useTransformEffect((ref) => {
    // counter auto center
    if (ref.state.positionY > 0) {
      boardControls.setTransform(ref.state.positionX, 0, ref.state.scale, 0);
    }
  });

  useEffect(() => {
    const handleScroll = (event) => {
      // countering scaled table
      setThStyle({ top: `${(event.target.scrollTop / props.scale) * -1}px` });
    };
    const elm = document.getElementsByClassName("react-transform-wrapper")[0];
    if (elm) elm.addEventListener("scroll", handleScroll);
    return () => {
      if (elm) elm.removeEventListener("scroll", handleScroll);
    };
  });

  const numDays = dayjs(props.dateTo).diff(dayjs(props.dateFrom), "day") + 1;
  const header = Array.from({ length: numDays }, (_, i) => {
    return dayjs(props.dateFrom).add(i, "day");
  });

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === "Escape") {
      boardControls.setTransform(0, 0, 1);
    }

    const scale = boardContext.getContext().state.scale;
    const xModifier = TileWidth * scale + 2; // 2px for border size

    if (scale === detailedScaleVal) {
      const popoverElements = document.getElementsByClassName(
        "popover-board-table"
      );

      if (popoverElements.length > 0) {
        Array.from(popoverElements as HTMLCollectionOf<HTMLElement>).forEach(
          (popoverElement) => {
            const currentLeft = popoverElement.style.left || "0px";
            const leftValue = parseInt(currentLeft);
            if (event.key === "ArrowLeft") {
              setTimeout(() => {
                popoverElement.style.left = `${leftValue + xModifier}px`; // Move left
              }, 60);
            }
            if (event.key === "ArrowRight") {
              setTimeout(() => {
                popoverElement.style.left = `${leftValue - xModifier}px`; // Move right
              }, 60);
            }
          }
        );
      }
      if (event.key === "ArrowLeft") {
        props.setCurrentColumn((c: number) => c - 1);
      }

      if (event.key === "ArrowRight") {
        props.setCurrentColumn((c: number) => c + 1);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [props.currentColumn]);

  function MonthsRow() {
    const monthDays: { [monthString: string]: number } = {};
    header.every((d) => {
      monthDays[d.format("MMMM")] = monthDays[d.format("MMMM")]
        ? monthDays[d.format("MMMM")] + 1
        : 1;
      return true;
    });
    return (
      <tr id="months-row">
        {Object.entries(monthDays).map(([month, daysCount], i) => (
          <th colSpan={daysCount} key={`th-month-${i}`}>
            {month}
          </th>
        ))}
      </tr>
    );
  }

  const triggerReload = () => {
    props.triggerReload();
    setScaleUp(props.currentColumn);
  };
  const scroll = (index: number) => {
    setTimeout(() => {
      boardControls.setTransform(
        index ? (index - 1) * -96.8 : 0, //some dimensions
        0,
        props.currentColumn ? detailedScaleVal : 1
      );
    }, 60);
  };
  const setScaleUp = (index) => {
    boardControls.setTransform(0, 0, detailedScaleVal);
    scroll(index);
  };

  useEffect(() => {
    scroll(props.currentColumn);
  }, [props.currentColumn]);

  const getGroupedData = useCallback(() => {
    const _data: IDay[][] = [];
    props.allScheduleData.forEach((d) => {
      const key = dayjs(d.date).format("YYYY-MM-DD");
      if (!_data[key]) {
        _data[key] = [];
      }
      _data[key].push(d);
    });
    return _data;
  }, [props.allScheduleData]);

  const groupedData = getGroupedData();

  function DaysRow() {
    const handleOnClick = (index: number) => {
      if (props.scale === detailedScaleVal) {
        props.setCurrentColumn(0);
        boardControls.setTransform(0, 0, 1, undefined);
      } else {
        props.setCurrentColumn(index);
        setScaleUp(index);
      }
    };

    const TilesCounterBadge = (_props: { date: dayjs.Dayjs }) => {
      const count = (groupedData[_props.date.format("YYYY-MM-DD")] ?? [])
        .length;

      let styles: CSSProperties = {
        background: "white",
        border: "1px solid rgb(221, 221, 221)",
        borderRadius: "5px",
        fontSize: "0.8em",
        position: "absolute",
        top: "-9px",
        left: "5px",
        right: "5px",
        opacity: "0.65",
        display: count > 0 ? "block" : "none",
        fontWeight: "bold",
      };

      if (props.scale === detailedScaleVal) {
        styles = {
          border: count > 0 ? "1px solid #ddd" : "none",
          borderRadius: "5px",
          fontSize: "0.8em",
          margin: "2px 4px",
        };
      }

      return <div style={styles}>{count > 0 ? count : <>&nbsp;</>}</div>;
    };

    return (
      <tr id="month-row">
        {header.map((d, i) => {
          const id = `th-${d.format("DDMMYYYY")}`;
          return (
            <th
              style={thStyle}
              className={`th-week-${d.week() % 2} ${
                d.isSame(dayjs(), "day") ? "today" : ""
              }`}
              id={id}
              key={`thk-${i}`}>
              <TilesCounterBadge date={d} />
              {props.scale < detailedScaleVal ? (
                <div
                  onClick={() => handleOnClick(i + 1)}
                  style={{ lineHeight: "14px", marginTop: "6px" }}>
                  <div style={{ color: "#b8b8b8" }}>{d.format("DD")}</div>
                  <div>{d.format("dd")}</div>
                </div>
              ) : (
                <div className={"full-weekday"}>
                  <TileContextMenu
                    userId={props.userId}
                    scope={EnumScope.COLUMN}
                    date={d}
                    canAdd={
                      !props.disabledElements?.includes(
                        "SYSTEM_GLOBAL-TIMETABLE_PERMISSION_ADD"
                      )
                    }
                    triggerReload={triggerReload}>
                    <div onClick={() => handleOnClick(i + 1)}>
                      <div>{d.format("dddd")}</div>
                      <div style={{ color: "#b8b8b8" }}>
                        {d.format("MM-DD")}
                      </div>
                    </div>
                  </TileContextMenu>
                </div>
              )}
            </th>
          );
        })}
      </tr>
    );
  }

  return (
    <BoardDataContext.Provider
      value={{
        days: props.allScheduleData,
        groupedDays: groupedData,
        onDataModified: props.onDataModified,
      }}>
      <table id="schedules" className={`scale-${props.scale}`}>
        <thead>
          <MonthsRow />
          <DaysRow />
        </thead>
        <BoardTableBody
          dateFrom={props.dateFrom}
          dateTo={props.dateTo}
          triggerReload={triggerReload}
          scale={props.scale}
          userId={props.userId}
          canAdd={props.canAdd}
        />
      </table>
    </BoardDataContext.Provider>
  );
};
export default BoardTable;
