import React, { useContext } from "react";
import { FCC } from "utils/models";
import { IDay } from "../ScheduleViewBoard";
import dayjs from "dayjs";
import TileSocket from "./TileSocket";
import { BoardDataContext, IDndItem } from "./BoardTable";

interface IBoardTableBody {
  triggerReload: () => void;
  scale: number;
  userId: string;
  dateFrom: Date;
  dateTo: Date;
  canAdd: boolean;
}

const BoardTableBody: FCC<IBoardTableBody> = (props) => {
  // const dataRows = useRef<{ [dataId: string]: number }>({}); // reference to row data
  const numDays = dayjs(props.dateTo).diff(dayjs(props.dateFrom), "day") + 1;
  const header = Array.from({ length: numDays }, (_, i) => {
    return dayjs(props.dateFrom).add(i, "day");
  });
  const { groupedDays } = useContext(BoardDataContext);

  const getDndItem = (d: dayjs.Dayjs, rowIndex: number): IDndItem => {
    const dataItem: IDay = (groupedDays[d.format("YYYY-MM-DD")] ?? []).sort(
      (a, b) => (a.order ?? 0) - (b.order ?? 0)
    )?.[rowIndex];

    return {
      id: dataItem?.id,
      rowIndex: rowIndex,
      location: dataItem
        ? {
            id: dataItem.locationId,
            name: dataItem.locationName,
            address: dataItem.locationAddress,
            code: dataItem.locationCustomerCode,
          }
        : null,
      frequency: dataItem?.frequencies?.[0],
      date: d,
      isMovable: dataItem?.isMovable,
      isEditable: dataItem?.isEditable,
      hasContinuity: dataItem?.hasContinuity,
    };
  };
  const getDataRowsNumber = (): number => {
    let maxRows = 0;
    Object.values(groupedDays).forEach((days) => {
      if (days.length > maxRows) maxRows = days.length;
    });
    return maxRows;
  };
  const length = groupedDays ? getDataRowsNumber() + 1 : 1;

  return (
    <tbody>
      {Array.from({ length: length }, (_, rowIndex) => (
        <tr key={`row-${rowIndex}`}>
          {header.map((d, i) => (
            <TileSocket
              triggerReload={props.triggerReload}
              key={`td-${d.format("DDMMYYYY")}-${i}`}
              scale={props.scale}
              userId={props.userId}
              columnData={groupedDays[d.format("YYYY-MM-DD")] ?? []}
              dndItem={getDndItem(d, rowIndex)}
              canAdd={props.canAdd}
            />
          ))}
        </tr>
      ))}
    </tbody>
  );
};

export default BoardTableBody;
