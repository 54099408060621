import CustomersEdit from "views/admin/components/customersEdit/CustomersEdit";
import CustomersList from "views/admin/components/customersList/CustomersList";
import CreateRole from "views/admin/components/rolesAdd/CreateRole";
import EditRole from "views/admin/components/rolesEdit/EditRole";
import RoleList from "views/admin/components/rolesList/RoleList";
import CreateUser from "views/admin/components/usersAdd/CreateUser";
import EditUser from "views/admin/components/usersEdit/EditUser";
import UsersList from "views/admin/components/usersList/UsersList";
import CreateNotification from "../../views/admin/components/notifications/createNotification";
import EditNotification from "../../views/admin/components/notifications/editNotification";
import { IRouteEntity } from "../../utils/models";
import LogsList from "../../views/admin/components/logs/LogsList";
import ProjectConnections from "../../views/admin/components/projectConnections/projectConnections";
import EditUserReplacement from "../../views/admin/components/uesrReplacement/EditUserReplacement";
import Notifications from "views/admin/components/notifications/Notifications";
import AlertForm from "views/projects/components/editProject/Alerts/AlertForm";

const adminRoutes: IRouteEntity[] = [
  { path: "/admin", breadcrumb: "Administracja", redirectTo: "/admin/users" },
  { path: "/admin/users", breadcrumb: "Użytkownicy", Component: UsersList },
  {
    path: "/admin/users/add",
    breadcrumb: "Dodaj użytkownika",
    Component: CreateUser,
  },
  { path: "/admin/users/:id", breadcrumb: undefined },
  {
    path: "/admin/users/:id/edit",
    breadcrumb: "Edytuj użytkownika",
    Component: EditUser,
  },
  {
    path: "/admin/users/:id/replace",
    breadcrumb: "Edytuj zamianę",
    Component: EditUserReplacement,
  },
  {
    path: "/admin/users/:id/replace/projects/:projectId/locations/:locationId/roles/:roleId",
    breadcrumb: "Edytuj zamianę",
    Component: EditUserReplacement,
  },
  { path: "/admin/customers", breadcrumb: "Klienci", Component: CustomersList },
  {
    path: "/admin/customers/add",
    breadcrumb: "Dodaj klienta",
    Component: CustomersEdit,
  },
  { path: "/admin/customers/:id", breadcrumb: undefined },
  {
    path: "/admin/customers/:id/edit",
    breadcrumb: "Edytuj klienta",
    Component: CustomersEdit,
  },
  {
    path: "/admin/roles",
    breadcrumb: "Grupy użytkowników",
    Component: RoleList,
  },
  {
    path: "/admin/roles/add",
    breadcrumb: "Dodaj grupę",
    Component: CreateRole,
  },
  { path: "/admin/roles/:id", breadcrumb: undefined },
  {
    path: "/admin/roles/:id/edit",
    breadcrumb: "Edytuj grupę",
    Component: EditRole,
  },

  {
    path: "/notifications",
    breadcrumb: "Powiadomienia",
    Component: Notifications,
  },

  {
    path: "/notifications/mailer",
    breadcrumb: "Skrzynka nadawcza",
    Component: Notifications,
  },
  {
    path: "/notifications/manual",
    breadcrumb: "Ręczne",
    Component: Notifications,
  },
  {
    path: "/notifications/manual/add",
    breadcrumb: "Dodaj",
    Component: CreateNotification,
  },
  {
    path: "/notifications/manual/:id/edit",
    breadcrumb: "Edytuj",
    Component: EditNotification,
  },
  {
    path: "/notifications/manual/:id/view",
    breadcrumb: "Wyświetl",
    Component: EditNotification,
  },
  {
    path: "/notifications/auto",
    breadcrumb: "Automatyczne",
    Component: Notifications,
  },
  {
    path: "/notifications/auto/add",
    breadcrumb: "Dodaj",
    Component: AlertForm,
  },
  {
    path: "/notifications/auto/:alertId/edit",
    breadcrumb: "Edytuj",
    Component: AlertForm,
  },

  { path: "/admin/logs", breadcrumb: "Logi", Component: LogsList },
  {
    path: "/admin/project-connections",
    breadcrumb: "Powiązania projektów",
    Component: ProjectConnections,
  },
];

export default adminRoutes;
