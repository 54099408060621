import React, { FunctionComponent, useState } from "react";
import Pagination from "global/pagination/Pagination";
import OrganizationsConnection from "utils/connections/organizations";
import { Table } from "rsuite";
import settings from "assets/svg/settings.svg";
import { useHistory } from "react-router-dom";
import UniversalFilters, {
  FILTER_INPUT_TEXT,
  FILTER_SELECT,
} from "../../../../global/filters/UniversalFilters";
import { _paginate30, getOrganizationsState } from "../../../../utils/states";
import { ButtonSort } from "../../../../utils/sortHelper";
import PaginatedTableViewWrapper from "global/PaginatedTableViewWrapper";
import ButtonListRedirect from "../../../../global/atoms/ButtonListRedirect";

interface IOrganizationsList {}

const OrganizationsList: FunctionComponent<IOrganizationsList> = () => {
  const [data, setData] = useState<any | null>(null);
  const [filters, setFilters] = useState<any>({
    name: "",
    countryId: "",
    apkVersion: "",
    ..._paginate30,
  });
  const history = useHistory();
  const [loading, setLoading] = useState(true);

  const handleEdit = (event: any) => {
    history.push(
      `/superadmin/organizations/${event.currentTarget.dataset.id}/edit`
    );
  };

  return (
    <>
      <PaginatedTableViewWrapper
        table={
          <Table fillHeight loading={loading} data={data?.data ?? []}>
            <Table.Column flexGrow={1} align="left">
              <Table.HeaderCell>
                <ButtonSort
                  dataName={"name"}
                  filters={filters}
                  setFilters={setFilters}>
                  Nazwa
                </ButtonSort>
              </Table.HeaderCell>
              <Table.Cell width={100} dataKey="name">
                {(rowData) => (
                  <ButtonListRedirect
                    redirectUrl={`/superadmin/organizations/${rowData.id}/edit`}>
                    {rowData.name}
                  </ButtonListRedirect>
                )}
              </Table.Cell>
            </Table.Column>
            <Table.Column flexGrow={1} align="left">
              <Table.HeaderCell>
                <ButtonSort
                  dataName={"apk"}
                  filters={filters}
                  setFilters={setFilters}>
                  Wersja aplikacji
                </ButtonSort>
              </Table.HeaderCell>
              <Table.Cell width={100} dataKey="apkVersion" />
            </Table.Column>
            <Table.Column
              verticalAlign={"middle"}
              align="right"
              fixed={"right"}>
              <Table.HeaderCell>Akcje</Table.HeaderCell>
              <Table.Cell>
                {(rowData) => (
                  <img
                    src={settings}
                    style={{ cursor: "pointer" }}
                    alt={"Idź do"}
                    data-id={rowData.id}
                    onClick={handleEdit}
                  />
                )}
              </Table.Cell>
            </Table.Column>
          </Table>
        }
        pagination={
          <Pagination
            count={data?.count ?? 0}
            page={filters.requestPaginate.page.toString()}
            limit={filters.requestPaginate.limit.toString()}
            setState={setFilters}
          />
        }
      />

      <UniversalFilters
        load={OrganizationsConnection.getOrganizations}
        formGet={OrganizationsConnection.getFiltersOrganizationsList}
        setLoading={setLoading}
        state={filters}
        setState={setFilters}
        defaultStateData={getOrganizationsState}
        setResultData={setData}
        filterStorageKey={"superadminOrganizationsList"}
        elements={[
          {
            type: FILTER_INPUT_TEXT,
            stateKey: "name",
            label: "Nazwa organizacji",
          },
          {
            type: FILTER_SELECT,
            stateKey: "countryId",
            formKey: "countries",
            label: "Kraj",
          },
        ]}
      />
    </>
  );
};

export default OrganizationsList;
