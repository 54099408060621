import { AxiosPromise } from "axios";
import {
  getAuthHeader,
  getBaseApiUrl,
  getOrganizationId,
} from "../../../../utils/helpers";
import AxiosHelper from "../../../../utils/AxiosHelper";
import { IDeleteLocationType } from "./states";

const baseUrl = (): string => {
  return `${getBaseApiUrl()}/organizations/${getOrganizationId()}/location-types`;
};

const LocationTypeConnection = {
  list: (data: any): AxiosPromise<any> => {
    return AxiosHelper.post(`${baseUrl()}/list`, data);
  },
  getFormFilterLocationType: (): AxiosPromise<any> => {
    return AxiosHelper.get(`${baseUrl()}/filter/form`);
  },
  getForm: (locationTypeId: string): AxiosPromise<any> => {
    return AxiosHelper.get(`${baseUrl()}/${locationTypeId}/form`);
  },
  addForm: (data: any) => {
    return AxiosHelper.post(`${baseUrl()}`, data);
  },
  updateForm: (id: string, data: any): AxiosPromise<any> => {
    return AxiosHelper.put(`${baseUrl()}/${id}`, data);
  },
  removeLocationType: (data: IDeleteLocationType): AxiosPromise<any> => {
    return AxiosHelper.delete(`${baseUrl()}`, {
      headers: getAuthHeader(),
      data,
    });
  },
};

export default LocationTypeConnection;
