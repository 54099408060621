import { IBodyResponse, ISelect, IVisitStatus } from "../models";
import AxiosHelper, {
  AxiosManager,
  HttpMethod,
  default as axios,
} from "../AxiosHelper";
import {
  getAuthHeader,
  getBaseApiUrl,
  getOrganizationId,
  getProjectIdFromUrl,
} from "../helpers";
import { AxiosPromise, AxiosResponse } from "axios";
import { IVisitData } from "../models";
import { generatePath } from "react-router-dom";
import { IVisitCommentItem } from "views/projects/components/visits/visitsEdit/VisitEdit";

const InstancesConnection = {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  getInstances: (data: any) => {
    const projectId = getProjectIdFromUrl();
    return axios.post(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/projects/${projectId}/visits/list`,
      data,
      undefined,
      `${projectId}-visits-list`
    );
  },
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  createInstance: (projectId: string, data: any) => {
    return axios.post(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/projects/${projectId}/visits`,
      data,
      {
        headers: getAuthHeader(),
      }
    );
  },
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  updateInstance: (projectId: string, id: string, data: any) => {
    return AxiosManager.send(
      HttpMethod.put,
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/projects/visits/${id}`,
      data,
      "visit-updateInstance"
    );
  },
  validateVisit: (id: string, data: any) => {
    return AxiosManager.send(
      HttpMethod.put,
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/projects/visits/${id}/validate`,
      data,
      "validateVisit"
    );
  },
  removeInstances: (data: { visits: Array<{ id: string }> }) => {
    return axios.delete(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/projects/visits`,
      {
        headers: getAuthHeader(),
        data: data,
      }
    );
  },
  getFiltersInstancesList: () => {
    const projectId = getProjectIdFromUrl() ?? "";
    return axios.get(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/projects/${projectId}/visits/filter/form`,
      {
        headers: getAuthHeader(),
      },
      `${projectId}-filter-form`
    );
  },
  getFormEditInstance: (
    projectId: string,
    id: string
  ): AxiosPromise<{
    model: IVisitData;
    visitStatuses: ISelect<IVisitStatus>;
    disabledElements: Array<string>;
    hiddenElements: Array<string>;
    comments: IBodyResponse<IVisitCommentItem>;
    visibleVisitSectionsByDefault: { data: Array<string> };
  }> => {
    return AxiosHelper.get(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/projects/${projectId}/visits/${id}/form`
    );
  },
  batchApprove: (status: string, data: Array<{ id: string }>) => {
    return axios.patch(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/projects/visits/approve`,
      {
        operation: status,
        visits: data,
      }
    );
  },

  getApprovalStatuses: (id: string) => {
    return axios.get(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/projects/${id}/visits/options/form`
    );
  },

  batchDelete: (projectId: string, data: Array<{ id: string }>) => {
    return axios.delete(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/projects/visits`,
      {
        headers: { ...getAuthHeader() },
        data: { visits: data },
      }
    );
  },

  getQuestionImageBase64Data: (
    visitQuestionAnswerId: string,
    imageRotation: number
  ) => {
    return axios
      .getBlobPost(`${getBaseApiUrl()}/public/question-answer-photo-rotated`, {
        questionAnswerId: visitQuestionAnswerId,
        rotation: imageRotation,
      })
      .then((resp: AxiosResponse<any>) => {
        return resp.data;
      });
  },
  putRotatedImage: (questionAnswerId: string, rotation) => {
    return axios
      .put(`${getBaseApiUrl()}/public/question-answer-photo`, {
        questionAnswerId: questionAnswerId,
        rotation: rotation,
      })
      .then((resp: AxiosResponse<any>) => {
        return resp.data;
      });
  },

  getQuestionImageLink: (visitQuestionAnswerId: string): string => {
    return `${getBaseApiUrl()}/public/question-answer-photo/${visitQuestionAnswerId}`;
  },

  getVisitImage: (visitQuestionAnswerId: string) => {
    return axios.get(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/projects/visits/question/${visitQuestionAnswerId}/photo`
    );
  },

  getVisitPublicUrl: (visitId: string): string => {
    return generatePath("/public/visit/:organizationId/:projectId/:visitId", {
      organizationId: getOrganizationId(),
      projectId: getProjectIdFromUrl() ?? "",
      visitId: visitId,
    });
  },
  getVisitPdfBlob: (visitId: string) => {
    return AxiosHelper.getBlob(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/projects/${getProjectIdFromUrl()}/visits/${visitId}/pdf`
    );
  },
};

export default InstancesConnection;
