import React, { FunctionComponent, useState } from "react";
import { IGetPaginationBase } from "utils/models";
import { generatePath, useHistory } from "react-router-dom";
import Pagination from "global/pagination/Pagination";
import Table from "rsuite/Table";
import HeaderButtons from "global/atoms/headerButtons/HeaderButtons";
import { Dropdown } from "rsuite";
import PlusIcon from "@rsuite/icons/Plus";
import TrashIcon from "@rsuite/icons/Trash";

import PaginatedTableViewWrapper from "global/PaginatedTableViewWrapper";
import { _paginate30 } from "utils/states";
import LocationCategoryConnection, {
  IDeleteLocationCategory,
} from "../../../../utils/connections/locationCategories";
import {
  deepClone,
  handleSortColumn,
  handleToast,
} from "../../../../utils/helpers";
import OptionsDropdown from "../../../../global/atoms/OptionsDropdown";
import {
  BatchActionDropdownItem,
  ToggleAllCheckbox,
  ToggleSingleCheckbox,
} from "../../../../utils/toggleToDeleteHelper";
import ButtonListRedirect from "../../../../global/atoms/ButtonListRedirect";
import UniversalFilters, {
  FILTER_INPUT_TEXT,
} from "../../../../global/filters/UniversalFilters";

export interface IGetLocationCategories extends IGetPaginationBase {
  name: string;
}

export interface ILocationCategories {
  count: number;
  data: IGetLocationCategories[];
}

export const getLocationCategoriesState: IGetLocationCategories = {
  name: "",
  ..._paginate30,
};

interface IProjectsList {}

const LocationCategoriesList: FunctionComponent<IProjectsList> = () => {
  const [data, setData] = useState<ILocationCategories | null | "error">(null);
  const [filters, setFilters] = useState<IGetLocationCategories>(
    deepClone(getLocationCategoriesState)
  );

  const history = useHistory();
  const [chosenToDelete, setChosenToDelete] = useState<Array<string>>([]);
  const [triggerLoad, setTriggerLoad] = useState(0);
  const [loading, setLoading] = useState<boolean>(true);

  if (data === "error") return <div>Błąd pobierania danych</div>;

  const handleDeleteSelected = () => {
    const data: IDeleteLocationCategory = {
      locationCategory: chosenToDelete.map((id) => ({ id: id })),
    };
    handleToast(LocationCategoryConnection.removeLocationCategory(data)).then(
      () => {
        setChosenToDelete([]);
        setTriggerLoad(Date.now());
      }
    );
  };

  return (
    <>
      <HeaderButtons>
        <OptionsDropdown>
          <Dropdown.Item
            icon={<PlusIcon />}
            onClick={() =>
              history.push(generatePath("/datasets/location-categories/add"))
            }>
            Utwórz nową kategorię
          </Dropdown.Item>
          <BatchActionDropdownItem
            callback={handleDeleteSelected}
            icon={<TrashIcon />}
            chosenRows={chosenToDelete}
            title={`Usuń zaznaczone kategorie (${chosenToDelete.length})`}
            message={"Czy na pewno chcesz usunąć zaznaczone kategorie?"}
          />
        </OptionsDropdown>
      </HeaderButtons>

      <PaginatedTableViewWrapper
        table={
          <Table
            loading={loading}
            fillHeight
            data={data?.data ?? []}
            sortColumn={filters.requestOrder.field}
            sortType={filters.requestOrder.order}
            onSortColumn={(dataKey, sortType) =>
              handleSortColumn(dataKey, sortType, setFilters)
            }>
            <Table.Column width={40} align="left">
              <Table.HeaderCell depth={0} width={0}>
                <ToggleAllCheckbox
                  data={data?.data}
                  state={chosenToDelete}
                  setState={setChosenToDelete}
                />
              </Table.HeaderCell>
              <Table.Cell width={100} depth={0}>
                {(rowData) => (
                  <ToggleSingleCheckbox
                    state={chosenToDelete}
                    setState={setChosenToDelete}
                    selectedId={rowData.id}
                  />
                )}
              </Table.Cell>
            </Table.Column>

            <Table.Column flexGrow={1} minWidth={200} align="left" sortable>
              <Table.HeaderCell depth={0} width={0}>
                Nazwa
              </Table.HeaderCell>
              <Table.Cell width={100} depth={0} dataKey="name">
                {(rowData) => (
                  <ButtonListRedirect
                    redirectUrl={`/datasets/location-categories/${rowData.id}/edit`}>
                    {rowData.name}
                  </ButtonListRedirect>
                )}
              </Table.Cell>
            </Table.Column>
          </Table>
        }
        pagination={
          <Pagination
            count={data?.count ?? 0}
            limit={filters.requestPaginate.limit.toString()}
            page={filters.requestPaginate.page.toString()}
            setState={setFilters}
          />
        }
      />

      <UniversalFilters
        load={LocationCategoryConnection.list}
        formGet={LocationCategoryConnection.getFormFilterLocationCategory}
        setLoading={setLoading}
        state={filters}
        setState={setFilters}
        defaultStateData={getLocationCategoriesState}
        setResultData={setData}
        filterStorageKey={"locationCategoryList"}
        triggerLoad={triggerLoad}
        elements={[
          {
            type: FILTER_INPUT_TEXT,
            stateKey: "name",
            label: "Nazwa",
          },
        ]}
      />
    </>
  );
};

export default LocationCategoriesList;
