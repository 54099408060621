import React, {
  FunctionComponent,
  ReactNode,
  useEffect,
  useState,
} from "react";
import {
  getUserId,
  handleToast,
  handleToastErrorCallback,
  handleToastRedux,
} from "../../../utils/helpers";
import PhotoReviewThumb from "./PhotoReviewThumb";
import {
  IBodyResponse,
  IPhotoReviewErrors,
  IPhotoReviewModal,
  IPhotoReviewRequestData,
  IReviewPhotoSimple,
  IVisitPhotoReviewEntity,
} from "../../../utils/models";

import {
  Button,
  Checkbox,
  Dropdown,
  IconButton,
  Message,
  Popover,
  Toggle,
  Whisper,
} from "rsuite";
import BadgeStatus from "../../../global/atoms/badge/BadgeStatus";
import ActionsContainer from "../../../global/atoms/ActionsContainer";
import WhiteCard from "../../../global/atoms/WhiteCard";
import { useHistory, useLocation } from "react-router-dom";
import connection from "../../../utils/connections/visitReviews";
import VisitReviewsConnection, {
  ISubmitReviewData,
} from "../../../utils/connections/visitReviews";
import {
  photoReviewErrorDef,
  photoReviewModalState,
  photoReviewRequestDataState,
} from "../../../utils/states";
import PhotoReviewModal from "./PhotoReviewModal";
import Pagination from "../../../global/pagination/Pagination";
import styles from "./styles.module.scss";
import UniversalFilters, {
  FILTER_INPUT_DATE,
  FILTER_INPUT_TEXT,
  FILTER_MULTI_SELECT,
  FILTER_SELECT,
  FILTER_SEPARATOR,
} from "../../../global/filters/UniversalFilters";
import SpinnerSmall from "../../../global/atoms/Spinner/SpinnerSmall";
import HeaderButtons from "../../../global/atoms/headerButtons/HeaderButtons";
import download from "downloadjs";
import { useDispatch, useSelector } from "react-redux";
import { AxiosPromise } from "axios";
import FileDownloadIcon from "@rsuite/icons/FileDownload";
import EditIcon from "@rsuite/icons/Edit";
import SeparatorEmpty from "../../../global/atoms/separators/SeparatorEmpty";
import SeparatorLine from "../../../global/atoms/separators/SeparatorLine";
import IconSvg from "../../../global/atoms/IconHelper";
import { faBoxes } from "@fortawesome/free-solid-svg-icons";
import { IRoot } from "redux/models";
import { SET_PHOTO_REVIEW } from "redux/actions";
import { parseFeedbackDetailsForRequest } from "../helper";
import dayjs from "dayjs";
import PlusIcon from "@rsuite/icons/Plus";
import ProgressBar from "../../../global/atoms/ProgressBar";
import SpinnerIcon from "@rsuite/icons/legacy/Spinner";
import SortIcon from "@rsuite/icons/Sort";
import ArrowUpIcon from "@rsuite/icons/ArrowUp";
import ArrowDownIcon from "@rsuite/icons/ArrowDown";
import Form from "rsuite/Form";
import ViewsAuthorizeIcon from "@rsuite/icons/ViewsAuthorize";
import InfoOutline from "@rsuite/icons/InfoOutline";
import { isArray } from "lodash";
import {
  ToastNotificationPush,
  ToastTypes,
} from "../../../global/ToastNotification";

interface IBodyResponseZ<T> extends IBodyResponse<T> {
  zipped: Array<{
    date: string;
    fileName: string;
    size: number;
    type: string;
    progress: number;
    metadata: { [key: string]: Array<string> | string };
  }>;
}

const VisitPhotoReview: FunctionComponent = () => {
  const search = new URLSearchParams(useLocation().search);
  const visitId = search.get("visitId");
  const questionId = search.get("questionId");
  const itemId = search.get("itemId");
  const answerId = search.get("answerId");

  const [reviewData, setReviewData] =
    useState<IBodyResponseZ<IVisitPhotoReviewEntity> | null>(null);
  const [modalData, setModalData] = useState<IPhotoReviewModal | null>(null);
  const [formErrors, setFormErrors] =
    useState<IPhotoReviewErrors>(photoReviewErrorDef);
  const requestInitState = photoReviewRequestDataState;
  const [triggerLoad, setTriggerLoad] = useState(0);
  const [pdfOptions, setPdfOptions] = useState<{ [key: string]: boolean }>({
    project: true,
    location: true,
    date: true,
    reportingUser: true,
    task: true,
    question: true,
  });
  const pdfOptionsIsChecked = () => {
    for (const key in pdfOptions) {
      if (!pdfOptions[key]) return false;
    }
    return true;
  };
  if (visitId) {
    requestInitState.visitId = visitId;
    requestInitState.dateRange = {
      dateFrom: "2000-01-01",
      dateTo: "3000-01-01",
    };
  }
  const [requestData, setRequestData] =
    useState<IPhotoReviewRequestData>(requestInitState);
  const [loading, setLoading] = useState(true);
  const [fileGenerating, setFileGenerating] = useState(false);
  const [detailedView, setDetailedView] = useState(true);
  const history = useHistory();
  const dispatch = useDispatch();

  const [hasKPIFeedback, setHasKPIFeedback] = useState<{
    [identifier: string]: boolean;
  }>({});
  const [hasSLAFeedback, setHasSLAFeedback] = useState<{
    [identifier: string]: boolean;
  }>({});

  const photoReviewState = useSelector((state: IRoot) => state.photoReview);

  useEffect(() => {
    if (photoReviewState === null) setModalData(null);
  }, [photoReviewState]);

  useEffect(() => {
    if ((reviewData?.data.length ?? 0) > 0 && visitId) {
      const visitRd =
        reviewData?.data.filter((rd) => rd.visit.id === visitId) ?? [];

      if (answerId) {
        // single photo review from URL
        const rd = visitRd.find((rd) =>
          rd.photoSimple.find((ps) => ps.value.refQuestionAnswerId === answerId)
        );
        if (rd) {
          const ps = rd.photoSimple.find(
            (ps) => ps.value.refQuestionAnswerId === answerId
          );
          if (ps) {
            doReview(visitId, ps.visitQuestionId, ps.itemId, [
              ps.value.refQuestionAnswerId,
            ]);
          }
        }
      }
      // photo review initiated from URL
      if (questionId) {
        // bulk photo review from URL
        const answerIds: Array<string> = [];
        visitRd.forEach((rd) => {
          rd.photoSimple.forEach((ps) => {
            if (ps.questionId === questionId && ps.itemId === itemId) {
              answerIds.push(ps.value.refQuestionAnswerId);
            }
          });
        });
        //TODO what exactly comes from url visitQuestionId or questionId
        doReview(visitId, questionId, itemId, answerIds);
      }
    }
  }, [reviewData]);
  const isInProgress = () => {
    return (
      (
        reviewData?.zipped.filter(
          (data: { progress: number }) => data.progress < 100
        ) ?? []
      ).length > 0
    );
  };
  const isInProgressPdf = () => {
    return (
      (
        pdfFiles().filter(
          (data: { progress: number }) => data.progress < 100
        ) ?? []
      ).length > 0
    );
  };
  const isInProgressZip = () => {
    return (
      (
        zippedFiles().filter(
          (data: { progress: number }) => data.progress < 100
        ) ?? []
      ).length > 0
    );
  };

  useEffect(() => {
    if (fileGenerating || isInProgress()) {
      const intervalUpdate = setInterval(() => {
        if (fileGenerating || isInProgress()) {
          connection.getFileInfoProgressData(requestData).then((response) => {
            if (!isInProgress()) {
              clearInterval(intervalUpdate);
            }
            //@ts-ignore
            setReviewData((d) => ({ ...d, zipped: response.data.zipped }));
          });
        }
      }, 5000);
      return () => {
        clearInterval(intervalUpdate);
      };
    }
  }, [fileGenerating, isInProgress()]);
  const getAggregationIdentifier = (
    visitId: string,
    visitQuestionId: string,
    itemId: string | null
  ): string => {
    if (!detailedView) return `agg_${visitId}`;
    return `agg_${visitId}_${visitQuestionId}_${itemId ?? "-"}`;
  };

  const getPhotoSimpleAgg = (
    visitId: string | null = null
  ): { [aggIdentifier: string]: Array<IReviewPhotoSimple> } => {
    const photosQuestionAgg: {
      [aggIdentifier: string]: Array<IReviewPhotoSimple>;
    } = {};
    const data = visitId
      ? (reviewData?.data ?? []).filter((rd) => rd.visit.id === visitId) ?? []
      : reviewData?.data ?? [];

    data.forEach((rd) => {
      if (rd.photoSimple.length) {
        rd.photoSimple.forEach((ph: IReviewPhotoSimple) => {
          const _id = getAggregationIdentifier(
            rd.visit.id,
            ph.visitQuestionId,
            ph.itemId
          );
          if (!photosQuestionAgg[_id]) photosQuestionAgg[_id] = [];
          photosQuestionAgg[_id].push(ph);
        });
      }
    });

    return photosQuestionAgg;
  };

  const getPhotoNeighbours = (
    visitId: string,
    answerIds: Array<string>
  ): { prev: null | string; next: null | string } => {
    let next: string | null = null;
    let last: string | null = null;

    const phsAgg = getPhotoSimpleAgg();

    // single
    const phFlat: Array<string> = [];
    Object.values(phsAgg).forEach((varr) => {
      varr.forEach((v) => phFlat.push(v.value.refQuestionAnswerId));
    });

    const cId = phFlat.findIndex((phId) => phId === answerIds[0]);
    if (phFlat[cId - 1]) last = phFlat[cId - 1];
    if (phFlat[cId + 1]) next = phFlat[cId + 1];

    // bulk - override single if found
    if (answerIds.length > 1) {
      let crossPh: null | Partial<IReviewPhotoSimple> = {};
      Object.values(phsAgg).every((varr) => {
        varr.every((v) => {
          if (v.value.refQuestionAnswerId === answerIds[0]) {
            crossPh = v;
            return false;
          }
          return true;
        });
        return crossPh === null;
      });
      if (crossPh) {
        // @ts-ignore
        const identifier = getAggregationIdentifier(
          visitId,
          crossPh.visitQuestionId ?? "",
          crossPh.itemId ?? ""
        );
        const qFlat: Array<string> = Object.keys(phsAgg);
        const cqId = qFlat.findIndex((qi) => qi == identifier);
        if (qFlat[cqId - 1]) last = qFlat[cqId - 1];
        if (qFlat[cqId + 1]) next = qFlat[cqId + 1];
      }
    }

    return {
      prev: last,
      next: next,
    };
  };

  const doReview = (
    visitId: string,
    visitQuestionId: string,
    itemId: string | null,
    answerIds: Array<string>
  ): void => {
    const visit =
      reviewData?.data.find((rd) => rd.visit.id === visitId)?.visit ?? null;
    let phs: Array<IReviewPhotoSimple> =
      getPhotoSimpleAgg(visitId)[
        getAggregationIdentifier(visitId, visitQuestionId, itemId)
      ];
    const neighbours = getPhotoNeighbours(visitId, answerIds);

    // single photo review
    if (answerIds.length === 1) {
      try {
        // @ts-ignore
        phs = [phs.find((ph) => ph.value.refQuestionAnswerId === answerIds[0])];
      } catch {
        alert("AnswerId not found!");
      }
    }

    setModalData({
      ...photoReviewModalState,
      open: true,
      photoSimple: phs,
      visit: visit,
      toggler: Date.now(),
      prevId: neighbours.prev,
      nextId: neighbours.next,
    });

    setFormErrors(photoReviewErrorDef);
  };

  const onReviewModalClose = () => {
    setModalData(null);
    dispatch({ type: SET_PHOTO_REVIEW, payload: null });
  };

  const thumbnailReviewIndicatorUpdate = () => {
    if (!photoReviewState) return;
    photoReviewState.refAnswerIds.forEach((refAnswerId) => {
      if (photoReviewState.feedbackType === "KPI")
        setHasKPIFeedback((s) => ({ ...s, [refAnswerId]: true }));
      if (photoReviewState.feedbackType === "SLA")
        setHasSLAFeedback((s) => ({ ...s, [refAnswerId]: true }));
    });
  };

  const submitReviews = (openNext = false): void => {
    if (!photoReviewState) throw new Error("Photo review state is not defined");

    const data: ISubmitReviewData = {
      refAnswerIds: photoReviewState.refAnswerIds,
      type: photoReviewState.feedbackType,
      note: photoReviewState.note,
      positions: parseFeedbackDetailsForRequest(
        photoReviewState.feedbackDetails
      ),
    };

    handleToastErrorCallback(
      VisitReviewsConnection.submitReview(data),
      (errors) => {
        if (errors) {
          setFormErrors({
            ...formErrors,
            [photoReviewState.feedbackType]: errors,
          });
        }
      }
    ).then(() => {
      thumbnailReviewIndicatorUpdate();
      if (openNext && modalData?.nextId) {
        triggerThumbReview(modalData.nextId);
      } else {
        dispatch({ type: SET_PHOTO_REVIEW, payload: null });
      }
    });
  };

  // todo: not fully functional, go agg then prev to single then again to agg from single, agg will be skipped
  const triggerThumbReview = (identifier: string): void => {
    let found = false;
    if (identifier.substring(0, 3) === "agg") {
      const phs = getPhotoSimpleAgg()[identifier];
      const rd = reviewData?.data.find((rd) =>
        rd.photoSimple.find(
          (ph) =>
            ph.value.refQuestionAnswerId === phs[0].value.refQuestionAnswerId
        )
      );
      if (rd) {
        doReview(
          rd?.visit.id,
          phs[0].visitQuestionId,
          phs[0].itemId,
          phs.map((ph) => ph.value.refQuestionAnswerId)
        );
      }
    } else {
      reviewData?.data.every((rd) => {
        rd.photoSimple.every((ps) => {
          if (ps.value.refQuestionAnswerId === identifier) {
            doReview(rd.visit.id, ps.visitQuestionId, ps.itemId, [
              ps.value.refQuestionAnswerId,
            ]);
            found = true;
            return false;
          }
          return true;
        });
        return !found;
      });
    }
  };

  const handleEdit = (projectId: string, visitId: string) => {
    history.push(`/projects/${projectId}/visits/${visitId}/edit`);
  };

  const downloadFile = (
    connection: (
      requestData: IPhotoReviewRequestData,
      setProgress
    ) => AxiosPromise<any>
  ) => {
    setFileGenerating(true);
    handleToastRedux(
      connection({ ...requestData, options: pdfOptions }, setProgress),
      dispatch
    ).then((res) => {
      download(res.data, res.headers["x-filename"] ?? null);
      setFileGenerating(false);
      setTriggerLoad(dayjs().unix());
      setProgress(undefined);
    });
  };

  const handleZipFileToProcess = () => {
    setFileGenerating(true);
    handleToast(VisitReviewsConnection.postPhotosZipCreate(requestData))
      .then((response) => {
        if (response.status == 200) {
          ToastNotificationPush(
            ToastTypes.success,
            "Tworzenie archiwum w toku, obserwuj pasek postępu"
          );
          connection.getFileInfoProgressData(requestData).then((response) => {
            setFileGenerating(false);
            //@ts-ignore
            setReviewData((d) => ({ ...d, zipped: response.data.zipped }));
          });
        }
      })
      .catch((error) => {
        console.log(error.response);
        ToastNotificationPush(
          ToastTypes.error,
          `Błąd! ${error.response.data.fileName}`
        );
        setFileGenerating(false);
      });
  };
  const handlePdfFileToProcess = () => {
    setFileGenerating(true);
    const _data = { ...requestData, options: pdfOptions };
    handleToast(VisitReviewsConnection.postPhotosPdfCreate(_data))
      .then((response) => {
        if (response.status == 200) {
          ToastNotificationPush(
            ToastTypes.success,
            "Tworzenie dokumentu pdf w toku, obserwuj pasek postępu"
          );
          connection.getFileInfoProgressData(requestData).then((response) => {
            setFileGenerating(false);
            //@ts-ignore
            setReviewData((d) => ({ ...d, zipped: response.data.zipped }));
          });
        }
      })
      .catch((error) => {
        console.log(error.response);
        ToastNotificationPush(
          ToastTypes.error,
          `Błąd! ${error.response.data.fileName}`
        );
        setFileGenerating(false);
      });
  };

  const [progress, setProgress] = useState<
    { progress: number; total: number; completed: number } | undefined
  >();
  const downloadSingleFile = (fileName: string) => {
    handleToastRedux(
      VisitReviewsConnection.getPhotosZipFileBlob(fileName, setProgress),
      dispatch
    ).then((res) => {
      download(res.data, res.headers["x-filename"] ?? null);
      setProgress(undefined);
    });
  };
  const photosContainer = (rowData: IVisitPhotoReviewEntity): ReactNode => {
    return Object.entries(getPhotoSimpleAgg(rowData.visit.id)).map(
      ([aggIdentifier, objs]) => {
        return (
          <div
            className={`${styles.photoContainer}`}
            key={`${rowData.visit.id}-${aggIdentifier}`}>
            {detailedView && (
              <div>
                {objs[0].questionType === "ITEM" && (
                  <>{IconSvg(faBoxes, "Pytanie produktowe")}</>
                )}
                <BadgeStatus color={"#000"}>
                  {objs[0].questionType === "ITEM" && `${objs[0].itemName}: `}
                  {objs[0].activityName} / {objs[0].questionName}
                </BadgeStatus>

                {objs.length > 1 && (
                  <>
                    &nbsp;&nbsp;
                    <Button
                      appearance={"ghost"}
                      size={"xs"}
                      onClick={() =>
                        doReview(
                          rowData.visit.id,
                          objs[0].visitQuestionId,
                          objs[0].itemId,
                          objs.map((o) => o.value.refQuestionAnswerId)
                        )
                      }>
                      Ocena zbiorcza
                    </Button>
                  </>
                )}
              </div>
            )}

            <SeparatorEmpty size={1.5} />
            <div
              className={`${styles.photoReviewThumbContainer} visit-thumbs-container-${rowData.visit.id}`}>
              {objs.map((obj) => (
                <PhotoReviewThumb
                  hasKPIFeedback={
                    hasKPIFeedback[obj.value.refQuestionAnswerId] ||
                    obj.hasKPIFeedback
                  }
                  hasSLAFeedback={
                    hasSLAFeedback[obj.value.refQuestionAnswerId] ||
                    obj.hasSLAFeedback
                  }
                  doReview={doReview}
                  reviewPhotoSimple={obj}
                  visit={rowData.visit}
                  key={`pt-${obj.value.refQuestionAnswerId}-${aggIdentifier}`}
                />
              ))}
            </div>
          </div>
        );
      }
    );
  };
  const importIsDisabled = () => {
    return (
      fileGenerating ||
      !reviewData?.count ||
      (!requestData.activityIds && !requestData.visitId)
    );
  };
  const pdfFiles = () => {
    return reviewData?.zipped.filter((f) => f.type == "pdf") ?? [];
  };
  const zippedFiles = () => {
    return reviewData?.zipped.filter((f) => f.type == "zip") ?? [];
  };

  const formatFileSize = (size: number): string => {
    if (size < 1024) return `${size} B`;
    if (size < 1024 * 1024) return `${(size / 1024).toFixed(2)} kB`;
    if (size < 1024 * 1024 * 1024)
      return `${(size / 1024 / 1024).toFixed(2)} MB`;
    return `${(size / 1024 / 1024 / 1024).toFixed(2)} GB`;
  };

  const sortableFields = [
    "project",
    "location",
    "reportingUser",
    "network",
    "startDate",
    "status",
  ];
  const setRequestOrder = (key: string) => {
    if (requestData.requestOrder.field === key) {
      setRequestData((rd) => ({
        ...rd,
        requestOrder: {
          field: key,
          order: requestData.requestOrder.order == "desc" ? "asc" : "desc",
        },
      }));
    } else {
      setRequestData((rd) => ({
        ...rd,
        requestOrder: { field: key, order: "asc" },
      }));
    }
  };

  const getOrderByIcon = (key: string) => {
    if (requestData.requestOrder.field === key) {
      return requestData.requestOrder.order === "asc" ? (
        <ArrowUpIcon style={{ color: "#FFAF38" }} />
      ) : (
        <ArrowDownIcon style={{ color: "#FFAF38" }} />
      );
    }
    if (sortableFields.includes(key))
      return <SortIcon style={{ color: "#FFAF38" }} />;
  };
  return (
    <>
      <HeaderButtons>
        {pdfFiles() && (
          <Dropdown
            icon={
              isInProgressPdf() ? <SpinnerIcon spin /> : <FileDownloadIcon />
            }
            appearance={"ghost"}
            title={`.PDF (${pdfFiles().length})`}
            placement={"bottomEnd"}>
            <Dropdown.Item
              style={{ minWidth: "300px" }}
              icon={<PlusIcon />}
              disabled={importIsDisabled()}
              onClick={handlePdfFileToProcess}>
              Pobierz nowy
            </Dropdown.Item>
            <Dropdown.Separator />
            <Form fluid>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  paddingLeft: "10px",
                }}>
                <Dropdown
                  appearance={"ghost"}
                  title={"Opcje opisu w raporcie"}
                  icon={<ViewsAuthorizeIcon />}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      padding: "10px",
                    }}>
                    <Checkbox
                      checked={pdfOptionsIsChecked() ?? false}
                      onChange={(value, checked) => {
                        if (checked) {
                          setPdfOptions((s) => {
                            const options = {};
                            for (const key in s) {
                              options[key] = true;
                            }
                            return options;
                          });
                        } else {
                          setPdfOptions((s) => {
                            const options = {};
                            for (const key in s) {
                              options[key] = false;
                            }
                            return options;
                          });
                        }
                      }}>
                      Zaznacz/Odznacz wszystkie
                    </Checkbox>

                    <Checkbox
                      checked={pdfOptions?.project ?? false}
                      onChange={(value, checked) => {
                        setPdfOptions((s) => ({ ...s, project: checked }));
                      }}>
                      Projekt
                    </Checkbox>
                    <Checkbox
                      checked={pdfOptions?.location ?? false}
                      onChange={(value, checked) => {
                        setPdfOptions((s) => ({ ...s, location: checked }));
                      }}>
                      Lokalizacja
                    </Checkbox>
                    <Checkbox
                      checked={pdfOptions?.task ?? false}
                      onChange={(value, checked) => {
                        setPdfOptions((s) => ({ ...s, task: checked }));
                      }}>
                      Zadanie
                    </Checkbox>
                    <Checkbox
                      checked={pdfOptions?.question ?? false}
                      onChange={(value, checked) => {
                        setPdfOptions((s) => ({ ...s, question: checked }));
                      }}>
                      Pytanie
                    </Checkbox>
                    <Checkbox
                      checked={pdfOptions?.date ?? false}
                      onChange={(value, checked) => {
                        setPdfOptions((s) => ({ ...s, date: checked }));
                      }}>
                      Data
                    </Checkbox>
                    <Checkbox
                      checked={pdfOptions?.reportingUser ?? false}
                      onChange={(value, checked) => {
                        setPdfOptions((s) => ({
                          ...s,
                          reportingUser: checked,
                        }));
                      }}>
                      Raportujący
                    </Checkbox>
                    <Checkbox
                      checked={pdfOptions?.logo ?? false}
                      onChange={(value, checked) => {
                        setPdfOptions((s) => ({ ...s, logo: checked }));
                      }}>
                      Logo
                    </Checkbox>
                    <Checkbox
                      checked={pdfOptions?.landScape ?? false}
                      onChange={(value, checked) => {
                        setPdfOptions((s) => ({ ...s, landScape: checked }));
                      }}>
                      Układ poziomy
                    </Checkbox>
                  </div>
                </Dropdown>
              </div>
            </Form>
            <Dropdown.Separator />
            {pdfFiles().map((pdf) => (
              <>
                <Dropdown.Item
                  icon={<FileDownloadIcon />}
                  onClick={downloadSingleFile.bind(null, pdf.fileName)}>
                  <Whisper
                    placement={"leftStart"}
                    trigger={"hover"}
                    speaker={
                      <Popover style={{ maxWidth: "200px" }}>
                        {Object.entries(pdf.metadata).map(([key, value]) => {
                          return (
                            <>
                              <div style={{ fontWeight: "bold" }}>{key}:</div>
                              <div style={{}}>
                                {isArray(value) ? value.join(",") : value}
                              </div>
                            </>
                          );
                        })}
                      </Popover>
                    }>
                    <InfoOutline />
                  </Whisper>
                  {formatFileSize(pdf.size)} {pdf.date}
                  {pdf.progress < 100 && (
                    <ProgressBar completed={pdf.progress} />
                  )}
                </Dropdown.Item>
              </>
            ))}
          </Dropdown>
        )}
        {!pdfFiles() && (
          <IconButton
            appearance={"ghost"}
            icon={<FileDownloadIcon />}
            disabled={importIsDisabled()}
            onClick={downloadFile.bind(
              null,
              VisitReviewsConnection.getPhotosPDFBlob
            )}>
            .PDF
          </IconButton>
        )}
        {zippedFiles() && (
          <Dropdown
            icon={
              isInProgressZip() ? <SpinnerIcon spin /> : <FileDownloadIcon />
            }
            appearance={"ghost"}
            title={`.ZIP (${zippedFiles().length})`}
            placement={"bottomEnd"}>
            <Dropdown.Item
              icon={<PlusIcon />}
              disabled={importIsDisabled()}
              onClick={handleZipFileToProcess}>
              Pobierz nowy
            </Dropdown.Item>
            {zippedFiles().map((zip) => {
              return (
                <>
                  <Dropdown.Item
                    icon={<FileDownloadIcon />}
                    onClick={downloadSingleFile.bind(null, zip.fileName)}>
                    <Whisper
                      placement={"leftStart"}
                      trigger={"hover"}
                      speaker={
                        <Popover style={{ maxWidth: "200px" }}>
                          {Object.entries(zip.metadata).map(([key, value]) => {
                            return (
                              <>
                                <div style={{ fontWeight: "bold" }}>{key}:</div>
                                <div style={{}}>
                                  {isArray(value) ? value.join(",") : value}
                                </div>
                              </>
                            );
                          })}
                        </Popover>
                      }>
                      <InfoOutline />
                    </Whisper>
                    {formatFileSize(zip.size)} {zip.date}
                    {zip.progress < 100 && (
                      <ProgressBar completed={zip.progress} />
                    )}
                  </Dropdown.Item>
                </>
              );
            })}
          </Dropdown>
        )}
        {!zippedFiles() && (
          <IconButton
            appearance={"ghost"}
            icon={<FileDownloadIcon />}
            disabled={importIsDisabled()}
            onClick={handleZipFileToProcess}>
            .ZIP
          </IconButton>
        )}
      </HeaderButtons>

      <WhiteCard mobileTransparent={true} padding={false}>
        <div style={{ padding: "15px", position: "relative" }}>
          <strong>Tryb widoku</strong>&nbsp;
          <Toggle
            size="md"
            checkedChildren="Szczegółowy"
            unCheckedChildren="Ogólny"
            checked={detailedView}
            onChange={(checked) => setDetailedView(checked)}
          />
          {visitId && (
            <>
              <SeparatorEmpty size={2} />
              <Message showIcon type={"warning"}>
                Wynik ograniczony do pojedynczej wizyty! &nbsp;&nbsp;
                <Button
                  onClick={() => {
                    window.location.href = "/visit-reviews";
                    // history.push(`/visit-reviews`);
                  }}
                  size="xs"
                  appearance={"ghost"}>
                  Pełen widok
                </Button>
              </Message>
            </>
          )}
          {progress && (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: "20px",
                width: "500px",
                position: "absolute",
                right: "15px",
                top: "5px",
              }}>
              <ProgressBar
                completed={parseFloat(progress.progress.toFixed(2))}
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  width: "250px",
                  alignItems: "center",
                  gap: "5px",
                }}>
                <span>{formatFileSize(progress.completed)} </span>
                <span>/</span>
                <span>{formatFileSize(progress.total)}</span>
              </div>
            </div>
          )}
        </div>

        {loading ? (
          <SpinnerSmall />
        ) : reviewData === null ? (
          <></>
        ) : (
          <div style={{ height: "100%" }}>
            <div className={styles.headerWrapper}>
              <div
                className={`${styles.headerElm} ${styles.flexGrow} `}
                style={{ display: "flex", cursor: "pointer" }}>
                <div
                  onClick={() => {
                    setRequestOrder("project");
                  }}>
                  Projekt{getOrderByIcon("project")}
                </div>
                /
                <div onClick={setRequestOrder.bind(null, "location")}>
                  Lokalizacja{getOrderByIcon("location")}
                </div>
              </div>
              <div
                onClick={setRequestOrder.bind(null, "reportingUser")}
                className={`${styles.headerElm}`}
                style={{ minWidth: "250px", cursor: "pointer" }}>
                Raportujący {getOrderByIcon("reportingUser")}
              </div>
              <div
                onClick={setRequestOrder.bind(null, "network")}
                className={`${styles.headerElm}`}
                style={{ minWidth: "100px", cursor: "pointer" }}>
                Sieć {getOrderByIcon("network")}
              </div>
              <div
                onClick={setRequestOrder.bind(null, "startDate")}
                className={`${styles.headerElm}`}
                style={{ minWidth: "100px", cursor: "pointer" }}>
                Data {getOrderByIcon("startDate")}
              </div>
              <div
                onClick={setRequestOrder.bind(null, "status")}
                className={`${styles.headerElm}`}
                style={{ minWidth: "150px", cursor: "pointer" }}>
                Status {getOrderByIcon("status")}
              </div>
              <div
                className={`${styles.headerElm}`}
                style={{ minWidth: "100px", textAlign: "right" }}>
                Akcje
              </div>
            </div>

            <div
              style={{
                overflow: "auto",
                height: "100%",
                paddingBottom: "100px",
              }}>
              {(reviewData?.data ?? []).map((d) => (
                <div key={`row-${d.visit.id}`} className={styles.grandWrapper}>
                  <div className={styles.bodyRow}>
                    <div className={`${styles.Cell} ${styles.flexGrow}`}>
                      {d.visit.projectName}/{d.visit.locationName} -{" "}
                      {d.visit.locationAddress}
                    </div>
                    <div
                      className={`${styles.Cell}`}
                      style={{ minWidth: "250px" }}>
                      {d.visit.reportingUserName}
                    </div>
                    <div
                      className={`${styles.Cell}`}
                      style={{ minWidth: "100px" }}>
                      {d.visit.networkName}
                    </div>
                    <div
                      className={`${styles.Cell}`}
                      style={{ minWidth: "100px" }}>
                      {d.visit.startDate}
                    </div>
                    <div
                      className={`${styles.Cell}`}
                      style={{ minWidth: "150px" }}>
                      <BadgeStatus color={d.visit.status.color}>
                        {d.visit.status.name}
                      </BadgeStatus>
                    </div>
                    <div
                      className={`${styles.Cell}`}
                      style={{ minWidth: "100px", justifyContent: "end" }}>
                      <ActionsContainer>
                        <IconButton
                          appearance={"subtle"}
                          onClick={handleEdit.bind(
                            null,
                            d.visit.projectId,
                            d.visit.id
                          )}
                          icon={<EditIcon />}
                        />
                      </ActionsContainer>
                    </div>
                  </div>
                  {photosContainer(d)}
                  <SeparatorLine size={1} />
                </div>
              ))}
            </div>
          </div>
        )}
      </WhiteCard>

      <PhotoReviewModal
        onClose={onReviewModalClose}
        modalData={modalData}
        errors={formErrors}
        goToPhoto={triggerThumbReview}
        submitReview={submitReviews}
      />

      <Pagination
        count={reviewData?.count ?? 0}
        page={requestData.requestPaginate.page.toString()}
        limit={requestData.requestPaginate.limit.toString()}
        setState={setRequestData}
      />

      <UniversalFilters
        load={connection.getPhotoReviewData}
        setLoading={setLoading}
        state={requestData}
        triggerLoad={triggerLoad}
        setState={setRequestData}
        headerLabelsDisabled={!!visitId}
        filterButtonHidden={!!visitId}
        defaultStateData={photoReviewRequestDataState}
        setResultData={setReviewData}
        formGet={connection.getPhotoReviewFilter}
        filterStorageKey={`visitPhotoReview_multi_userId-${getUserId()}-${
          visitId ? dayjs().unix : ""
        }`}
        elements={[
          {
            type: FILTER_SELECT,
            label: "Ocenione",
            stateKey: "hasFeedback",
            formKey: "hasFeedback",
          },
          {
            type: FILTER_MULTI_SELECT,
            label: "Status wizyty",
            stateKey: "visitStatuses",
            formKey: "visitStatuses",
          },
          {
            type: FILTER_SEPARATOR,
            stateKey: "",
            label: "",
          },
          {
            type: FILTER_INPUT_DATE,
            outputFormat: "yyyy-MM-dd",
            stateKey: "dateRange.dateFrom",
            //defaultValueFromKey: "dateRange.dateFrom",
            label: "Data wykonania wizyty od",
          },
          {
            type: FILTER_INPUT_DATE,
            outputFormat: "yyyy-MM-dd",
            stateKey: "dateRange.dateTo",
            //defaultValueFromKey: "dateRange.dateTo",
            label: "Data wykonania wizyty do",
          },

          {
            type: FILTER_SEPARATOR,
            stateKey: "",
            label: "",
          },
          {
            type: FILTER_MULTI_SELECT,
            label: "Projekty",
            stateKey: "projectIds",
            formKey: "projects",
          },
          {
            type: FILTER_MULTI_SELECT,
            label: "Zadanie",
            stateKey: "taskIds",
            formKey: "tasks",
            filter: ["projectIds", "projectId"],
            groupBy: "projectName",
            filterIgnoreIfEmpty: true,
          },
          {
            type: FILTER_MULTI_SELECT,
            label: "Aktywność",
            stateKey: "activityIds",
            formKey: "activities",
            filter: ["taskIds", "taskId"],
            groupBy: "taskName",
            filterIgnoreIfEmpty: true,
          },
          {
            type: FILTER_MULTI_SELECT,
            label: "Pytanie",
            stateKey: "questionIds",
            formKey: "questions",
            filter: ["activityIds", "activityId"],
            groupBy: "activityName",
            filterIgnoreIfEmpty: true,
          },
          {
            type: FILTER_SEPARATOR,
            stateKey: "",
            label: "",
          },
          {
            type: FILTER_MULTI_SELECT,
            label: "Sieć",
            stateKey: "networkIds",
            formKey: "networks",
          },
          {
            type: FILTER_MULTI_SELECT,
            label: "Lokalizacja",
            stateKey: "locationIds",
            formKey: "locations",
            groupBy: "network_name",
            filter: ["networkIds", "network_id"],
            filterIgnoreIfEmpty: true,
          },
          {
            type: FILTER_INPUT_TEXT,
            stateKey: "locationCode",
            label: "Zewnętrzny kod lokalizacji",
          },
          {
            type: FILTER_INPUT_TEXT,
            stateKey: "locationNetworkCode",
            label: "Kod sieciowy lokalizacji",
          },
          {
            type: FILTER_INPUT_TEXT,
            stateKey: "locationCustomerCode",
            label: "Kod klienta lokalizacji",
          },
          {
            type: FILTER_SEPARATOR,
            stateKey: "",
            label: "",
          },
          {
            type: FILTER_MULTI_SELECT,
            stateKey: "roleIds",
            formKey: "merchRoles",
            label: "Grupa",
          },
          {
            type: FILTER_MULTI_SELECT,
            stateKey: "userRoleIds",
            formKey: "users",
            label: "Grupa -> Użytkownik",
            groupBy: "roleName",
            filter: ["roleIds", "roleId"],
            filterIgnoreIfEmpty: true,
          },
        ]}
      />
    </>
  );
};
export default VisitPhotoReview;
