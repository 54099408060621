import AxiosHelper, { default as axios } from "../AxiosHelper";
import {
  getAuthHeader,
  getBaseApiUrl,
  getOrganizationId,
  getProjectIdFromUrl,
} from "../helpers";
import { IAddTask, IGetProjectTasks } from "../models";

const TasksConnection = {
  getTasksForProject: (data: IGetProjectTasks) => {
    const projectId = getProjectIdFromUrl() ?? "";
    return axios.post(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/projects/${projectId}/tasks/list`,
      data,
      { headers: getAuthHeader() }
    );
  },
  createTaskForProject: (projectId: string, data: IAddTask) => {
    return axios.post(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/projects/${projectId}/tasks`,
      data,
      { headers: getAuthHeader() }
    );
  },
  updateTaskForProject: (projectId: string, taskId: string, data: IAddTask) => {
    return axios.put(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/projects/${projectId}/tasks/${taskId}`,
      data,
      { headers: getAuthHeader() }
    );
  },
  getFormAddTask: (projectId: string) => {
    return axios.get(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/projects/${projectId}/tasks/form`,
      {
        headers: getAuthHeader(),
      }
    );
  },
  deleteTask: (projectId: string, taskId: string) => {
    return axios.delete(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/projects/${projectId}/tasks/${taskId}`,
      {
        headers: getAuthHeader(),
      }
    );
  },
  cloneTask: (projectId: string, taskId: string, data) => {
    return axios.post(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/projects/${projectId}/tasks/${taskId}/clone`,
      data
    );
  },
  getFormEditTask: (projectId: string, taskId: string) => {
    return axios.get(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/project/${projectId}/tasks/${taskId}/form`,
      {
        headers: getAuthHeader(),
      }
    );
  },
  getFiltersForTasks: () => {
    const projectId = getProjectIdFromUrl() ?? "";
    return axios.get(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/projects/${projectId}/tasks/filter/form`,
      { headers: getAuthHeader() }
    );
  },
  refreshingAction: () => {
    const projectId = getProjectIdFromUrl() ?? "";
    return axios.post(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/projects/${projectId}/visits/update-visit-bodies`
    );
  },
  getAssignedLocationsForActivity: (
    projectId: string,
    taskId: string,
    activityId: string
  ) => {
    return axios.get(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/projects/${projectId}/tasks/${taskId}/activities/${activityId}/locations/assigned/list`,
      { headers: getAuthHeader() }
    );
  },
  getUnassignedLocationsForActivity: (
    projectId: string,
    taskId: string,
    activityId: string
  ) => {
    return axios.get(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/projects/${projectId}/tasks/${taskId}/activities/${activityId}/locations/unassigned/list`,
      { headers: getAuthHeader() }
    );
  },
  getAssignedItemsForActivity: (
    projectId: string,
    taskId: string,
    activityId: string
  ) => {
    return axios.get(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/projects/${projectId}/tasks/${taskId}/activities/${activityId}/items/assigned/list`,
      { headers: getAuthHeader() }
    );
  },
  getUnassignedItemsForActivity: (
    projectId: string,
    taskId: string,
    activityId: string
  ) => {
    return axios.get(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/projects/${projectId}/tasks/${taskId}/activities/${activityId}/items/unassigned/list`,
      { headers: getAuthHeader() }
    );
  },
  assignLocationsToActivity: (
    projectId: string,
    taskId: string,
    activityId: string,
    data: Array<{ locationId: string }>
  ) => {
    return axios.patch(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/projects/${projectId}/tasks/${taskId}/activities/${activityId}/locations/assign`,
      { locations: data },
      { headers: getAuthHeader() }
    );
  },
  detachLocationsFromActivity: (
    projectId: string,
    taskId: string,
    activityId: string,
    data: Array<{ locationId: string }>
  ) => {
    return axios.delete(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/projects/${projectId}/tasks/${taskId}/activities/${activityId}/locations/detach`,
      { headers: getAuthHeader(), data: { locations: data } }
    );
  },
  assignItemsToActivity: (
    projectId: string,
    taskId: string,
    activityId: string,
    data: Array<{ itemId: string }>
  ) => {
    return axios.patch(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/projects/${projectId}/tasks/${taskId}/activities/${activityId}/items/assign`,
      { items: data },
      { headers: getAuthHeader() }
    );
  },
  detachItemsFromActivity: (
    projectId: string,
    taskId: string,
    activityId: string,
    data: Array<{ itemId: string }>
  ) => {
    return axios.delete(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/projects/${projectId}/tasks/${taskId}/activities/${activityId}/items/detach`,
      { headers: getAuthHeader(), data: { items: data } }
    );
  },
  getAssignedQuestionsForActivity: (
    projectId: string,
    taskId: string,
    activityId: string
  ) => {
    return axios.get(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/projects/${projectId}/tasks/${taskId}/activities/${activityId}/questions/assigned/list`,
      { headers: getAuthHeader() }
    );
  },
  getUnassignedQuestionsForActivity: (
    projectId: string,
    taskId: string,
    activityId: string
  ) => {
    return axios.get(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/projects/${projectId}/tasks/${taskId}/activities/${activityId}/questions/unassigned/list`,
      { headers: getAuthHeader() }
    );
  },
  assignQuestionsToActivity: (
    projectId: string,
    taskId: string,
    activityId: string,
    data: Array<{ questionId: string }>
  ) => {
    return axios.patch(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/projects/${projectId}/tasks/${taskId}/activities/${activityId}/questions/assign`,
      { questions: data },
      { headers: getAuthHeader() }
    );
  },
  updateBatchTaskOrder: (projectId: string, data: Array<{ id: string }>) => {
    return AxiosHelper.put(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/projects/${projectId}/tasks/order`,
      { taskIds: data },
      undefined,
      "taskLiveUpdate"
    );
  },
  detachQuestionsFromActivity: (
    projectId: string,
    taskId: string,
    activityId: string,
    data: Array<{ questionId: string }>
  ) => {
    return axios.delete(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/projects/${projectId}/tasks/${taskId}/activities/${activityId}/questions/detach`,
      { headers: getAuthHeader(), data: { questions: data } }
    );
  },
};

export default TasksConnection;
