import React, { FunctionComponent, useContext, useState } from "react";
import { Dropdown, Popover } from "rsuite";
import Whisper from "rsuite/esm/Whisper";
import TrashIcon from "@rsuite/icons/Trash";
import DragableIcon from "@rsuite/icons/Dragable";
import PlusIcon from "@rsuite/icons/Plus";
import { BoardDataContext, IDndItem } from "./BoardTable";
import dayjs from "dayjs";
import MoveAllModal from "./MoveAllModa";
import CreateModal from "./CreateModal";
import { addDays } from "date-fns";
import EditIcon from "@rsuite/icons/Edit";
import EditModal from "./EditModal";

export enum EnumScope {
  VISIT = "1", // visit inside cell
  COLUMN = "2", // column
  CELL = "3", // empty cell
}

enum EnumTileContextMenu {
  EDIT = "1",
  DELETE = "2",
  GO_TO_VISIT = "3",
  MOVE = "4",
  ADD = "5",
}

interface ITileContextMenu {
  children: JSX.Element;
  scope: EnumScope;
  item?: IDndItem;
  date: dayjs.Dayjs;
  userId: string;
  triggerReload: () => void;
  canAdd: boolean;
}

const TileContextMenu: FunctionComponent<ITileContextMenu> = (props) => {
  const { days, onDataModified } = useContext(BoardDataContext);
  const [moveModal, setMoveModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [createModal, setCreateModal] = useState(false);
  const [popoverOpened, setPopoverOpened] = useState(false);

  const _TileContextMenu = ({ onClose, className }, ref, scope: EnumScope) => {
    const handleSelect = (eventKey: EnumTileContextMenu): void => {
      switch (eventKey) {
        case EnumTileContextMenu.DELETE: {
          if (props.item) {
            onDataModified(days.filter((item) => item.id !== props.item?.id));
          } else {
            onDataModified(
              days.filter((item) => !props.date.isSame(item.date, "day"))
            );
          }
          break;
        }
        case EnumTileContextMenu.MOVE: {
          if (!props.item) {
            setMoveModal(true);
          }
          break;
        }
        case EnumTileContextMenu.EDIT: {
          setEditModal(true);
          break;
        }
        case EnumTileContextMenu.ADD: {
          setCreateModal(true);
          break;
        }
      }
      onClose();
    };

    return (
      <Popover
        ref={ref}
        className={`${className} popover-board-table`}
        full
        id={"popover-board-table"}>
        <Dropdown.Menu
          onSelect={(key) => handleSelect(key as EnumTileContextMenu)}>
          {props.canAdd && (
            <Dropdown.Item
              icon={<PlusIcon />}
              eventKey={EnumTileContextMenu.ADD}>
              Dodaj nowy
            </Dropdown.Item>
          )}
          {scope === EnumScope.VISIT && (
            <>
              {
                <Dropdown.Item
                  disabled={!props.item?.isEditable}
                  icon={<EditIcon />}
                  eventKey={EnumTileContextMenu.EDIT}>
                  Edytuj
                </Dropdown.Item>
              }
            </>
          )}
          {props.date.isAfter(dayjs().subtract(1, "day"), "day") &&
            scope === EnumScope.COLUMN && (
              <Dropdown.Item
                disabled={!(props.item?.isEditable ?? true)}
                icon={<DragableIcon style={{ transform: "rotate(90deg)" }} />}
                eventKey={EnumTileContextMenu.MOVE}>
                Przesuń {scope === EnumScope.COLUMN && "wszystkie"}
              </Dropdown.Item>
            )}
          {props.date.isAfter(dayjs().subtract(1, "day"), "day") &&
            [EnumScope.COLUMN, EnumScope.VISIT].includes(scope) && (
              <Dropdown.Item
                disabled={!(props.item?.isEditable ?? true)}
                style={
                  props.item?.isEditable ?? true ? { color: "red" } : undefined
                }
                icon={<TrashIcon />}
                eventKey={EnumTileContextMenu.DELETE}>
                Usuń {scope === EnumScope.COLUMN && "wszystkie"}
              </Dropdown.Item>
            )}
        </Dropdown.Menu>
      </Popover>
    );
  };

  if (props.date.isBefore(addDays(new Date(), 1), "day"))
    return <>{props.children}</>;
  return (
    <>
      <Whisper
        onContextMenu={() => setPopoverOpened(true)}
        // onOpen={() => setPopoverOpened(true)}
        onClose={() => setPopoverOpened(false)}
        placement="bottomStart"
        trigger="contextMenu"
        speaker={(_props, ref) => _TileContextMenu(_props, ref, props.scope)}>
        <div className={`${popoverOpened ? "popover-opened" : ""}`}>
          {props.children}
        </div>
      </Whisper>
      {moveModal && (
        <MoveAllModal
          onClose={() => setMoveModal(false)}
          onSubmit={(date) => {
            // todo: validation (location)
            onDataModified([
              ...days.filter((d) => !dayjs(d.date).isSame(props.date, "day")),
              ...days
                .filter((d) => dayjs(d.date).isSame(props.date, "day"))
                .map((d) => ({ ...d, date: date.toDate() })),
            ]);
            setMoveModal(false);
          }}
          date={props.date}
        />
      )}
      {createModal && (
        <CreateModal
          userId={props.userId}
          onClose={(reload) => {
            setEditModal(false);
            setCreateModal(false);
            if (reload) props.triggerReload();
          }}
          date={props.date}
          order={0}
          dataModified={function (): void {
            throw new Error("Function not implemented.");
          }}
        />
      )}
      {editModal && (
        <EditModal
          onClose={() => {
            setEditModal(false);
            setCreateModal(false);
          }}
          reload={props.triggerReload}
          day={props?.item}
        />
      )}
    </>
  );
};

export default TileContextMenu;
