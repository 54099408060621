import dayjs from "dayjs";
import React, { FunctionComponent, useEffect, useState } from "react";
import { IDay } from "../ScheduleViewBoard";
import UniversalModal from "global/atoms/UniversalModal";
import {
  FormGroupMultiSelect,
  FormGroupSelect,
  FormGroupSubmit,
  FormRequired,
} from "utils/formHelper";
import SchedulesConnection from "utils/connections/schedules";
import _ from "lodash";
import ActionsContainer from "global/atoms/ActionsContainer";
import { handleToast } from "utils/helpers";
import { Loader } from "rsuite";
import SeparatorLine from "global/atoms/separators/SeparatorLine";
import objectHash from "object-hash";

interface IFormModal {
  date: dayjs.Dayjs;
  order: number;
  dataModified: (data: IDay[]) => void;
  onClose: (reload?: boolean) => void;
  userId: string;
}

interface IScheduleCreateModalData_location {
  id: string;
  name: string;
}
export interface IScheduleCreateModalData {
  frequencies: Array<{ id: string; name: string }>;
  taskLocationVariants: Array<{
    location: IScheduleCreateModalData_location;
    task: { id: string; name: string };
  }>;
}

export interface IScheduleRouteAddData {
  userId: string;
  dateFrom: number;
  elements: Array<{
    locationId: string;
    taskId: string;
    frequency: number;
  }>;
}

export interface IScheduleRouteEditData {
  frequency: number | null;
}

const CreateModal: FunctionComponent<IFormModal> = (props) => {
  interface IFormState {
    taskIds: string[];
    locationIds: string[];
    userId: string;
    frequency: number;
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [formState, setFormState] = useState<IFormState>({
    taskIds: [],
    locationIds: [],
    userId: "", // todo:
    frequency: 0,
  });

  const [modalData, setModalData] = useState<
    IScheduleCreateModalData | undefined
  >();

  useEffect(() => {
    SchedulesConnection.scheduleModalData(props.userId).then((res) => {
      setModalData(res.data);
    });
  }, []);

  const getLocationOptions = () => {
    const locs: IScheduleCreateModalData_location[] =
      modalData.taskLocationVariants.map((tlv) => tlv.location);
    const uniqueSet = new Set<string>();
    const uniqueLocationVariants: IScheduleCreateModalData_location[] = [];

    for (const loc of locs) {
      if (!uniqueSet.has(loc.id)) {
        uniqueSet.add(loc.id);
        uniqueLocationVariants.push(loc);
      }
    }

    return uniqueLocationVariants;
  };

  const getTaskOptions = (locationId: string) => {
    return modalData?.taskLocationVariants
        .filter((tlv) => tlv.location.id === locationId)
        .map((tlv) => tlv.task);
  };

  const handleSubmit = (status: boolean) => {
    if (!status) return false;
    const elements: IScheduleRouteAddData["elements"] = [];

    modalData.taskLocationVariants
      .filter((tlv) => formState.locationIds.includes(tlv.location.id))
      .forEach((tlv) => {
          if (formState[`taskIds.${tlv.location.id}`].includes(tlv.task.id)) {
            elements.push({
              locationId: tlv.location.id,
              taskId: tlv.task.id,
              frequency: formState[`frequency.${tlv.location.id}`],
            });
          }
      });

    handleToast(
      SchedulesConnection.scheduleRouteAdd({
        userId: props.userId,
        dateFrom: props.date.unix(),
        elements: elements,
      })
    ).then(() => {
      props.onClose(true);
    });
  };

  const getModalBody = () => {
      return (
        <>
          {!modalData && <Loader backdrop content="Ładowanie..." vertical />}
          <FormRequired
            requiredFields={{ select: [] }} // todo:  ["frequency", "taskIds", "locationIds"]
            onSubmit={handleSubmit}
            onChange={(_state: IFormState) => {
              if (
                objectHash(_state.locationIds) !==
                objectHash(formState.locationIds)
              ) {
                _state.locationIds.forEach((locId) => {
                  _state[`taskIds.${locId}`] = getTaskOptions(
                    locId
                  ).map((t) => t.id);
                  _state[`frequency.${locId}`] = 1;
                });
              }
              setFormState((fs) => ({ ...fs, ..._state }));
            }}
            state={formState}>
            <FormGroupMultiSelect
              label={"Lokalizacje"}
              fieldName={"locationIds"}
              labelKey={"name"}
              valueKey={"id"}
              options={modalData ? getLocationOptions() : []}
            />
            <SeparatorLine />
            {formState.locationIds.map((locId) => (
              <>
                <>{getLocationOptions().find((l) => l.id === locId).name}</>
                <div style={{ marginLeft: "15px", marginBottom: "20px" }}>
                  <FormGroupMultiSelect
                    label={"Zadania"}
                    fieldName={`taskIds.${locId}`}
                    labelKey={"name"}
                    valueKey={"id"}
                    options={
                      modalData ? getTaskOptions(locId) : []
                    }
                  />
                  <FormGroupSelect
                    label={"Cykliczność"}
                    fieldName={`frequency.${locId}`}
                    labelKey={"name"}
                    valueKey={"id"}
                    disabled={_.isEmpty(formState[`taskIds.${locId}`])}
                    options={modalData?.frequencies ?? []}
                  />
                </div>
              </>
            ))}

            <ActionsContainer>
              <FormGroupSubmit label={"Dodaj"} />
            </ActionsContainer>
          </FormRequired>
        </>
      );
  };

  return (
      <UniversalModal
          title={
            <>
              {`Dodaj nowy wpis`}
              <br/>
              <small>{`${props.date.format("dddd, DD MMMM")}`}</small>
            </>
          }
          body={getModalBody()}
          open={true}
          displayFooterButtons={false}
          onClose={props.onClose}
      />
  );
};

export default CreateModal;
