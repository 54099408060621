import AxiosHelper from "../AxiosHelper";
import { getAuthHeader, getBaseApiUrl, getOrganizationId } from "../helpers";
import { AxiosPromise } from "axios";

const baseUrl = (): string => {
  return `${getBaseApiUrl()}/organizations/${getOrganizationId()}/location-categories`;
};
export interface IDeleteLocationCategory {
  locationCategory: Array<{ id: string }>;
}
const LocationCategoryConnection = {
  list: (data: any): AxiosPromise<any> => {
    return AxiosHelper.post(`${baseUrl()}/list`, data);
  },
  getFormFilterLocationCategory: (): AxiosPromise<any> => {
    return AxiosHelper.get(`${baseUrl()}/filter/form`);
  },
  getForm: (locationCategoryId: string): AxiosPromise<any> => {
    return AxiosHelper.get(`${baseUrl()}/${locationCategoryId}/form`);
  },
  addForm: (data: any): AxiosPromise => {
    return AxiosHelper.post(`${baseUrl()}`, data);
  },
  updateForm: (id: string, data: any): AxiosPromise<any> => {
    return AxiosHelper.put(`${baseUrl()}/${id}`, data);
  },
  removeLocationCategory: (
    data: IDeleteLocationCategory
  ): AxiosPromise<any> => {
    return AxiosHelper.delete(`${baseUrl()}`, {
      headers: getAuthHeader(),
      data,
    });
  },
};

export default LocationCategoryConnection;
