import React, { FunctionComponent, useRef } from "react";
import { IScheduleItemVisit, ScheduleInterval, ViewType } from "../Schedule";
import { SetState } from "../../../utils/models";
import { Dropdown, Popover, Whisper } from "rsuite";
import PlusIcon from "@rsuite/icons/Plus";
import TrashIcon from "@rsuite/icons/Trash";
import styles from "./styles.module.scss";
import dayjs, { Dayjs } from "dayjs";
import EditIcon from "@rsuite/icons/Edit";

interface IScheduleTile {
  onClick?: () => void;
  scheduleId: string | null;
  userName: string;
  userReplacementName?: string;
  recurringSettingsType: ScheduleInterval;
  activities: Array<{ id: string; color: string }>;
  date: Dayjs;
  hoveredScheduleId: string | null;
  setHoveredScheduleId: SetState<string | null>;
  onMoveSchedule: () => void;
  onDeleteSchedule: () => void;
  onAddNew: () => void;
  visit: IScheduleItemVisit | null;
  projectId: string;
  dayInMonth: number;
  viewType: ViewType;
  disabledElements: string[];
}

const ScheduleTile: FunctionComponent<IScheduleTile> = (props) => {
  let _className = "";
  switch (props.recurringSettingsType ?? 0) {
    case ScheduleInterval.Weekly:
      _className = "ui-worktime-oneweek";
      break;
    case ScheduleInterval.Weekly2:
      _className = "ui-worktime-twomonth";
      break;
    case ScheduleInterval.Weekly3:
      _className = "ui-worktime-threeweeks";
      break;
    case ScheduleInterval.Weekly4:
      _className = "ui-worktime-onemonth";
      break;
    case ScheduleInterval.Single: {
      if (props.scheduleId !== null) {
        _className = "ui-worktime-once";
      }
      break;
    }
  }

  // @ts-ignore
  const UniqActivityColors = [
    ...new Set(props.activities.map((a) => a.color) ?? []),
  ];

  const whisperRef = useRef();

  const getDaysToExpire = (): null | number | "-" => {
    if (props.visit?.deadline) {
      const d = dayjs(props.visit.deadline).diff(new Date(), "day", false);
      return d > 0 ? d : "-";
    }
    return null;
  };

  const isScheduleModificationInCellDateAllowed = (): boolean => {
    const minimumDate = dayjs().add(1, "day").format("YYYY-MM-DD");
    const cellDate = props.date.format("YYYY-MM-DD");

    return minimumDate <= cellDate;
  };

  const isScheduleCreationAllowed = (): boolean => {
    return (
      isScheduleModificationInCellDateAllowed() &&
      props.viewType === ViewType.create &&
      isPermissionGranted("SYSTEM_GLOBAL-TIMETABLE_PERMISSION_ADD")
    );
  };

  const isMoveAllowed = (): boolean => {
    return isPermissionGranted("SYSTEM_GLOBAL-TIMETABLE_PERMISSION_EDIT");
  };

  const isDeleteAllowed = (): boolean => {
    return isPermissionGranted("SYSTEM_GLOBAL-TIMETABLE_PERMISSION_DELETE");
  };

  const isPermissionGranted = (permission: string): boolean => {
    return !props.disabledElements?.includes(permission) ?? false;
  };

  const renderSpeaker = (
    <Popover full>
      <Dropdown.Menu
        onSelect={(eventKey) => {
          if (eventKey === "move") props.onMoveSchedule();
          else if (eventKey === "delete") props.onDeleteSchedule();
          else if (eventKey === "add") props.onAddNew();
          else if (eventKey === "details" && props.onClick) props.onClick();
          else if (eventKey === "visitHref") {
            // history.push(`/projects/${props.projectId}/visits/${props.visit?.visitId}/edit`);
          }
        }}>
        {props.onClick && props.scheduleId && (
          <Dropdown.Item eventKey={"details"}>
            Podgląd harmonogramu
          </Dropdown.Item>
        )}
        {props.visit && (
          <Dropdown.Item
            onClick={() => {
              window.open(
                `/projects/${props.projectId}/visits/${props.visit?.visitId}/edit`
              );
            }}>
            Podgląd wizyty
          </Dropdown.Item>
        )}
        {isScheduleCreationAllowed() && (
          <Dropdown.Item icon={<PlusIcon />} eventKey={"add"}>
            Dodaj nowy wpis
          </Dropdown.Item>
        )}
        {isScheduleModificationInCellDateAllowed() && (
          <>
            {props.scheduleId && (
              <>
                {isMoveAllowed() && (
                  <Dropdown.Item icon={<EditIcon />} eventKey={"move"}>
                    Przenieś
                  </Dropdown.Item>
                )}
                {isDeleteAllowed() && (
                  <Dropdown.Item
                    style={{ color: "red" }}
                    icon={<TrashIcon />}
                    eventKey={"delete"}>
                    Usuń
                  </Dropdown.Item>
                )}
              </>
            )}
          </>
        )}
      </Dropdown.Menu>
    </Popover>
  );

  return (
    <>
      <Whisper
        placement="bottomStart"
        trigger="contextMenu"
        ref={whisperRef}
        speaker={renderSpeaker}>
        <div
          className={`schedule-tile ${_className} ${
            props.hoveredScheduleId &&
            props.hoveredScheduleId === props.scheduleId
              ? "active"
              : ""
          }`}
          onClick={
            props.scheduleId
              ? props.onClick ?? undefined
              : () => {
                  // @ts-ignore
                  whisperRef.current.open();
                }
          }
          onMouseEnter={() => props.setHoveredScheduleId(props.scheduleId)}
          onMouseLeave={() => props.setHoveredScheduleId(null)}>
          <div
            className={`${styles.visitRibbon}`}
            title={props.visit?.status.name ?? "Brak wizyty"}
            style={{
              borderColor: `transparent ${
                props.visit?.status.color ?? "transparent"
              } transparent transparent`,
            }}></div>
          {getDaysToExpire() !== null && (
            <div className={styles.visitRibbonExpire}>{getDaysToExpire()}</div>
          )}
          <div className={`${styles.usernameReplacedWrapper} noselect`}>
            {props.userReplacementName && (
              <>
                <div className={`${styles.usernameReplaced} noselect`}>
                  {props.userReplacementName}
                </div>
              </>
            )}
            <div className={`${styles.username} noselect`}>
              {props.userName}
            </div>
          </div>
          <div className={`${styles.activityLine}`}>
            {UniqActivityColors.map((c, k) => (
              <div key={`c-${k}`} style={{ background: c, flex: "1 1" }} />
            ))}
          </div>
        </div>
      </Whisper>
    </>
  );
};

export default ScheduleTile;
