import UniversalModal from "global/atoms/UniversalModal";
import React, { FunctionComponent, useEffect, useState } from "react";
import { handleToast } from "../../../../utils/helpers";
import SchedulesConnection from "../../../../utils/connections/schedules";
import SeparatorLine from "../../../../global/atoms/separators/SeparatorLine";
import Spinner from "../../../../global/atoms/Spinner/Spinner";
import { ISelect } from "../../../../utils/models";
import { IDndItem } from "./BoardTable";
import Select from "../../../../global/atoms/Select";

interface IEditModal {
  day?: IDndItem;
  onClose: () => void;
  reload: () => void;
}

export interface IScheduleEditModalData {
  frequencies: ISelect;
}

const EditModal: FunctionComponent<IEditModal> = (props) => {
  const [modalData, setModalData] = useState<
    IScheduleEditModalData | undefined
  >();
  const [selectedFrequency, setSelectedFrequency] = useState<number | null>(
    null
  );

  const handleFrequencyChange = (value: number | null) => {
    setSelectedFrequency(value);
  };

  const getBody = () => {
    return (
      <>
        {!modalData ? (
          <Spinner />
        ) : (
          <>
            <Select
              placeholder={"Cykliczność"}
              name={`frequency`}
              options={modalData?.frequencies?.options ?? []}
              value={selectedFrequency}
              onChange={handleFrequencyChange}
            />
            <SeparatorLine />
          </>
        )}
      </>
    );
  };

  useEffect(() => {
    if (props.day?.id)
      SchedulesConnection.scheduleModalEditData(props.day.id).then((res) => {
        setModalData(res.data);
        setSelectedFrequency(res.data.frequencies.value);
      });
  }, []);

  const handleSubmit = () => {
    handleToast(
      SchedulesConnection.scheduleRouteEdit(props?.day?.id ?? "", {
        frequency: selectedFrequency,
      })
    ).then(() => {
      props.onClose();
      props.reload();
    });
  };

  return (
    <UniversalModal
      size={"xs"}
      onClose={props.onClose}
      title={"Edycja wpisu"}
      body={getBody()}
      open={true}
      onSubmit={handleSubmit}
      submitButtonDisabled={selectedFrequency === modalData?.frequencies?.value}
    />
  );
};

export default EditModal;
