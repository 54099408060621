import AxiosHelper from "../AxiosHelper";
import { getBaseApiUrl, getOrganizationId } from "../helpers";
import {
  IPhotoReviewFeedback,
  IVisitPhotoReviewEntity,
  IPhotoReviewRequestData,
  IBodyResponse,
} from "../models";
import { AxiosPromise, AxiosResponse } from "axios";

const baseUrl = `/organizations/${getOrganizationId()}/projects/visits`;

export interface IPhotoReviewFormsSingular {
  [refAnswerId: string]: IPhotoReviewFeedback;
}

export interface IPhotoReviewFormsMultiple {
  [refAnswerId: string]: IPhotoReviewFeedback[];
}

export interface ISubmitReviewDataPosition {
  feedbackQuestionId: string;
  values: Array<{
    value: string;
    refQuestionAnswerId: string;
  }>;
}

export interface ISubmitReviewData {
  refAnswerIds: Array<string>;
  type: "KPI" | "SLA";
  positions: ISubmitReviewDataPosition[];
  note: string;
}
// todo: get query token - use in request - change onfig

const VisitReviewsConnection = {
  getPhotoReviewData: (
    data: IPhotoReviewRequestData
  ): AxiosPromise<IBodyResponse<IVisitPhotoReviewEntity>> =>
    AxiosHelper.post(
      baseUrl + "/feedbackView",
      data,
      undefined,
      "getPhotoReviewData"
    ),
  getFileInfoProgressData: (data: IPhotoReviewRequestData): AxiosPromise<any> =>
    AxiosHelper.post(
      baseUrl + "/feedbackView/progress-file-info",
      data,
      undefined,
      "getPhotoFileInfoReviewData"
    ),
  getPhotoReviewFilter: (): AxiosPromise<any> =>
    AxiosHelper.get(baseUrl + "/feedbackView/filter/form"),

  postPhotosPdfCreate: (data: IPhotoReviewRequestData): AxiosPromise<any> =>
    AxiosHelper.post(
      `${baseUrl}/feedbackView/photosPdf`,
      data,
      undefined,
      "getPhotosZipBlob"
    ),
  postPhotosZipCreate: (data: IPhotoReviewRequestData): AxiosPromise<any> =>
    AxiosHelper.post(
      `${baseUrl}/feedbackView/photosZipped`,
      data,
      undefined,
      "getPhotosZipBlob"
    ),
  getPhotosZipFileBlob: (filename: string, setProgress): AxiosPromise<any> =>
    AxiosHelper.getBlobPost(
      `${baseUrl}/feedbackView/photosZipped/file`,
      { fileName: filename },
      undefined,
      "getPhotosZipBlob",
      setProgress
    ),

  getPhotosPDFBlob: (
    data: IPhotoReviewRequestData,
    setProgress
  ): AxiosPromise<any> =>
    AxiosHelper.getBlobPost(
      `${baseUrl}/feedbackView/photosPdf`,
      data,
      undefined,
      "getPhotosPDFBlob",
      setProgress
    ),
  submitReview: (data: ISubmitReviewData): AxiosPromise<any> =>
    AxiosHelper.post(`${baseUrl}/feedback`, data, undefined, "submitReview"),
  getFullSizePhotos: (
    visitQuestionAnswerIds: Array<string>
  ): Promise<
    AxiosResponse<Array<{ id: string; value: string; comment: string }>>
  > =>
    AxiosHelper.post(
      `${baseUrl}/answer/photos`,
      { ids: visitQuestionAnswerIds },
      undefined,
      "getFullSizePhotos"
    ),
  downloadPhotoUrl: (visitQuestionAnswerId: string): string =>
    `${getBaseApiUrl()}${baseUrl}/question/${visitQuestionAnswerId}/photo/download`,
  getFeedbackForms: (
    visitQuestionAnswerIds: Array<string>
  ): Promise<AxiosResponse<IPhotoReviewFormsSingular>> =>
    AxiosHelper.post(
      `${baseUrl}/feedbackView/feedback/form`,
      { ids: visitQuestionAnswerIds },
      undefined,
      "getFeedbackQuestions"
    ),
  getAllFeedbackForms: (
    visitQuestionAnswerIds: Array<string>
  ): Promise<AxiosResponse<IPhotoReviewFormsSingular>> =>
    AxiosHelper.post(`${baseUrl}/feedbackView/feedback/form`, {
      ids: visitQuestionAnswerIds,
    }),
  submitAllReview: (data: ISubmitReviewData): AxiosPromise<any> =>
    AxiosHelper.post(`${baseUrl}/feedback`, data),

  getFeedbackAnswers: (
    visitQuestionAnswerIds: Array<string>
  ): Promise<AxiosResponse<IPhotoReviewFormsMultiple>> =>
    AxiosHelper.post(`${baseUrl}/feedbackView/feedbacks`, {
      ids: visitQuestionAnswerIds,
    }),
  removeFeedback: (feedbackIds: string[]) =>
    AxiosHelper.batchDelete(`${baseUrl}/feedback`, {
      feedbackIds: feedbackIds,
    }),
};

export default VisitReviewsConnection;
