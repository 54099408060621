import React, { FunctionComponent, useState } from "react";
import { IGetPaginationBase } from "utils/models";
import { generatePath, useHistory } from "react-router-dom";
import Pagination from "global/pagination/Pagination";
import Table from "rsuite/Table";
import HeaderButtons from "global/atoms/headerButtons/HeaderButtons";
import { Checkbox, Dropdown } from "rsuite";
import PlusIcon from "@rsuite/icons/Plus";
import TrashIcon from "@rsuite/icons/Trash";

import PaginatedTableViewWrapper from "global/PaginatedTableViewWrapper";
import { _paginate30 } from "utils/states";
import {
  deepClone,
  handleSortColumn,
  handleToast,
} from "../../../../utils/helpers";
import OptionsDropdown from "../../../../global/atoms/OptionsDropdown";
import {
  BatchActionDropdownItem,
  ToggleAllCheckbox,
  ToggleSingleCheckbox,
} from "../../../../utils/toggleToDeleteHelper";
import ButtonListRedirect from "../../../../global/atoms/ButtonListRedirect";
import UniversalFilters, {
  FILTER_INPUT_TEXT,
} from "../../../../global/filters/UniversalFilters";
import LocationTypeConnection from "../api/connections";
import { IDeleteLocationType } from "../api/states";

export interface IGetLocationTypes extends IGetPaginationBase {
  name: string;
}

export interface ILocationTypes {
  count: number;
  data: IGetLocationTypes[];
}

export const getLocationTypesState: IGetLocationTypes = {
  name: "",
  ..._paginate30,
};

interface ILocationTypesList {}

const LocationTypesList: FunctionComponent<ILocationTypesList> = () => {
  const [data, setData] = useState<ILocationTypes | null | "error">(null);
  const [filters, setFilters] = useState<IGetLocationTypes>(
    deepClone(getLocationTypesState)
  );

  const history = useHistory();
  const [chosenToDelete, setChosenToDelete] = useState<Array<string>>([]);
  const [triggerLoad, setTriggerLoad] = useState(0);
  const [loading, setLoading] = useState<boolean>(true);

  if (data === "error") return <div>Błąd pobierania danych</div>;

  const handleDeleteSelected = () => {
    const data: IDeleteLocationType = {
      locationType: chosenToDelete.map((id) => ({ id: id })),
    };
    handleToast(LocationTypeConnection.removeLocationType(data)).then(() => {
      setChosenToDelete([]);
      setTriggerLoad(Date.now());
    });
  };

  return (
    <>
      <HeaderButtons>
        <OptionsDropdown>
          <Dropdown.Item
            icon={<PlusIcon />}
            onClick={() =>
              history.push(generatePath("/datasets/location-types/add"))
            }>
            Utwórz nową kategorię
          </Dropdown.Item>
          <BatchActionDropdownItem
            callback={handleDeleteSelected}
            icon={<TrashIcon />}
            chosenRows={chosenToDelete}
            title={`Usuń zaznaczone typy (${chosenToDelete.length})`}
            message={"Czy na pewno chcesz usunąć zaznaczone typy?"}
          />
        </OptionsDropdown>
      </HeaderButtons>

      <PaginatedTableViewWrapper
        table={
          <Table
            loading={loading}
            fillHeight
            data={data?.data ?? []}
            sortColumn={filters.requestOrder.field}
            sortType={filters.requestOrder.order}
            onSortColumn={(dataKey, sortType) =>
              handleSortColumn(dataKey, sortType, setFilters)
            }>
            <Table.Column width={40} align="left">
              <Table.HeaderCell>
                <ToggleAllCheckbox
                  data={data?.data}
                  state={chosenToDelete}
                  setState={setChosenToDelete}
                />
              </Table.HeaderCell>
              <Table.Cell>
                {(rowData) => (
                  <ToggleSingleCheckbox
                    state={chosenToDelete}
                    setState={setChosenToDelete}
                    selectedId={rowData.id}
                  />
                )}
              </Table.Cell>
            </Table.Column>

            <Table.Column flexGrow={1} minWidth={200} align="left" sortable>
              <Table.HeaderCell>Nazwa</Table.HeaderCell>
              <Table.Cell dataKey="name">
                {(rowData) => (
                  <ButtonListRedirect
                    redirectUrl={`/datasets/location-types/${rowData.id}/edit`}>
                    {rowData.name}
                  </ButtonListRedirect>
                )}
              </Table.Cell>
            </Table.Column>
            <Table.Column flexGrow={1} minWidth={200} align="left" sortable>
              <Table.HeaderCell>Opis</Table.HeaderCell>
              <Table.Cell dataKey="description" />
            </Table.Column>
            <Table.Column flexGrow={1} minWidth={200} align="left" sortable>
              <Table.HeaderCell>Typ</Table.HeaderCell>
              <Table.Cell dataKey="type" />
            </Table.Column>
            <Table.Column width={200} align="center" sortable>
              <Table.HeaderCell>Domyślny</Table.HeaderCell>
              <Table.Cell dataKey="color">
                {(rowData) => <Checkbox checked={rowData.default} disabled />}
              </Table.Cell>
            </Table.Column>
            <Table.Column width={200} align="center" sortable>
              <Table.HeaderCell>Kolor</Table.HeaderCell>
              <Table.Cell dataKey="color">
                {(rowData) => (
                  <div style={{ color: rowData.color }}>{rowData.color}</div>
                )}
              </Table.Cell>
            </Table.Column>
          </Table>
        }
        pagination={
          <Pagination
            count={data?.count ?? 0}
            limit={filters.requestPaginate.limit.toString()}
            page={filters.requestPaginate.page.toString()}
            setState={setFilters}
          />
        }
      />

      <UniversalFilters
        load={LocationTypeConnection.list}
        formGet={LocationTypeConnection.getFormFilterLocationType}
        setLoading={setLoading}
        state={filters}
        setState={setFilters}
        defaultStateData={getLocationTypesState}
        setResultData={setData}
        filterStorageKey={"locationTypeList"}
        triggerLoad={triggerLoad}
        elements={[
          {
            type: FILTER_INPUT_TEXT,
            stateKey: "name",
            label: "Nazwa",
          },
        ]}
      />
    </>
  );
};

export default LocationTypesList;
