import React, { FunctionComponent, useState } from "react";
import { IGetLocations, ILocations } from "utils/models";
import LocationsConnection from "utils/connections/locations";
import { getLocationsState } from "utils/states";
import Pagination from "global/pagination/Pagination";
import Table from "rsuite/Table";
import Checkbox from "rsuite/Checkbox";
import settings from "assets/svg/settings.svg";
import { useHistory } from "react-router-dom";
import HeaderButtons from "global/atoms/headerButtons/HeaderButtons";
import ActionsContainer from "../../../../global/atoms/ActionsContainer";
import {
  ToggleAllCheckbox,
  ToggleSingleCheckbox,
} from "../../../../utils/toggleToDeleteHelper";
import ButtonListRedirect from "../../../../global/atoms/ButtonListRedirect";
import UniversalFilters, {
  FILTER_INPUT_TEXT,
  FILTER_SELECT,
} from "../../../../global/filters/UniversalFilters";
import NavOptionsWrapper from "../../../../utils/NavOptionsWrapper";
import { handleSortColumn, handleToast } from "../../../../utils/helpers";
import PaginatedTableViewWrapper from "global/PaginatedTableViewWrapper";
import { Dropdown } from "rsuite";
import TrashIcon from "@rsuite/icons/Trash";
import { confirmModalCallback } from "../../../../redux/store";
import ToastNotificationPush, {
  ToastTypes,
} from "../../../../global/ToastNotification";

interface ILocationsList {}

const LocationsList: FunctionComponent<ILocationsList> = () => {
  const [data, setData] = useState<ILocations | null | "error">(null);
  const [filters, setFilters] = useState<IGetLocations>(getLocationsState);
  const history = useHistory();
  const [chosenToDelete, setChosenToDelete] = useState<Array<string>>([]);
  const [triggerUpdate, setTriggerUpdate] = useState(0);
  const [loading, setLoading] = useState(true);

  const handleEdit = (event: any) => {
    history.push(`/datasets/locations/${event.currentTarget.dataset.id}/edit`);
  };

  if (data === "error") return <div>Błąd pobierania danych</div>;
  const handleDeleteSelected = () => {
    confirmModalCallback(
      `Czy usunąć zaznaczone elementy? (${chosenToDelete?.length ?? 0})`,
      () => {
        handleToast(
          LocationsConnection.batchDelete(
            chosenToDelete.map((id) => ({ id: id }))
          )
        )
          .then(() => {
            setTriggerUpdate(Date.now());
            setChosenToDelete([]);
          })
          .catch((err) => {
            let message = "";
            if (err.response.status == 403)
              message = "Akcja wymaga rozszerzonych uprawnień";
            if (err.response.data.message)
              message = `${message} ${err.response.data.message}`;
            ToastNotificationPush(
              ToastTypes.error,
              "Błąd! Usunięcie lokalizacji nie powiodło się:",
              message,
              5000
            );
          });
      }
    );
  };
  return (
    <>
      <HeaderButtons>
        <NavOptionsWrapper addPath={"/datasets/locations/add"}>
          <Dropdown.Item
            disabled={chosenToDelete.length === 0}
            onClick={handleDeleteSelected}
            icon={<TrashIcon />}>
            Usuń zaznaczone ({chosenToDelete.length})
          </Dropdown.Item>
        </NavOptionsWrapper>
      </HeaderButtons>

      <PaginatedTableViewWrapper
        table={
          <Table
            fillHeight
            loading={loading}
            data={data?.data ?? []}
            sortColumn={filters.requestOrder.field}
            sortType={filters.requestOrder.order}
            onSortColumn={(dataKey, sortType) =>
              handleSortColumn(dataKey, sortType, setFilters)
            }>
            <Table.Column width={40} align="left">
              <Table.HeaderCell>
                <ToggleAllCheckbox
                  data={data?.data}
                  state={chosenToDelete}
                  setState={setChosenToDelete}
                />
              </Table.HeaderCell>
              <Table.Cell width={100}>
                {(rowData) => (
                  <ToggleSingleCheckbox
                    state={chosenToDelete}
                    setState={setChosenToDelete}
                    selectedId={rowData.id}
                  />
                )}
              </Table.Cell>
            </Table.Column>
            <Table.Column
              flexGrow={1}
              minWidth={300}
              align="left"
              sortable
              resizable>
              <Table.HeaderCell>Nazwa</Table.HeaderCell>
              <Table.Cell dataKey="name">
                {(rowData) => (
                  <ButtonListRedirect
                    redirectUrl={`/datasets/locations/${rowData.id}/edit`}>
                    {rowData.name}
                  </ButtonListRedirect>
                )}
              </Table.Cell>
            </Table.Column>
            <Table.Column width={100} align="left" sortable resizable>
              <Table.HeaderCell>Numer porządkowy</Table.HeaderCell>
              <Table.Cell dataKey="incrementId" />
            </Table.Column>
            <Table.Column width={150} align="left" sortable resizable>
              <Table.HeaderCell>Kod lokalizacji</Table.HeaderCell>
              <Table.Cell dataKey="code" />
            </Table.Column>
            <Table.Column
              minWidth={250}
              width={250}
              align="left"
              sortable
              resizable>
              <Table.HeaderCell>Adres</Table.HeaderCell>
              <Table.Cell dataKey="locationAddress">
                {(rowData) => (
                  <span>
                    {rowData.address?.street?.name
                      ? `${rowData.address?.street?.name} ${
                          rowData.address.houseNumber
                        }${
                          rowData.address.apartmentNumber
                            ? "/" + rowData.address.apartmentNumber
                            : ""
                        }, ${rowData.address?.postCode?.name} ${rowData.address
                          ?.city?.name}`
                      : ""}
                  </span>
                )}
              </Table.Cell>
            </Table.Column>
            <Table.Column width={75} align="center">
              <Table.HeaderCell>Koord.</Table.HeaderCell>
              <Table.Cell>
                {(rowData) => (
                  <Checkbox checked={!!rowData.address.coords} disabled />
                )}
              </Table.Cell>
            </Table.Column>
            <Table.Column width={150} align="left" sortable resizable>
              <Table.HeaderCell>Sieć</Table.HeaderCell>
              <Table.Cell dataKey="networkName">
                {(rowData) => <span>{rowData.network.name}</span>}
              </Table.Cell>
            </Table.Column>
            <Table.Column width={150} align="left" sortable resizable>
              <Table.HeaderCell>Miasto</Table.HeaderCell>
              <Table.Cell dataKey="addressCityName">
                {(rowData) => <span>{rowData.address.city?.name}</span>}
              </Table.Cell>
            </Table.Column>
            <Table.Column
              flexGrow={1}
              minWidth={100}
              align="left"
              sortable
              resizable>
              <Table.HeaderCell>Typ lokalizacji</Table.HeaderCell>
              <Table.Cell dataKey="locationTypeName">
                {(rowData) => <span>{rowData.locationType?.name}</span>}
              </Table.Cell>
            </Table.Column>
            <Table.Column
              verticalAlign={"middle"}
              align="right"
              fixed={"right"}>
              <Table.HeaderCell>Akcje</Table.HeaderCell>
              <Table.Cell>
                {(rowData) => (
                  <>
                    <ActionsContainer>
                      <img
                        src={settings}
                        style={{ cursor: "pointer" }}
                        alt={"Idź do"}
                        data-id={rowData.id}
                        onClick={handleEdit}
                      />
                    </ActionsContainer>
                  </>
                )}
              </Table.Cell>
            </Table.Column>
          </Table>
        }
        pagination={
          <Pagination
            count={data?.count ?? 0}
            limit={filters.requestPaginate.limit.toString()}
            page={filters.requestPaginate.page.toString()}
            setState={setFilters}
          />
        }
      />

      <UniversalFilters
        load={LocationsConnection.getLocations}
        formGet={LocationsConnection.getFormFilterLocations}
        setLoading={setLoading}
        state={filters}
        setState={setFilters}
        defaultStateData={getLocationsState}
        setResultData={setData}
        filterStorageKey={"LocationList"}
        triggerLoad={triggerUpdate}
        elements={[
          {
            type: FILTER_INPUT_TEXT,
            stateKey: "name",
            label: "Nazwa",
          },
          {
            type: FILTER_INPUT_TEXT,
            stateKey: "incrementId",
            label: "Numer porządkowy",
          },
          {
            type: FILTER_INPUT_TEXT,
            stateKey: "locationCustomerCode",
            label: "Kod klienta dla lokalizacji",
          },
          {
            type: FILTER_SELECT,
            stateKey: "code",
            formKey: "locationCodes",
            label: "Zewnętrzny kod lokalizacji",
          },
          {
            type: FILTER_SELECT,
            stateKey: "locationCategoryId",
            formKey: "locationCategories",
            label: "Kategoria lokalizacji",
          },

          {
            type: FILTER_INPUT_TEXT,
            stateKey: "description",
            label: "Opis",
          },
          {
            type: FILTER_INPUT_TEXT,
            stateKey: "nip",
            label: "Nip",
          },
          {
            type: FILTER_INPUT_TEXT,
            stateKey: "street",
            label: "Ulica",
          },
          {
            type: FILTER_INPUT_TEXT,
            stateKey: "city",
            label: "Miasto",
          },
          {
            type: FILTER_INPUT_TEXT,
            stateKey: "community",
            label: "Gmina",
          },
          {
            type: FILTER_INPUT_TEXT,
            stateKey: "district",
            label: "Powiat",
          },
          {
            type: FILTER_SELECT,
            stateKey: "voivodeshipId",
            formKey: "voivodeships",
            label: "Województwo",
          },
          {
            type: FILTER_SELECT,
            stateKey: "networkId",
            formKey: "networks",
            label: "Sieć",
          },
        ]}
      />
    </>
  );
};

export default LocationsList;
