import React, { FunctionComponent, useContext } from "react";
import { useDrop } from "react-dnd";
import { IDndItem, detailedScaleVal, BoardDataContext } from "./BoardTable";
import _ from "lodash";
import dayjs from "dayjs";
import Tile from "./Tile";
import { IDay } from "../ScheduleViewBoard";
import TileContextMenu, { EnumScope } from "./TileContextMenu";
import { useInView } from "react-intersection-observer";

interface ITileSocket {
  scale: number;
  dndItem: IDndItem;
  columnData: IDay[];
  triggerReload: () => void;
  userId: string;
  canAdd: boolean;
  disabledElements?: Array<string>;
}

const TileSocket: FunctionComponent<ITileSocket> = (props) => {
  const [collectionProps, drop] = useDrop<
    IDndItem,
    unknown,
    { isOver: boolean }
  >({
    accept: "visit",
    drop: (item) => tileOnDrop(item, props.dndItem),
    collect: (monitor) => {
      return { isOver: monitor.isOver() };
    },
  });
  const { days, onDataModified } = useContext(BoardDataContext);
  const { ref, inView } = useInView({
    threshold: 0.25,
    delay: 200,
    triggerOnce: false,
  });
  const tileOnDrop = (item: IDndItem, dropItem: IDndItem) => {
    const dataCloned = _.cloneDeep(days) as IDay[];
    const itemIndex = days.findIndex((d) => d.id === item.id);

    // drop area earlier than today
    if (dayjs(dropItem.date).isBefore(dayjs(), "day")) {
      alert("Nie można przypisać wizyty do przeszłości");
      return undefined;
    }

    // set sort for entire column (date)
    let tmp_sort_val: null | number = null;

    const tmp = dataCloned
      .filter((d) => dayjs(d.date).isSame(dropItem.date, "day"))
      .sort((a, b) => (a.order ?? 0) - (b.order ?? 0));

    tmp.every((d) => {
      if (tmp_sort_val === null && d.id === dropItem.id) {
        tmp_sort_val = d.order;
      }
      if (tmp_sort_val !== null) {
        const index = dataCloned.findIndex((_d) => _d.id === d.id);
        dataCloned[index].order = (dataCloned[index]?.order ?? 0) + 1;
      }
      return true;
    });

    dataCloned[itemIndex].date = dropItem.date.toDate(); // new date
    dataCloned[itemIndex].order =
      tmp_sort_val ?? (tmp.length === 0 ? 0 : (tmp.pop()?.order ?? 0) + 1); // new sort value

    onDataModified(dataCloned);
  };

  return (
    <td
      ref={drop}
      className={`td-week-${props.dndItem.date.week() % 2} ${
        collectionProps.isOver ? "over" : ""
      }`}>
      <div ref={ref} />
      {inView && (
        <>
          {props.dndItem.id && (
            <Tile
              scale={props.scale}
              dndItem={props.dndItem}
              triggerReload={props.triggerReload}
              userId={props.userId}
              canAdd={props.canAdd}
            />
          )}
          {!props.dndItem.id && props.scale === detailedScaleVal && (
            <TileContextMenu
              triggerReload={props.triggerReload}
              userId={props.userId}
              scope={EnumScope.CELL}
              date={props.dndItem.date}
              item={props.dndItem}
              canAdd={
                !props.disabledElements?.includes(
                  "SYSTEM_GLOBAL-TIMETABLE_PERMISSION_ADD"
                )
              }>
              <div className={"empty-cell"} />
            </TileContextMenu>
          )}
        </>
      )}
    </td>
  );
};

export default TileSocket;
