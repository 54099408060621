import React, { FunctionComponent } from "react";
import { IDndItem, detailedScaleVal } from "./BoardTable";
import { useDrag } from "react-dnd";
import TileContextMenu, { EnumScope } from "./TileContextMenu";
import Whisper from "rsuite/esm/Whisper";
import Tooltip from "rsuite/esm/Tooltip";

interface ITile {
  scale: number;
  dndItem: IDndItem;
  triggerReload: () => void;
  userId: string;
  disabledElements?: Array<string>;
  canAdd: boolean;
}

const Tile: FunctionComponent<ITile> = (props) => {
  const [{ isDragging }, drag, dragPreview] = useDrag({
    type: "visit",
    item: props.dndItem,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const isDetailedView = props.scale >= detailedScaleVal;

  if (isDragging) {
    // empty div for drag preview
    return <div ref={dragPreview}></div>;
  }

  let frequencyClass = "ui-worktime-once";

  if (props.dndItem.hasContinuity) {
    switch (props.dndItem.frequency) {
      case 1:
        frequencyClass = "ui-worktime-oneweek";
        break;
      case 2:
        frequencyClass = "ui-worktime-twomonth";
        break;
      case 3:
        frequencyClass = "ui-worktime-threeweeks";
        break;
      case 4:
        frequencyClass = "ui-worktime-onemonth";
        break;
    }
  }

  const className = `tile
    ${props.dndItem.isMovable ? "isMovable" : "isNotMovable"}
    ${props.dndItem.isEditable ? "isEditable" : "isNotEditable"}
  }`;

  switch (isDetailedView) {
    case true:
      return (
        <div
          className={className}
          ref={props.dndItem.isMovable ? drag : undefined}>
          <TileContextMenu
            scope={EnumScope.VISIT}
            date={props.dndItem.date}
            item={props.dndItem}
            userId={props.userId}
            triggerReload={props.triggerReload}
            canAdd={
              !props.disabledElements?.includes(
                "SYSTEM_GLOBAL-TIMETABLE_PERMISSION_ADD"
              )
            }>
            <div className={`inner-tile ${frequencyClass}`}>
              <div className={"details-view"}>
                {props.dndItem.location?.name}
                {props.dndItem.location?.code !== "" && (
                  <div className={"code"}>[{props.dndItem.location?.code}]</div>
                )}
                <div>
                  <small className={"address"}>
                    {props.dndItem.location?.address}
                  </small>
                </div>
              </div>
            </div>
          </TileContextMenu>
        </div>
      );
    case false:
      return (
        <div
          className={className}
          ref={props.dndItem.isMovable ? drag : undefined}>
          <Whisper
            speaker={
              <Tooltip>
                {props.dndItem.location?.name} -{" "}
                {props.dndItem.location?.address}
              </Tooltip>
            }>
            <div className={`inner-tile ${frequencyClass}`} />
          </Whisper>
        </div>
      );
  }
};

export default Tile;
