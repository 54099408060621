import React, { FunctionComponent, useState } from "react";
import { ICreateLocation, IQuestionItem } from "utils/models";
import WhiteCard from "global/atoms/WhiteCard";
import ProjectsConnection from "utils/connections/projects";
import { useHistory, useParams } from "react-router-dom";
import {
  deepClone,
  doubleSidePickerOnChange,
  handleSetErrors,
  handleToast,
  useQuery,
} from "utils/helpers";
import ActionsContainer from "global/atoms/ActionsContainer";
import ButtonSubmit from "global/atoms/ButtonSubmit";
import SeparatorEmpty from "../../../../../global/atoms/separators/SeparatorEmpty";
import DoubleSidePicker from "../../../../../global/atoms/dooubleSidePicker/DoubleSidePicker";
import QuestionForm from "../../taskActivitiesEdit/QuestionForm";
import UniversalModal, {
  getQuestionConfigTitle,
} from "../../../../../global/atoms/UniversalModal";
import LocationForm from "../../../../datasets/components/location/LocationForm";
import { Button, Col, IconButton, Message, Panel } from "rsuite";
import EditIcon from "@rsuite/icons/Edit";
import EditReplacement, { IReplacedUser } from "./EditReplacement";
import TrashIcon from "@rsuite/icons/Trash";
import _ from "lodash";
import QuestionFormInLine from "../../taskActivitiesEdit/QuestionFormInLine";
import SeparatorLine from "../../../../../global/atoms/separators/SeparatorLine";

const EditLocation: FunctionComponent = () => {
  const [errors, setErrors] = useState<any>({});
  const [form, setForm] = useState<any>(null);
  const { id, locationId } = useParams<{ id: string; locationId: string }>();
  const [modalOpen, setModalOpen] = useState(false);
  const [questionViewOpen, setQuestionViewOpen] = useState(false);
  const [pickedQuestion, setPickedQuestion] = useState<IQuestionItem>();
  const [clearPickedQuestionTrigger, setClearPickedQuestionTrigger] =
    useState(0);
  const [editReplacementModal, setEditReplacementModal] = useState<{
    open: boolean;
    operation: string;
  }>({
    open: false,
    operation: "UPDATE",
  });
  const [editReplacementModalData, setEditReplacementModalData] =
    useState<IReplacedUser>();
  const history = useHistory();
  const query = useQuery();

  const handleSubmit = (data: ICreateLocation) => {
    data.locationQuestions = {
      all: true,
      assignedCount: form.assignedQuestions.data.length,
      assigned: form.assignedQuestions.data,
      unassigned: form.unassignedQuestions.data,
    };

    handleToast(ProjectsConnection.updateProjectLocation(id, locationId, data))
      .then(() => {
        history.push(`/projects/${id}/edit/locations`);
      })
      .catch((err) => handleSetErrors(err, setErrors));
  };

  const handleSubmitUsers = () => {
    handleToast(
      ProjectsConnection.assignUsersToLoaction(id, locationId, {
        roles: form.locationRoles.data.map((item: any) => ({
          roleId: item.roleId,
          assigned: item.users.assigned.map((assigned: any) => ({
            id: assigned.id,
          })),
          unassigned: item.users.unassigned.map((unassigned: any) => ({
            id: unassigned.id,
          })),
        })),
      })
    ).then(() => {
      if (query.get("goBack")) {
        history.push(`/${query.get("goBack")}`);
      } else {
        history.push(`/projects/${id}/edit/locations`);
      }
    });
  };

  const onRoleUserChange = (
    locationRole: any,
    assignedUserIds: Array<string>,
    unAssignedUserIds: Array<string>
  ) => {
    setForm((state: any) => ({
      ...state,
      locationRoles: {
        data: state.locationRoles.data.map((item: any) => {
          if (item.roleId === locationRole.roleId) {
            const allUsers = [...item.users.unassigned, ...item.users.assigned];
            return {
              ...item,
              users: {
                assigned: allUsers.filter((i) =>
                  assignedUserIds.includes(i.id)
                ),
                unassigned: allUsers.filter((i) =>
                  unAssignedUserIds.includes(i.id)
                ),
              },
            };
          } else return item;
        }),
      },
    }));
  };

  const getDisabledElements = (): Array<string> => {
    return form?.disabledElements ?? [];
  };

  const isDisabled = (elm: string): boolean => {
    return getDisabledElements().includes(elm);
  };

  const handlePickedQuestion = (questionId: string) => {
    // @ts-ignore
    const question: IQuestionItem = form.assignedQuestions.data.find(
      (q) => q.id === questionId
    );
    if (!question) throw "handlePickedQuestion - picked question not found";
    setPickedQuestion(question);
    setModalOpen(true);
  };
  const isLocationQuestionEditEnabled = !form?.disabledElements?.includes(
    "LOCATION_QUESTION_EDIT_DISABLED"
  );
  const getModalBody = (): JSX.Element => {
    if (!pickedQuestion) return <></>;
    return (
      <>
        <QuestionForm
          question={pickedQuestion}
          assigned={form.assignedQuestions.data}
          removePicked={() => {
            setModalOpen(false);
            setClearPickedQuestionTrigger(new Date().getTime());
          }}
          setForm={setForm}
          form={form}
          defaultValueEdit={
            isLocationQuestionEditEnabled
              ? isLocationQuestionEditEnabled
              : pickedQuestion.isEdit
          }
          isReadOnly={!isLocationQuestionEditEnabled}
          type={"default"}
        />
      </>
    );
  };

  // to store question answer from QuestionFormInLine
  const [data, setData] = useState<Array<IQuestionItem>>(
    form?.assignedQuestions?.data ?? []
  );

  const getData = (question: IQuestionItem) => {
    const _data =
      data.filter((q) => q.refQuestionId !== question.refQuestionId) ?? [];
    _data.push(question);
    setData(_data);
  };

  const updateQuestionAnswers = () => {
    //  console.log(data)
    //  console.log(form.assignedQuestions.data)
    const currentQuestion = deepClone(data);
    const aqArr = deepClone(form.assignedQuestions.data);
    currentQuestion.forEach((qd) => {
      const qid = aqArr.findIndex((q: any) => q.id === qd.id);

      aqArr[qid] = {
        ...aqArr[qid],
        ...qd,
      };
    });
    setForm({
      ...form,
      assignedQuestions: {
        data: aqArr,
      },
    });
  };
  const getModalForAllQuestionsBody = (): JSX.Element => {
    if (!form?.assignedQuestions.data) return <></>;
    return (
      <>
        {form.assignedQuestions.data.map((questionElement) => (
          <>
            <QuestionFormInLine
              question={questionElement}
              defaultValueEdit={
                isLocationQuestionEditEnabled
                  ? isLocationQuestionEditEnabled
                  : questionElement.isEdit
              }
              getData={getData}
              type={"default"}
            />
            <SeparatorEmpty />
            <SeparatorLine />
          </>
        ))}
        <Panel>
          <ActionsContainer>
            <Button
              appearance={"ghost"}
              onClick={() => {
                setQuestionViewOpen(false);
              }}>
              Anuluj
            </Button>
            <Button
              appearance={"primary"}
              onClick={() => {
                updateQuestionAnswers();
                setQuestionViewOpen(false);
              }}>
              Akceptuj
            </Button>
          </ActionsContainer>
        </Panel>
      </>
    );
  };

  const getReplacedUsers = (roleId: string): Array<IReplacedUser> => {
    return form.userReplacements.data.filter(
      (d: { roleId: string }) => d.roleId === roleId
    );
  };

  return (
    <>
      <LocationForm
        handleSubmit={handleSubmit}
        errors={errors}
        callbackFormData={(formData: any) => {
          setForm(formData);
        }}
        dataPromise={ProjectsConnection.getFormEditLocation}
        isEdit={!isDisabled("SAVE_LOCATION_CHANGES")}>
        {form && (
          <>
            <DoubleSidePicker
              actionOnAssigned={() => {
                setQuestionViewOpen(true);
              }}
              disabled={isDisabled("SAVE_LOCATION_CHANGES")}
              heading={"Pytania do lokalizacji"}
              itemType={"locationQuestion"}
              emptyMessageAssigned={"Nie przypisano żadnych lokalizacji"}
              emptyMessageNotAssigned={"Brak pytań w projekcie"}
              assigned={form.assignedQuestions.data}
              unassigned={form.unassignedQuestions.data}
              clearPickedItemTrigger={clearPickedQuestionTrigger}
              pickedItemCallback={handlePickedQuestion}
              onChange={(assignedIds, unAssignedIds) => {
                doubleSidePickerOnChange(
                  assignedIds,
                  unAssignedIds,
                  "assignedQuestions",
                  "unassignedQuestions",
                  form,
                  setForm
                );
              }}
            />
          </>
        )}
      </LocationForm>

      {form && (
        <>
          <WhiteCard padding={true} style={{ marginTop: "30px" }}>
            {form.locationRoles.data.map((item: any) => {
              if (
                isDisabled("SAVE_LOCATION_USERS_CHANGES") &&
                _.isEmpty(item.users.assigned)
              ) {
                return <></>;
              }
              return (
                <>
                  <DoubleSidePicker
                    key={item.roleId}
                    itemType={"user"}
                    heading={`Grupa ${item.roleName}`}
                    assigned={item.users.assigned.map((el) => ({
                      ...el,
                      roleId: item.roleId,
                    }))}
                    unassigned={item.users.unassigned}
                    disabled={isDisabled("SAVE_LOCATION_USERS_CHANGES")}
                    onChange={(aids, uaids) => {
                      onRoleUserChange(item, aids, uaids);
                    }}
                    emptyMessageAssigned={"Brak przypisanych użytkowników"}
                    emptyMessageNotAssigned={"Brak użytkowników w projekcie"}
                    appendElement={
                      <>
                        {getReplacedUsers(item.roleId).map((u) => (
                          <>
                            <Message
                              type={"warning"}
                              showIcon={true}
                              style={{ marginBottom: "10px" }}>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                }}>
                                <div>
                                  <small>
                                    Zastępstwo od <strong>{u.startDate}</strong>{" "}
                                    do <strong>{u.endDate}</strong>
                                  </small>
                                  <div>
                                    <strong>{u.userToName}</strong> za{" "}
                                    <s>{u.userFromName}</s>
                                  </div>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    gap: "5px",
                                  }}>
                                  <IconButton
                                    icon={<EditIcon />}
                                    appearance={"subtle"}
                                    placement={"right"}
                                    style={{ color: "#E09616" }}
                                    onClick={() => {
                                      setEditReplacementModal({
                                        open: true,
                                        operation: "UPDATE",
                                      });
                                      setEditReplacementModalData(u);
                                    }}
                                  />
                                  <IconButton
                                    icon={<TrashIcon />}
                                    appearance={"subtle"}
                                    placement={"right"}
                                    style={{ color: "#E09616" }}
                                    onClick={() => {
                                      setEditReplacementModal({
                                        open: true,
                                        operation: "REMOVE",
                                      });
                                      setEditReplacementModalData(u);
                                    }}
                                  />
                                </div>
                              </div>
                            </Message>
                          </>
                        ))}
                      </>
                    }
                  />
                  <SeparatorEmpty />
                </>
              );
            })}

            {!isDisabled("SAVE_LOCATION_USERS_CHANGES") && (
              <ActionsContainer>
                <ButtonSubmit onClick={handleSubmitUsers} form={form} />
              </ActionsContainer>
            )}
          </WhiteCard>

          <UniversalModal
            title={getQuestionConfigTitle(pickedQuestion)}
            open={modalOpen}
            body={getModalBody()}
            displayFooterButtons={false}
          />
          <UniversalModal
            title={""}
            customHeader={
              <>
                <Col xs={8}>Pytania do lokalizacji</Col>
                <Col xs={16}>
                  Wartość predefiniowana (Zapisana w momencie generowania
                  wizyty)
                </Col>
                <SeparatorLine size={0.5} />
              </>
            }
            size={"lg"}
            open={questionViewOpen}
            body={getModalForAllQuestionsBody()}
            displayFooterButtons={false}
            onClose={() => {
              setQuestionViewOpen(false);
            }}
          />

          {editReplacementModalData !== undefined && (
            <EditReplacement
              open={editReplacementModal.open}
              operation={editReplacementModal.operation}
              //@ts-ignore
              data={editReplacementModalData}
              handleClose={() =>
                setEditReplacementModal({
                  ...editReplacementModal,
                  open: false,
                })
              }
              handleSave={(data: IReplacedUser) => {
                setEditReplacementModal({
                  ...editReplacementModal,
                  open: false,
                });
                setForm((state: any) => ({
                  ...state,
                  userReplacements: {
                    data: state.userReplacements.data.map((item: any) => {
                      if (item.id === data.id) {
                        if (editReplacementModal.operation === "REMOVE") {
                          return [];
                        } else {
                          return {
                            ...item,
                            startDate: data.startDate,
                            endDate: data.endDate,
                          };
                        }
                      } else return item;
                    }),
                  },
                }));
              }}
            />
          )}
        </>
      )}
    </>
  );
};

export default EditLocation;
