import React, { FC, memo, useEffect, useState } from "react";
import { Modal, Panel, Table } from "rsuite";

const { Column, HeaderCell, Cell } = Table;
import { IGpsSimple } from "../../../../../../utils/models";
import { getPointTypeName } from "./Atoms/MarkerClusterWindowContent";
import IconSvg from "../../../../../../global/atoms/IconHelper";
import { faPhotoVideo } from "@fortawesome/free-solid-svg-icons";
import _ from "lodash";
import dayjs from "dayjs";
import SeparatorEmpty from "global/atoms/separators/SeparatorEmpty";
import { usePanelContext } from "./VisitHelpers";
import GlobalIcon from "@rsuite/icons/Global";
import GpsLocationIcon from "@rsuite/icons/Location";

interface IVisitDistances {
  data: IGpsSimple[];
}

export const formattedDistance = (data: IGpsSimple) => {
  if (data.distance === null) {
    return <span />;
  }
  const stringDistance = data.distance.distance.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return (
    <>
      <span
        style={{
          color: data.outOfToleratedDistance ? "red" : "green",
          whiteSpace: "nowrap",
        }}>
        {stringDistance} {data.distance.unit}
      </span>
    </>
  );
};
const VisitDistances: FC<IVisitDistances> = (props) => {
  const [opened, setOpened] = useState(false);
  const [currentModalPicture, setCurrentModalPicture] = useState<IGpsSimple>();
  const [modalState, setModalState] = useState(false);
  const { registerPanelId, panelToggle, openPanels } = usePanelContext();

  useEffect(() => {
    registerPanelId("visit-distances");
    panelToggle("visit-distances");
  }, []);

  const getGpsData = () => {
    const dataSort = props.data.sort((a, b) =>
      new Date(a.saveDate) > new Date(b.saveDate) ? 1 : -1
    );
    const workStart = dataSort.find((el) => el.pointType === "WORK_START");
    const workEnd = dataSort.find((el) => el.pointType === "WORK_END");
    const activityStart = dataSort.filter(
      (el) => el.pointType === "ACTIVITY_START"
    );
    const activityEnd = dataSort.filter(
      (el) => el.pointType === "ACTIVITY_END"
    );
    let data: Array<IGpsSimple> = [];
    if (workStart !== undefined) {
      data.push(workStart);
    }
    activityStart.forEach((pel) => {
      data.push(pel);
      data = [
        ...data,
        ...dataSort.filter(
          (el) =>
            el.pointType === "PHOTO_QUESTION" &&
            pel.activityId === el.activityId
        ),
      ];
      const end = activityEnd.find((el) => pel.activityId === el.activityId);
      if (end !== undefined && activityEnd.length > 0) {
        data.push(end);
      }
    });
    if (workEnd !== undefined) {
      data.push(workEnd);
    }
    return data ?? [];
  };

  if (_.isEmpty(getGpsData()) || !props.data.length) return <></>;
  return (
    <>
      <SeparatorEmpty />
      <Panel
        header={
          <>
            <div>{"Pomiar odległości"}</div>
            <small>
              {
                "Na podstawie danych lokalizowania względem raportowanej lokalizacji"
              }
            </small>
          </>
        }
        shaded
        defaultExpanded={false}
        collapsible
        expanded={openPanels.includes("visit-distances")}
        onSelect={() => panelToggle("visit-distances")}
        onEntered={() => setOpened(true)}
        onExited={() => setOpened(false)}>
        <Table
          autoHeight
          data={getGpsData()}
          loading={!opened}
          key={"activityId"}>
          <Column flexGrow={2}>
            <HeaderCell>Typ</HeaderCell>
            <Cell>
              {(rowData) => {
                return (
                  <div
                    style={
                      rowData.pointType === "PHOTO_QUESTION"
                        ? { marginLeft: "20%" }
                        : {}
                    }>
                    {getPointTypeName(rowData.pointType)}
                  </div>
                );
              }}
            </Cell>
          </Column>
          <Column flexGrow={2}>
            <HeaderCell>Nazwa aktywności</HeaderCell>
            <Cell dataKey="activityName">
              {(rowData) => {
                if (rowData.pointType === "PHOTO_QUESTION") {
                  return (
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        if (rowData.photoContentUrl) {
                          setCurrentModalPicture(rowData as IGpsSimple);
                          setModalState(true);
                        }
                      }}>
                      {IconSvg(faPhotoVideo, undefined, undefined, "#ff9800")}
                    </div>
                  );
                } // todo: photo name now is preview (miniature and full in modal),
                return (
                  <>
                    <small>{rowData.taskName}</small>
                    <div>{rowData.activityName}</div>
                  </>
                );
              }}
            </Cell>
          </Column>

          <Column flexGrow={1}>
            <HeaderCell>...</HeaderCell>
            <Cell>
              {(rowData) =>
                dayjs(new Date(rowData.saveDate)).format("YYYY-MM-DD HH:mm:ss")
              }
            </Cell>
          </Column>
          <Column align="right" flexGrow={1}>
            <HeaderCell>Odległość</HeaderCell>
            <Cell>{(rowData) => formattedDistance(rowData as IGpsSimple)}</Cell>
          </Column>
          <Column align="center" width={150}>
            <HeaderCell>{""}</HeaderCell>
            <Cell>
              {(rowData) => (
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      textAlign: "center",
                    }}>
                    {rowData?.gpsSourceDistance?.geoSource == "ANTENNA" && (
                      <div>
                        <GlobalIcon style={{ color: "#E09616" }} />
                      </div>
                    )}
                    {rowData?.gpsSourceDistance?.geoSource == "GPS" && (
                      <div>
                        <GpsLocationIcon style={{ color: "#E09616" }} />
                      </div>
                    )}
                    <div style={{ color: "#C5C6C7", fontSize: "8px" }}>
                      {rowData?.gpsSourceDistance?.geoDistance ?? "-"}/
                      {rowData?.distance?.acceptableDistance?.toFixed(0)}
                    </div>
                  </div>
                </>
              )}
            </Cell>
          </Column>
        </Table>
        <Modal
          open={modalState}
          onClose={() => {
            setCurrentModalPicture(undefined);
            setModalState(false);
          }}>
          <Modal.Header>
            {currentModalPicture?.taskName}: {currentModalPicture?.activityName}
          </Modal.Header>
          <Modal.Body>
            <img
              style={{ width: "100%" }}
              src={currentModalPicture?.photoContentUrl}
              alt={""}
            />
          </Modal.Body>
        </Modal>
      </Panel>
      <SeparatorEmpty />
    </>
  );
};

export default memo(VisitDistances);
