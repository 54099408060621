import { default as axios } from "../AxiosHelper";
import { getAuthHeader, getBaseApiUrl } from "../helpers";

const OrganizationsConnection = {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  getOrganizations: (data: any) => {
    return axios.post(`${getBaseApiUrl()}/organizations/list`, data, {
      headers: getAuthHeader(),
    });
  },

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  updateOrganization: (id: string, data: any) => {
    return axios.put(`${getBaseApiUrl()}/organizations/${id}`, data, {
      headers: getAuthHeader(),
    });
  },
  getFiltersOrganizationsList: () => {
    return axios.get(`${getBaseApiUrl()}/organizations/filter/form`, {
      headers: getAuthHeader(),
    });
  },
  getFormAddOrganization: () => {
    return axios.get(`${getBaseApiUrl()}/organizations/form`, {
      headers: getAuthHeader(),
    });
  },
  getFormEditOrganization: (id: string) => {
    return axios.get(`${getBaseApiUrl()}/organizations/${id}/form`, {
      headers: getAuthHeader(),
    });
  },
  getLogo: () => {
    return axios.get(`${getBaseApiUrl()}/organizations/logo`, {
      headers: getAuthHeader(),
    });
  },
};

export default OrganizationsConnection;
