import React, { FunctionComponent, useEffect, useState } from "react";
import Form from "rsuite/Form";
import Input from "rsuite/Input";
import Schema from "rsuite/Schema";
import Col from "rsuite/Col";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import LocationCategoryConnection from "../../../../utils/connections/locationCategories";
import { handleToastErrorCallback } from "../../../../utils/helpers";
import WhiteCard from "../../../../global/atoms/WhiteCard";
import SeparatorEmpty from "../../../../global/atoms/separators/SeparatorEmpty";
import Section from "../../../../global/atoms/section/Section";
import { IconButton } from "rsuite";
import EditIcon from "@rsuite/icons/Edit";
import ActionsContainer from "../../../../global/atoms/ActionsContainer";
import CloseIcon from "@rsuite/icons/Close";

interface ILocationCategoryEdit {}

interface IStateEdit {
  name: string;
  isDefault: string;
}

enum locationCategoryFormFields {
  name = "name",
  isDefault = "isDefault",
}

const LocationCategoryEdit: FunctionComponent<ILocationCategoryEdit> = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const dispatch = useDispatch();
  const stateDefault: IStateEdit = {
    name: "",
    isDefault: "true",
  };

  const [state, setState] = useState<IStateEdit>(stateDefault);
  const [errors, setErrors] = useState<any | undefined>();
  const handleSubmit = () => {
    if (id) {
      handleToastErrorCallback(
        LocationCategoryConnection.updateForm(id, state),
        setErrors
      ).then(() => history.push("/datasets/location-categories"));
    } else {
      handleToastErrorCallback(
        LocationCategoryConnection.addForm(state),
        setErrors
      ).then(() => history.push("/datasets/location-categories"));
    }
  };
  const handleChange = (value: any) => {
    setState({ ...state, ...value });
  };
  const fieldRequired = Schema.Types.StringType().isRequired(
    "To pole jest wymagane"
  );
  const LocationCategoryModelForm = Schema.Model({
    [locationCategoryFormFields.name]: fieldRequired,
  });

  useEffect(() => {
    if (id)
      LocationCategoryConnection.getForm(id).then((response) => {
        setState(response.data.model);
        response.data?.header &&
          dispatch({ type: "SET_HEADER", payload: response.data.header });
      });
  }, []);

  return (
    <>
      <WhiteCard>
        <Section title={"Kategoria lokalizacji"}>
          <Form
            model={LocationCategoryModelForm}
            fluid
            //onSubmit={handleSubmit}
            onChange={handleChange}
            formValue={state}>
            <Col xs={12}>
              <Form.Group>
                <Form.ControlLabel>{"Nazwa"}</Form.ControlLabel>
                <Form.Control
                  errorMessage={errors?.name}
                  name={locationCategoryFormFields.name}
                  accepter={Input}
                  placeholder={"Nazwa..."}
                />
              </Form.Group>
            </Col>
            <SeparatorEmpty />
            <div style={{ textAlign: "right" }}>
              <ActionsContainer>
                <IconButton
                  type={"submit"}
                  appearance={"ghost"}
                  onClick={() => history.push("/datasets/location-categories")}
                  icon={<CloseIcon />}>
                  Anuluj
                </IconButton>
                <IconButton
                  type={"submit"}
                  appearance={"ghost"}
                  onClick={handleSubmit}
                  icon={<EditIcon />}>
                  Zapisz
                </IconButton>
              </ActionsContainer>
            </div>
          </Form>
        </Section>
      </WhiteCard>
    </>
  );
};

export default LocationCategoryEdit;
