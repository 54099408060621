import pl from "date-fns/locale/pl";

const Calendar = {
  monday: "Po",
  tuesday: "Wt",
  wednesday: "Śr",
  thursday: "Cz",
  friday: "Pt",
  saturday: "So",
  sunday: "Nd",

  ok: "OK",
  today: "Dzisiaj",
  yesterday: "Wczoraj",
  hours: "Godziny",
  minutes: "Minuty",
  seconds: "Sekundy",
  last7Days: "Ostatnie 7 dni",

  formattedMonthPattern: "MMM yyyy",
  formattedDayPattern: "dd MMM yyyy",

  dateLocale: pl,
};
export default {
  common: {
    loading: "Ładowanie...",
    emptyMessage: "Brak danych",
  },
  Plaintext: {
    unfilled: "-",
    notSelected: "-",
    notUploaded: "-",
  },
  Pagination: {
    more: "Więcej",
    prev: "Poprzednia",
    next: "Następna",
    first: "Pierwsza",
    last: "Ostatnia",
    limit: "{0} / strona",
    total: "Wszystkich: {0}",
    skip: "Przejdź {0}",
  },
  Calendar: Calendar,
  DatePicker: Calendar,
  DateRangePicker: Calendar,
  Picker: {
    noResultsText: "Brak wyników",
    placeholder: "Wybierz",
    searchPlaceholder: "Szukaj",
    checkAll: "Zaznacz wszystko",
  },
  InputPicker: {
    newItem: "Nowy",
    createOption: 'Stwórz opcje: "{0}"',
  },
  Uploader: {
    inited: "Rozpoczęto",
    progress: "Wysyłanie",
    error: "Błąd",
    complete: "Zakończono",
    emptyFile: "Pusty",
    upload: "Wyślij",
  },
  CloseButton: {
    closeLabel: "Zamknij",
  },
  Breadcrumb: {
    expandText: "Rozwiń",
  },
  Toggle: {
    on: "Otwórz",
    off: "Zamknij",
  },
};
