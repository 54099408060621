import React, { memo, useEffect, useState } from "react";
import { FCC, IFeedbackDetails, IQuestionItem } from "utils/models";
import { TypeActivityType } from "utils/types";
import { Checkbox, Dropdown, InputGroup, Rate } from "rsuite";
import {
  IPhotoReviewFormsSingular,
  ISubmitReviewData,
  ISubmitReviewDataPosition,
} from "utils/connections/visitReviews";
import ReviewPassIcon from "@rsuite/icons/ReviewPass";
import Connection from "../../../../../../../utils/connections/visitReviews";
import { cloneDeep, isEmpty } from "lodash";
import {
  DICTIONARY_MULTIPLE_ANSWERS_QUESTION,
  INTEGER_QUESTION,
} from "../QuestionTypeConsts";
import FormErrorMessage from "rsuite/FormErrorMessage";
import { useInView } from "react-intersection-observer";
import FormGroup from "rsuite/FormGroup";
import ReloadIcon from "@rsuite/icons/Reload";

interface IVisitReviewButton {
  question: IQuestionItem;
  itemId: string | undefined;
  activityType: TypeActivityType;
  visitId: string;
}

const VisitReviewShortButton: FCC<IVisitReviewButton> = (props) => {
  const [type, setType] = useState<"KPI" | "SLA">("KPI");
  const [value, setValue] = useState<number>();
  const [errors, setErrors] = useState<any>();
  const [isChanged, setIsChanged] = useState(false);
  const [loading, setLoading] = useState(true);
  const [values, setValues] =
    useState<Array<{ id: string; checked: boolean }>>();
  const [forms, setForms] = useState<IPhotoReviewFormsSingular | null>(null);
  const [form, setForm] = useState<Array<IFeedbackDetails>>();
  // skip if no value or unsaved value

  const refAnswerIds = props?.question.values.map(
    (ref) => ref.refQuestionAnswerId
  );

  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  const loadForm = () => {
    if (refAnswerIds.length === 0) return;
    Connection.getAllFeedbackForms(refAnswerIds)
      .then((result) => {
        setForms(result.data);

        const value = Object.values(result.data).find(
          (el) => el.feedbackDetails.length > 0
        );
        if (!value) {
          setLoading(false);
          return;
        }

        setForm(value?.feedbackDetails ?? []);
        setType(value?.feedbackType);
        let stars = 0;
        value?.feedbackDetails?.forEach((el) => {
          if (el.feedbackQuestion.questionType == INTEGER_QUESTION) {
            if (el.values) {
              stars = parseInt(el.values[0]?.value ?? "0");
              setValue(stars);
            }
          }
          if (
            el.feedbackQuestion.questionType ==
            DICTIONARY_MULTIPLE_ANSWERS_QUESTION
          ) {
            if (el.values && stars < 5) {
              setValues(el.values.map((v) => ({ id: v.value, checked: true })));
            }
          }
        });
        setLoading(false);
      })
      .catch((error) => {
        if (error?.response?.data?.message)
          setErrors((e) => ({ ...e, message: error?.response?.data?.message }));
        console.log(error.response.data.message);
        setLoading(false);
      });
  };

  const parseFeedbackDetailsForRequest = (
    feedbacks: IFeedbackDetails[]
  ): ISubmitReviewDataPosition[] => {
    return feedbacks.map((f) => {
      if (
        f?.feedbackQuestion?.questionType ==
        "DICTIONARY_MULTIPLE_ANSWERS_QUESTION"
      ) {
        const checkedAnswers = f.feedbackQuestion.answers.filter(
          (a) => values?.some((v) => v.id == a.id && v.checked)
        );
        return {
          feedbackQuestionId: f.feedbackQuestion.id,
          values:
            checkedAnswers?.map((el) => ({
              refQuestionAnswerId: "",
              value: el.id,
            })) ?? [],
        };
      }
      return {
        feedbackQuestionId: f.feedbackQuestion.id,
        values: [{ refQuestionAnswerId: "", value: value?.toString() ?? "" }],
      };
    });
  };
  const submitReview = () => {
    const data: ISubmitReviewData = {
      refAnswerIds: refAnswerIds,
      type: type,
      note: "",
      positions: form ? parseFeedbackDetailsForRequest(form) : [],
    };
    Connection.submitAllReview(data)
      .then(() => {
        setIsChanged(false);
      })
      .catch((err) => {
        setErrors(err?.response?.data?.errors);
      });
  };
  useEffect(() => {
    if (isChanged) {
      submitReview();
    }
  }, [value, values]);
  useEffect(() => {
    inView && loadForm();
  }, [inView, props.visitId]);

  const getForm = () => {
    return form ?? [];
  };

  const getCheckedReasonValue = (el, id): boolean => {
    return values?.find((c) => c.id == id)?.checked ?? false;
  };

  const title = (el) => {
    if (!values) return el.feedbackQuestion.name;
    return `${el.feedbackQuestion.name} (wybrano: ${values?.filter(
      (c) => c.checked
    ).length})`;
  };

  if (
    props.question.questionType !== "PHOTO_QUESTION" ||
    props.question.values.filter((v) => v.refQuestionAnswerId !== "").length ===
      0
  ) {
    return <></>;
  }
  if (props.activityType === "LOCATION") return <></>;

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        gap: "8px",
        alignItems: "center",
      }}>
      <div ref={ref} />
      <FormErrorMessage
        placement={"topEnd"}
        show={!!errors?.message || errors?.positions?.values[0]}>
        {errors?.message}
        {errors?.positions?.values[0]}
      </FormErrorMessage>
      {loading && <ReloadIcon spin style={{ color: "#E09616" }} />}
      {!isEmpty(forms) && (
        <>
          {getForm().map((el, indexForm) => (
            <>
              <FormGroup>
                {el.feedbackQuestion.questionType == "INTEGER_QUESTION" && (
                  <Rate
                    onChange={(value) => {
                      setValue(value > 0 ? value : 1);
                      setErrors(undefined);
                      setIsChanged(true);
                      if (value == 5) {
                        setValues([]);
                      }
                    }}
                    max={5}
                    value={value}
                    size={"xs"}
                  />
                )}
                {el.feedbackQuestion.questionType ==
                  "DICTIONARY_MULTIPLE_ANSWERS_QUESTION" && (
                  <InputGroup>
                    {!!value && value < 5 && (
                      <Dropdown
                        menuStyle={{ width: "300px" }}
                        appearance={"ghost"}
                        size={"sm"}
                        title={title(el)}>
                        {el.feedbackQuestion.answers.map(
                          (check, indexCheck) => (
                            <Checkbox
                              key={`${indexCheck}- ${indexForm}-${el.feedbackQuestion.id}`}
                              checked={getCheckedReasonValue(el, check.id)}
                              onChange={(value, checked) => {
                                setErrors(undefined);
                                setIsChanged(true);
                                const allChecked = cloneDeep(values) ?? [];
                                const i = allChecked.findIndex(
                                  (c) => c.id == value
                                );
                                if (i >= 0 && value)
                                  allChecked[i] = {
                                    id: value as string,
                                    checked: checked,
                                  };
                                else
                                  allChecked.push({
                                    id: value as string,
                                    checked: checked,
                                  });
                                setValues(allChecked);
                              }}
                              value={check.id}>
                              {check.name}
                            </Checkbox>
                          )
                        )}
                      </Dropdown>
                    )}
                    <InputGroup.Addon style={{ padding: "4px" }}>
                      <ReviewPassIcon
                        onClick={submitReview}
                        title={"Zapisz ocenę"}
                        style={{ color: isChanged ? "#E09616" : "lightgray" }}
                      />
                    </InputGroup.Addon>
                    <FormErrorMessage
                      placement={"topStart"}
                      show={!!errors?.[el.feedbackQuestion.id]}>
                      {el.feedbackQuestion.name}:{" "}
                      {errors?.[el.feedbackQuestion.id]}
                    </FormErrorMessage>
                  </InputGroup>
                )}
              </FormGroup>
            </>
          ))}
        </>
      )}
    </div>
  );
};

export default memo(VisitReviewShortButton);
