import React, { FunctionComponent, useEffect, useState } from "react";
import Form from "rsuite/Form";
import Input from "rsuite/Input";
import Schema from "rsuite/Schema";
import Col from "rsuite/Col";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { handleToastErrorCallback } from "../../../../utils/helpers";
import WhiteCard from "../../../../global/atoms/WhiteCard";
import SeparatorEmpty from "../../../../global/atoms/separators/SeparatorEmpty";
import Section from "../../../../global/atoms/section/Section";
import { IconButton, Toggle } from "rsuite";
import EditIcon from "@rsuite/icons/Edit";
import ActionsContainer from "../../../../global/atoms/ActionsContainer";
import CloseIcon from "@rsuite/icons/Close";
import LocationTypeConnection from "../api/connections";
import Spinner from "../../../../global/atoms/Spinner/Spinner";

interface ILocationTypeEdit {}

interface IStateEdit {
  name: string;
  default: boolean;
  color: string;
  description: string;
  type: string;
}

enum locationTypeFormFields {
  name = "name",
  default = "default",
  color = "color",
  description = "description",
  type = "type",
}

const LocationTypeEdit: FunctionComponent<ILocationTypeEdit> = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const dispatch = useDispatch();
  const stateDefault: IStateEdit = {
    name: "",
    default: false,
    color: "",
    description: "",
    type: "",
  };

  const [state, setState] = useState<IStateEdit>(stateDefault);
  const [loading, setLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<any | undefined>();
  const handleSubmit = () => {
    if (id) {
      handleToastErrorCallback(
        LocationTypeConnection.updateForm(id, state),
        setErrors
      ).then(() => history.push("/datasets/location-types"));
    } else {
      handleToastErrorCallback(
        LocationTypeConnection.addForm(state),
        setErrors
      ).then(() => history.push("/datasets/location-types"));
    }
  };
  const handleChange = (value: any) => {
    console.log(["form values", value]);
    setState({ ...state, ...value });
  };
  const fieldRequired = Schema.Types.StringType().isRequired(
    "To pole jest wymagane"
  );
  const LocationTypeModelForm = Schema.Model({
    [locationTypeFormFields.name]: fieldRequired,
  });

  useEffect(() => {
    if (id) {
      setLoading(true);
      LocationTypeConnection.getForm(id).then((response) => {
        setState(response.data.model);
        response.data?.header &&
          dispatch({ type: "SET_HEADER", payload: response.data.header });
        setLoading(false);
      });
    }
  }, []);
  const ColorPicker = () => (
    <input
      style={{ width: "100%" }}
      value={state.color}
      type={"color"}
      onChange={(ev) => {
        setState((s) => ({ ...s, color: ev.target.value }));
      }}
    />
  );

  console.log(state);
  if (loading) return <Spinner />;

  return (
    <>
      <WhiteCard>
        <Section title={"Typ lokalizacji"}>
          <Form
            model={LocationTypeModelForm}
            fluid
            //onSubmit={handleSubmit}
            onChange={handleChange}
            formValue={state}>
            <Col xs={12}>
              <Form.Group>
                <Form.ControlLabel>{"Nazwa"}</Form.ControlLabel>
                <Form.Control
                  value={state.name}
                  errorMessage={errors?.name}
                  name={locationTypeFormFields.name}
                  accepter={Input}
                  placeholder={"Nazwa..."}
                />
              </Form.Group>
            </Col>
            <Col xs={12}>
              <Form.Group>
                <Form.ControlLabel>{"Typ (nazwa skrócona)"}</Form.ControlLabel>
                <Form.Control
                  value={state.type}
                  errorMessage={errors?.type}
                  name={locationTypeFormFields.type}
                  placeholder={
                    "Nazwa własna max 16 znaków(znaki białe są usuwane)"
                  }
                />
              </Form.Group>
            </Col>
            <Col xs={24}>
              <Form.Group>
                <Form.ControlLabel>{"Opis"}</Form.ControlLabel>
                <Input
                  as="textarea"
                  rows={3}
                  name={locationTypeFormFields.description}
                  value={state.description}
                  onChange={(value) => {
                    console.log(value);
                    setState((s) => ({ ...s, description: value }));
                  }}
                />
              </Form.Group>
            </Col>
            <Col xs={6}>
              <Form.Group>
                <Form.ControlLabel>{"Kolor"}</Form.ControlLabel>
                <ColorPicker />
              </Form.Group>
            </Col>
            <Col xsOffset={6} xs={12}>
              <Form.Group>
                <Form.ControlLabel>{"Typ domyślny"}</Form.ControlLabel>
                <Form.Control
                  value={state.default}
                  errorMessage={errors?.isDefault}
                  name={locationTypeFormFields.default}
                  accepter={Toggle}
                />
              </Form.Group>
            </Col>
            <SeparatorEmpty />
            <div style={{ textAlign: "right" }}>
              <ActionsContainer>
                <IconButton
                  type={"submit"}
                  appearance={"ghost"}
                  onClick={() => history.push("/datasets/location-types")}
                  icon={<CloseIcon />}>
                  Anuluj
                </IconButton>
                <IconButton
                  type={"submit"}
                  appearance={"ghost"}
                  onClick={handleSubmit}
                  icon={<EditIcon />}>
                  Zapisz
                </IconButton>
              </ActionsContainer>
            </div>
          </Form>
        </Section>
      </WhiteCard>
    </>
  );
};

export default LocationTypeEdit;
