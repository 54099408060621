import React, { FunctionComponent, useEffect, useState } from "react";

import { useHistory, useParams } from "react-router-dom";
import HeaderButtons from "../../../../global/atoms/headerButtons/HeaderButtons";
import Button from "rsuite/Button";
import SelectPicker from "rsuite/SelectPicker";
import TagPicker from "rsuite/TagPicker";
import Checkbox from "rsuite/Checkbox";
import Table from "rsuite/Table";
import Col from "rsuite/Col";
import Row from "rsuite/Row";
import UserConnection from "../../../../utils/connections/user";

import { useDispatch } from "react-redux";
import WhiteCard from "../../../../global/atoms/WhiteCard";
import { ISelect } from "../../../../utils/models";
import SeparatorEmpty from "../../../../global/atoms/separators/SeparatorEmpty";
import Spinner from "../../../../global/atoms/Spinner/Spinner";
import { deepClone, handleToast } from "../../../../utils/helpers";
import SeparatorLine from "../../../../global/atoms/separators/SeparatorLine";
import Message from "rsuite/Message";
import { confirmModalCallback } from "../../../../redux/store";
import DatePicker from "rsuite/DatePicker";

import { format } from "date-fns";
import dayjs from "dayjs";
import { AxiosResponse } from "axios";
import { RowDataType } from "rsuite/esm/Table";

interface IEditUserReplacementComponent {}

interface IReplacementOption {
  id: string;
  name: string;
  roleId: string;
  userId: string;
  userName: string;
}

interface IUserReplacementForm {
  userRolesFrom: IUserReplacementSelect;
  userRolesTo: IUserReplacementSelect;
  model: { fullName: string };
}

interface IUserReplacementSelect {
  options: Array<IReplacementOption>;
  disabled?: boolean;
  name?: string; //unikatowy per widok
  placeholder?: string; //może się powtórzyć
  value: string | null;
}

interface IUserReplacementFilterForm {
  projects: ISelect;
  locations: ISelect;
}

interface IReplacementData {
  id: string;
  locationId: string;
  projectId: string;
  roleId: string;
}

const EditUserReplacement: FunctionComponent<
  IEditUserReplacementComponent
> = () => {
  const { id, projectId, locationId, roleId } = useParams<{
    id: string;
    projectId: string;
    locationId: string;
    roleId: string;
  }>();
  const [userRoleFrom, setUserRoleFrom] = useState<string>("");
  const [scheduleDate, setScheduleDate] = useState<Date>(new Date);
  const [userRoleTo, setUserRoleTo] = useState<string>("");
  const [userRolesTo, setUserRolesTo] = useState<Array<IReplacementData>>([]);
  const [replacementData, setReplacementData] = useState({
    data: [],
    count: 0,
  });

  const [replacementFilterForm, setReplacementFilterForm] =
    useState<IUserReplacementFilterForm>();
  const [form, setForm] = useState<IUserReplacementForm>();
  const [stateProjectId, setProjectId] = useState(projectId ? [projectId] : []);
  const [stateLocationId, setLocationId] = useState(
    locationId ? [locationId] : []
  );
  const [isRemove, setIsRemove] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [errors, setErrors] = useState();

  //const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const dispatch = useDispatch();

  const getList = () => {
    if (userRoleFrom)
      UserConnection.getUserReplacementList(userRoleFrom, {
        ...filterTemp,
        projects: stateProjectId,
        locations: stateLocationId,
      }).then((data: AxiosResponse<any>) => {
        setUserRoleTo("");
        setReplacementData(data.data);
        setUserRolesTo(data.data.data);
      });
  };

  useEffect(() => {
    UserConnection.getFormReplacementUser(id).then(
      (data: AxiosResponse<any>) => {
        setForm(data.data);
        data.data?.header &&
          dispatch({ type: "SET_HEADER", payload: data.data.header });
      }
    );
  }, []);

  useEffect(() => {
    getList();
    setUserRoleTo("");
  }, [stateProjectId, stateLocationId, userRoleFrom]);

  const filterTemp = {
    projects: "",
    locations: "",
    requestPaginate: { page: 0, limit: 0 },
    requestOrder: { field: undefined, order: undefined },
  };

  const handleChangeRoleFrom = (value: string) => {
    if (value) {
      setIsLoading(true);
      UserConnection.getUserReplacementFilterForm(value).then((data) => {
        setReplacementFilterForm(data.data);
        setIsLoading(false);
      });
    } else {
      setReplacementFilterForm(undefined);
    }
    setUserRoleFrom(value);
  };
  const handleChangeRoleTo = (value) => {
    setUserRoleTo(value);
    if (replacementData) {
      const data = deepClone(replacementData.data);
      data.forEach((el: IReplacementData) => (el.roleId = value));
      setUserRolesTo(data);
    }
  };

  const handleChangeRowRoleTo = (
    rowData: RowDataType<IReplacementData>,
    value: string
  ) => {
    const data = deepClone(userRolesTo);
    data.forEach((el: IReplacementData) => {
      if (el.id == rowData.id) el.roleId = value;
    });
    setUserRolesTo(data);
  };
  const rowRoleValue = (id: string) => {
    const data = userRolesTo.find((el: IReplacementData) => el.id == id);
    return data?.roleId ?? undefined;
  };
  const getUserRoleTo = () => {
    if (form && userRoleFrom) {
      const roleFrom: IReplacementOption | undefined =
        form.userRolesTo.options.find(
          (el: IReplacementOption) => el.id == userRoleFrom
        );
      return form.userRolesTo.options
        .filter((el: IReplacementOption) => el.roleId == roleFrom?.roleId)
        .map((el: IReplacementOption) => ({
          label: `${el.userName}`,
          value: el.id,
        }));
    }
    return [];
  };

  const getPositions = () => userRolesTo.filter((el) => !!el.roleId);
  const handleSubmit = () => {
    const positions = getPositions();
    if (positions.length > 0) {
      const data = {
        isRemove: isRemove,
        scheduleDate: dayjs(scheduleDate).unix(),
        positions: positions.map((el: IReplacementData) => ({
          userRoleFromId: userRoleFrom,
          userRoleToId: el.roleId,
          userNameTo:
            form?.userRolesTo.options.find((a) => a.id == el.roleId)
              ?.userName ?? "",
          userNameFrom: form?.model.fullName,
          projectId: el.projectId,
          locationId: el.locationId,
        })),
      };
      confirmModalCallback(
        `Czy wysłać dane (${
          getPositions().length
        }) lokalizacji! Zmiana będzie trwała`,
        () => {
          setIsLoading(true);
          handleToast(UserConnection.changeUserAssigment(data))
            .then((resp) => {
              if (resp.status == 201) {
                if (scheduleDate) history.push(`/admin/users/${id}/edit`);
                else getList();
              }
              setIsLoading(false);
            })
            .catch((err) => setErrors(err.response.data));
        }
      );
    }
  };
  const getUserRoleFromRoleIdUrl = () => {
    if (roleId) {
      const userRoleId = form?.userRolesFrom.options?.find(
        (el) => el.roleId == roleId
      )?.id;
      if (userRoleId) {
        setUserRoleFrom(userRoleId);
        return userRoleId;
      }
    }
    return null;
  };
  const spinner = () => <Spinner style={{ zIndex: 2000 }} />;
  if (!form) return spinner();

  return (
    <>
      <HeaderButtons>
        <Button
          appearance={"ghost"}
          onClick={() => history.push(`/admin/users/${id}/edit`)}>
          Powrót
        </Button>
      </HeaderButtons>

      <WhiteCard style={{ position: "relative" }}>
        <span>
          Użytkownik:{" "}
          <span style={{ fontWeight: 800 }}>{form?.model?.fullName}</span>
        </span>
        <SeparatorEmpty />
        <Col xs={12}>
          Wybierz rolę:
          <SelectPicker
            block
            value={
              userRoleFrom !== "" ? userRoleFrom : getUserRoleFromRoleIdUrl()
            }
            placeholder={"Wybierz rolę"}
            data={
              form.userRolesFrom.options?.map((el) => ({
                label: `${el.name}`,
                value: el.id,
              })) ?? []
            }
            onChange={(value) => {
              handleChangeRoleFrom(value ?? "");
            }}
          />
        </Col>
        <SeparatorEmpty />
        <Col xs={12}>
          Data zmiany
          <DatePicker
            block
            isoWeek
            oneTap
            value={scheduleDate}
            disabled={true}
            onChange={(value) => setScheduleDate(value as Date)}
            format={"yyyy-MM-dd"}
            shouldDisableDate={(date) => dayjs(date).isBefore(dayjs())}
          />
        </Col>
      </WhiteCard>
      <SeparatorEmpty />
      {userRoleFrom && (
        <WhiteCard>
          <Checkbox
            checked={isRemove}
            onChange={(value, checked) => {
              setIsRemove(checked);
            }}>
            Usuń użytkownika z przypisanych lokalizacji.
          </Checkbox>
          <SeparatorEmpty />
          <Row>
            <Col xs={6}>
              Projekt:
              <TagPicker
                block
                placement={"bottomStart"}
                value={stateProjectId}
                onChange={(value) => setProjectId(value)}
                data={replacementFilterForm?.projects.options ?? []}
                labelKey={"name"}
                valueKey={"id"}
              />
            </Col>
            <Col xs={6}>
              Lokalizacja:
              <TagPicker
                block
                placement={"bottomStart"}
                value={stateLocationId}
                onChange={(value) => setLocationId(value)}
                data={replacementFilterForm?.locations.options ?? []}
                labelKey={"name"}
                valueKey={"id"}
              />
            </Col>
            <Col xs={12}>
              Zmień wszędzie na:
              <SelectPicker
                block
                value={userRoleTo}
                onChange={handleChangeRoleTo}
                data={getUserRoleTo()}
              />
            </Col>
          </Row>
          <SeparatorLine />
          {isLoading ? (
            spinner()
          ) : (
            <Table
              autoHeight={true}
              //@ts-ignore
              data={replacementData?.data ?? []}>
              <Table.Column flexGrow={1} align="left">
                <Table.HeaderCell>Projekt</Table.HeaderCell>
                <Table.Cell dataKey="projectName" />
              </Table.Column>

              <Table.Column flexGrow={1} align="left">
                <Table.HeaderCell depth={0} width={0}>
                  Lokalizacja
                </Table.HeaderCell>
                <Table.Cell dataKey="locationName" />
              </Table.Column>
              <Table.Column flexGrow={2} align="left">
                <Table.HeaderCell>Użytkownik</Table.HeaderCell>
                <Table.Cell dataKey="id">
                  {(rowData: RowDataType<IReplacementData>) => (
                    <SelectPicker
                      value={rowRoleValue(rowData.id)}
                      data={getUserRoleTo()}
                      block
                      onChange={(value) => {
                        handleChangeRowRoleTo(rowData, value as string);
                      }}
                    />
                  )}
                </Table.Cell>
              </Table.Column>
            </Table>
          )}

          <Row>
            <Col xs={18}>
              {!!getPositions().length && (
                <Message
                  style={{ margin: "20px" }}
                  showIcon={true}
                  header={`Zmieniasz ${
                    getPositions().length
                  }/${replacementData?.count} wpisów! Zmiana będzie trwała.`}
                  type={"warning"}
                />
              )}
            </Col>
            <Col xs={6}>
              <div style={{ textAlign: "right" }}>
                <Button
                  appearance={"ghost"}
                  style={{ marginTop: "20px" }}
                  type={"submit"}
                  onClick={handleSubmit}>
                  Zamień osoby ({getPositions().length})
                </Button>
              </div>
            </Col>
          </Row>
        </WhiteCard>
      )}
    </>
  );
};

export default EditUserReplacement;
