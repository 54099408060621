import React, { FunctionComponent, useEffect } from "react";
import App from "../../App";
import AppPublic from "../../AppPublic";
import { useLocation } from "react-router-dom";
import Login from "../../Login";
import { useSelector } from "react-redux";
import { IRoot } from "redux/models";

interface ISecurity {}

export const isPublicScope =
  window.location.pathname.substring(0, 7) === "/public";

const Security: FunctionComponent<ISecurity> = () => {
  const location = useLocation();
  const token = JSON.parse(localStorage.getItem("user") ?? "{}")?.token;
  const tokenExpired = useSelector((state: IRoot) => state.app.tokenExpired);

  // silence annoying non-harmfull error of ResizeObserver
  useEffect(() => {
    window.addEventListener("error", (e) => {
      if (
        e.message === "ResizeObserver loop limit exceeded" ||
        e.message ===
          "ResizeObserver loop completed with undelivered notifications."
      ) {
        const resizeObserverErrDiv = document.getElementById(
          "webpack-dev-server-client-overlay-div"
        );
        const resizeObserverErr = document.getElementById(
          "webpack-dev-server-client-overlay"
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute("style", "display: none");
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute("style", "display: none");
        }
      }
    });
  }, []);

  if (isPublicScope) return <AppPublic />;
  if (!token || tokenExpired)
    return (
      <Login
        backUrl={`${location.pathname}${location.search}${location.hash}`}
      />
    );
  return <App />;
};

export default Security;
