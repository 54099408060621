import BadgeStatus from "global/atoms/badge/BadgeStatus";
import React from "react";
import { FCC } from "utils/models";

const VisitFreequencyLegend: FCC = () => {
  return (
    <div>
      <div
        style={{ alignSelf: "flex-end", display: "flex", columnGap: "10px" }}>
        <BadgeStatus
          color={"#aaa"}
          borderColor={"#ddd"}
          backgroundColor={"#f5f2ad"}>
          Raz na tydzień
        </BadgeStatus>
        <BadgeStatus
          color={"#aaa"}
          borderColor={"#ddd"}
          backgroundColor={"#daec9f"}>
          Raz na 2 tygodnie
        </BadgeStatus>
        <BadgeStatus
          color={"#aaa"}
          borderColor={"#ddd"}
          backgroundColor={"#cae8f5"}>
          Raz na 3 tygodnie
        </BadgeStatus>
        <BadgeStatus
          color={"#aaa"}
          borderColor={"#ddd"}
          backgroundColor={"#f9ddc6"}>
          Raz na 4 tygodnie
        </BadgeStatus>
        <BadgeStatus
          color={"#aaa"}
          borderColor={"#ddd"}
          backgroundColor={"#e6e6e6"}>
          Wizyta jednorazowa
        </BadgeStatus>
        <BadgeStatus
          color={"#aaa"}
          borderColor={"#ddd"}
          backgroundColor={"#fff"}>
          Wizyta spoza harmonogramu
        </BadgeStatus>
      </div>
    </div>
  );
};

export default VisitFreequencyLegend;
