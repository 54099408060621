import WhiteCard from "../../../../global/atoms/WhiteCard";
import Form from "../../../../global/form/Form";
import Input from "../../../../global/atoms/Input";
import Textarea from "../../../../global/atoms/Textarea";
import Select from "../../../../global/atoms/Select";
import ActionsContainer from "../../../../global/atoms/ActionsContainer";
import ButtonSubmit from "../../../../global/atoms/ButtonSubmit";
import React, {
  FunctionComponent,
  ReactElement,
  useEffect,
  useState,
} from "react";
import { INotification, ISelect, IUser } from "../../../../utils/models";
import ButtonOutlined from "../../../../global/atoms/ButtonOutlined";
import { notificationState } from "../../../../utils/states";
import FileUpload from "../../../../global/atoms/FileUpload";
import commonStyles from "../../../../global/common.module.scss";
import {
  deepClone,
  getBaseApiUrl,
  getOrganizationId,
  handleToast,
} from "../../../../utils/helpers";
import IconButton from "../../../../global/atoms/IconButton";
import NotificationsConnection from "../../../../utils/connections/notifications";
import download from "downloadjs";
import LocalDatePicker from "../../../../global/atoms/LocalDatePicker";
import Checkbox from "../../../../global/atoms/Checkbox";
import DoubleSidePicker from "../../../../global/atoms/dooubleSidePicker/DoubleSidePicker";
import { Button, Message } from "rsuite";
import { useHistory } from "react-router-dom";
import Spinner from "../../../../global/atoms/Spinner/Spinner";

export interface INotificationForm {
  model: IUser;
  sourceTypes: ISelect;
  sendChannels: ISelect;
  statuses: ISelect;
  users: {
    disabled: boolean;
    data: Array<{
      networkId: string;
      networkName: string;
      projectId: string;
      projectName: string;
      roleId: string;
      roleName: string;
      userId: string;
      userName: string;
      userRoleId: string;
    }>;
  };
}

interface INotificationFormComponent {
  handleSubmit: (event: any, assignedUsers: Array<string>) => void;
  form: INotificationForm;
  setForm: React.Dispatch<any>;
  errors: any;
  onlyUsersFromLocations?: boolean;
  assignedUserIds?: Array<string>;
  notification?: INotification;
  readOnly?: boolean;
}

interface INotificationFile {
  filePath: string;
}

const SUBMIT_SEND = "sendSubmit";
const SUBMIT_PREPARE = "prepareSubmit";

const NotificationForm: FunctionComponent<INotificationFormComponent> = (
  props
) => {
  const [assignedUserIds, setAssignedUserIds] = useState<Array<string>>(
    props.assignedUserIds ?? []
  );
  const [notification, setNotification] =
    useState<INotification>(notificationState);
  const saveNotifyFile = `${getBaseApiUrl()}/organizations/${getOrganizationId()}/notifications/files`;
  const history = useHistory();
  if (notification.scheduleDate === "") {
    notification.scheduleDate = new Date().toDateString();
  }

  if (notification.endDate === "") {
    notification.endDate = new Date().toDateString();
  }
  useEffect(() => {
    if (notification.endDateTimestamp < notification.scheduleDateTimestamp) {
      setNotification({
        ...notification,
        endDateTimestamp: notification.scheduleDateTimestamp,
      });
    }
  }, [notification.scheduleDateTimestamp]);

  useEffect(() => {
    if (props.notification !== undefined) {
      setNotification(props.notification);
    }

    if (notification.scheduleDate === "") {
      setNotification({
        ...notification,
        scheduleDate: new Date().toISOString().split("T")[0],
      });
    }

    if (notification.endDate === "") {
      setNotification({
        ...notification,
        endDate: new Date().toISOString().split("T")[0],
      });
    }
  }, []);

  const fileUploadCallback = (
    filePath: string,
    type: "attachment" | "iconPath"
  ) => {
    setNotification({ ...notification, [type]: filePath });
  };

  const [fileUploadErrors, setFileUploadErrors] = useState();
  const fileUploadHandleError = (err: any) => {
    setFileUploadErrors(err);
  };

  const getFileName = (keyType: "attachment" | "iconPath"): string => {
    let fileName = "";
    try {
      if (notification[keyType]) {
        const tmp = notification[keyType].split("/");
        fileName = tmp[tmp.length - 1];
      }
    } catch (e) {
      fileName = notification[keyType];
    }
    return fileName;
  };

  const downloadFile = (keyType: "attachment" | "iconPath") => {
    NotificationsConnection.getFileBlob(notification.id, keyType).then(
      (result) => {
        download(result.data, getFileName(keyType));
      }
    );
  };

  const getFileContainer = (type: "attachment" | "icon"): ReactElement => {
    let fileTypeData: {
      key: "attachment" | "iconPath";
      url: string;
      label: string;
    };

    switch (type) {
      case "attachment":
        fileTypeData = {
          key: "attachment",
          url: saveNotifyFile + "/" + notification.id + "/attachment",
          label: "Załącznik",
        };
        break;
      case "icon":
        fileTypeData = {
          key: "iconPath",
          label: "Ikona",
          url: saveNotifyFile + "/" + notification.id + "/icon",
        };
        break;
    }
    return (
      <>
        {props.readOnly !== true && (
          <FileUpload
            label={fileTypeData.label}
            saveUrl={saveNotifyFile}
            savedFileCallback={(res: INotificationFile) => {
              fileUploadCallback(res.filePath, fileTypeData.key);
            }}
            handleError={fileUploadHandleError}
            maxFileSizeMB={1}
          />
        )}
        {
          // @ts-ignore
          notification[fileTypeData.key] && (
            <div>
              <small>
                {fileTypeData.label}:&nbsp;
                {notification.id ? (
                  // @ts-ignore
                  <a
                    href="views/admin/components/notifications/NotificationForm#"
                    onClick={downloadFile.bind(null, fileTypeData.key)}>
                    {getFileName(fileTypeData.key)}
                  </a>
                ) : (
                  <span className={commonStyles.smallMuted}>
                    {getFileName(fileTypeData.key)}
                  </span>
                )}
                {!props.readOnly && (
                  <IconButton
                    style={{ position: "relative", top: "8px" }}
                    inline={true}
                    size={25}
                    icon={"close"}
                    onClick={() => {
                      setNotification({
                        ...notification,
                        [fileTypeData.key]: "",
                      });
                    }}
                  />
                )}
              </small>
            </div>
          )
        }
        {fileUploadErrors ? (
          <div className={commonStyles.loadingSmallErr}>{fileUploadErrors}</div>
        ) : (
          <></>
        )}
      </>
    );
  };

  const funcUserMap = (user: {
    userName: string;
    userId: string;
  }): { name: string; id: string } => {
    return {
      ...user,
      name: user.userName,
      id: user.userId,
    };
  };
  useEffect(() => {
    if (!props.form?.users?.data) {
      NotificationsConnection.getUsersFilterForm().then((response) => {
        props.setForm((d) => ({ ...d, users: response.data.users }));
      });
    }
  }, []);
  const getUserAssigned = () => {
    if (!props.form?.users?.data) return [];
    return props.form.users.data
      .filter((u) => assignedUserIds.indexOf(u.userId) >= 0)
      .map(funcUserMap);
  };

  const getUserUnAssigned = () => {
    if (!props.form?.users?.data) return [];
    return props.form.users.data
      .filter((u) => assignedUserIds.indexOf(u.userId) < 0)
      .map(funcUserMap);
  };

  const notifyDelete = () => {
    if (!props.notification?.id) return;
    handleToast(NotificationsConnection.delete(props.notification.id)).then(
      () => {
        history.push("/notifications");
      }
    );
  };

  return (
    <>
      <WhiteCard>
        <Form
          handleSubmit={(event) => {
            event.preventDefault();

            const tmp = deepClone(notification);
            if (event.nativeEvent.submitter.name === SUBMIT_SEND) {
              tmp.status = "WAITING";
            } else if (event.nativeEvent.submitter.name === SUBMIT_PREPARE) {
              tmp.status = "PREPARE";
            }

            props.handleSubmit(tmp, assignedUserIds);
          }}>
          <>
            <Input
              placeholder={"Temat"}
              name={"subject"}
              value={notification.subject}
              state={notification}
              setState={setNotification}
              errors={props.errors}
              disabled={props.readOnly ?? false}
            />
            {props.notification?.createFrom == "IMPORT" ? (
              <Message showIcon header={"Wiadomość"}>
                {props.notification.body}
              </Message>
            ) : (
              <Textarea
                placeholder={"Wiadomość"}
                name={"body"}
                value={notification.body}
                state={notification}
                setState={setNotification}
                errors={props.errors}
                rows={props?.notification?.createFrom == "IMPORT" ? 4 : 10}
                disabled={
                  (props?.notification?.createFrom == "IMPORT" ||
                    props.readOnly) ??
                  false
                }
              />
            )}

            <Select
              placeholder={"Kanał dystrybucji"}
              name={"sendChannel"}
              options={props.form.sendChannels.options}
              value={notification.sendChannel}
              errors={props.errors}
              onChange={(value) => {
                setNotification({
                  ...notification,
                  sendChannel: value,
                  iconPath: "",
                  attachment: "",
                });
              }}
              disabled={props.readOnly ?? false}
            />

            <LocalDatePicker
              disabled={props.readOnly ?? false}
              label={"Data rozpoczęcia wysłania"}
              placement={"bottomStart"}
              state={notification}
              setState={setNotification}
              stateKey={"scheduleDateTimestamp"}
              emptyValue={""}
              disabledDate={(date: Date) =>
                date < new Date(new Date().toISOString().slice(0, 10))
              }
            />

            <LocalDatePicker
              disabled={props.readOnly ?? false}
              label={"Data zakończenia wysłania"}
              placement={"bottomStart"}
              state={notification}
              setState={setNotification}
              stateKey={"endDateTimestamp"}
              emptyValue={""}
              disabledDate={(date: Date) =>
                date < new Date(new Date().toISOString().slice(0, 10)) ||
                date < new Date(notification.scheduleDateTimestamp * 1000)
              }
            />
            {["MAIL", "PUSH", "APP"].includes(notification.sendChannel) && (
              <div>
                {["MAIL", "APP"].includes(notification.sendChannel) &&
                  getFileContainer("attachment")}
                {notification.sendChannel === "PUSH" &&
                  getFileContainer("icon")}
              </div>
            )}

            {notification.sendChannel === "APP" && (
              <Checkbox
                isChecked={notification.isConfirmationRequired}
                onClick={() =>
                  setNotification({
                    ...notification,
                    isConfirmationRequired:
                      !notification.isConfirmationRequired,
                  })
                }>
                Wymagane potwierdzenie
              </Checkbox>
            )}
            <br />
            {props.form &&
              notification.sendChannel !== "HELPDESK" &&
              props.form?.users?.data && (
                <DoubleSidePicker
                  onlyUsersFromLocations={props.onlyUsersFromLocations}
                  loading={!props.form?.users?.data}
                  heading={"Odbiorcy powiadomienia"}
                  emptyMessageAssigned={"Brak przypisanych użytkowników"}
                  emptyMessageNotAssigned={"Brak użytkowników w systemie"}
                  assigned={getUserAssigned()}
                  unassigned={getUserUnAssigned()}
                  form={props.form}
                  onChange={(assignedIds) => setAssignedUserIds(assignedIds)}
                  disabled={props.readOnly ?? false}
                  itemType={"userGlobal"}
                />
              )}
            {props.form &&
              notification.sendChannel !== "HELPDESK" &&
              !props.form?.users?.data && (
                <Spinner content="Pobieranie danych użytkowników..." />
              )}
            {props.errors && props.errors.users ? (
              <div className={commonStyles.loadingSmallErr}>
                {props.errors.users}
              </div>
            ) : (
              <></>
            )}

            <ActionsContainer>
              {["PREPARE", "WAITING"].indexOf(
                props.notification?.status ?? ""
              ) > -1 &&
                props.notification?.id && (
                  <>
                    <Button
                      appearance={"ghost"}
                      color={"red"}
                      onClick={() => notifyDelete()}>
                      Usuń
                    </Button>
                  </>
                )}
              {props.readOnly !== true ? (
                <>
                  <ButtonSubmit
                    attrName={"sendSubmit"}
                    form={props.form}
                    name={"Zapisz i wyślij"}
                  />

                  <ButtonSubmit
                    appearance={"ghost"}
                    attrName={"prepareSubmit"}
                    form={props.form}
                    name={"Zapisz wersje roboczą"}
                  />
                </>
              ) : (
                <ButtonOutlined onClick={() => history.push("/notifications")}>
                  {"Wróć"}
                </ButtonOutlined>
              )}
            </ActionsContainer>
          </>
        </Form>
      </WhiteCard>
      <div id={"filterRefDiv"} />
    </>
  );
};

export default NotificationForm;
