import {
  getBaseApiUrl,
  getOrganizationId,
  getProjectIdFromUrl,
} from "../helpers";
import { AxiosPromise, AxiosResponse } from "axios";
import {
  IExportDownloadRequest,
  IProjectImportEntity,
  IProjectImportRequestData,
  ISelect,
} from "../models";
import AxiosHelper from "../AxiosHelper";

const baseUrl = `${getBaseApiUrl()}/organizations/${getOrganizationId()}/projects`;

interface IGetAllResult {
  result: IProjectImportEntity[];
  paginateResult: any;
}

export interface IImportFrom {
  import_types: ISelect;
  export_types: ISelect;
}

const ProjectImports = {
  getAll: (
    requestData: IProjectImportRequestData
  ): Promise<AxiosResponse<IGetAllResult>> => {
    return AxiosHelper.post(
      `${baseUrl}/${getProjectIdFromUrl()}/imports/list`,
      requestData,
      undefined,
      "projectImportList"
    );
  },
  importsForm: (): AxiosPromise<IImportFrom> => {
    return AxiosHelper.get(`${baseUrl}/${getProjectIdFromUrl()}/imports/form`);
  },
  getForm: (): Promise<AxiosResponse<IProjectImportRequestData>> => {
    return AxiosHelper.get(
      `${baseUrl}/${getProjectIdFromUrl()}/imports/filter/form`
    );
  },
  add: (data: FormData): any => {
    // IProjectImportAdd
    return AxiosHelper.post(
      `${baseUrl}/${getProjectIdFromUrl()}/imports`,
      data
    );
  },
  getFileSourceBlob: (projectImportId: string) => {
    // todo: w resulcie filename z conenten-disposition
    return AxiosHelper.getBlob(
      `${baseUrl}/${getProjectIdFromUrl()}/imports/download/source/${projectImportId}`
    );
  },
  getFileResultBlob: (projectImportId: string) => {
    // todo: w resulcie filename z conenten-disposition
    return AxiosHelper.getBlob(
      `${baseUrl}/${getProjectIdFromUrl()}/imports/download/result/${projectImportId}`
    );
  },
  setImportError: (projectImportId: string) => {
    return AxiosHelper.put(
      `${baseUrl}/${getProjectIdFromUrl()}/imports/${projectImportId}/clear`
    );
  },
  downloadExport: (
    downloadRequest: IExportDownloadRequest
  ): AxiosPromise<any> => {
    return AxiosHelper.getBlobPost(
      `${baseUrl}/${getProjectIdFromUrl()}/imports/export/download`,
      downloadRequest
    );
  },
  downloadExportFile: (fileHash: string): AxiosPromise<any> => {
    return AxiosHelper.getBlobPost(
      `${baseUrl}/${getProjectIdFromUrl()}/imports/download/export-file/${fileHash}`
    );
  },
};

export default ProjectImports;
